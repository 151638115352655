import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../../components/AuthContext';
import {useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';

interface Transaction {
    _id: string,
    mode: string,
    amount: number,
    remarks: string,
    dated: Date,
    filename: string,
    tags: string[],
    createdAt: Date
}

interface ParamTypes {
    vehicleID: string
}

const VehicleExpenses: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {vehicleID} = useParams < ParamTypes > ();
    const [trans, setTrans] = useState < Transaction[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [skip, setSkip] = useState < number > (0);
    const [total, setTotal] = useState < number > (0);
    const [sum, setSum] = useState < number > (0);
    const [toDate, setToDate] = useState < string | string[] | undefined > ();
    const [fromDate, setFromDate] = useState < string | string[] | undefined> ();
    const [duration, setDuration] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [plate, setPlate] = useState < string > ("");

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
      };

      const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
      };

      const handleSubmit = () => {

        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
            const tDate = new Date(toDate);
            const fDate = new Date(fromDate);
            if (tDate < fDate)
            {
                setMessage(t("dates_invalid"));
                setIserror(true);
                return;
            }     
            else
            {
                toDOB = toDate;
                fromDOB = fromDate;
            }
        }

        if (toDOB === "" && (!duration || duration === "")) {
            setMessage(t("duration_mandatory"));
            setIserror(true);
            return;
        }

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.post(`/transactions/listVehicle/${vehicleID}`, {duration, toDOB, fromDOB});
                setTrans(result.data.transactions);
                setTotal(result.data.total);
                setSum(result.data.sum);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }

      const loadItems = async () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        let limit = skip + 30;
       
        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
            const tDate = new Date(toDate);
            const fDate = new Date(fromDate);
            if (tDate < fDate)
            {
                setMessage(t("dates_invalid"));
                setIserror(true);
                return;
            }     
            else
            {
                toDOB = toDate;
                fromDOB = fromDate;
            }
        }

        if (toDOB === "" && (!duration || duration === "")) {
            setMessage(t("duration_mandatory"));
            setIserror(true);
            return;
        }

        try {
            const result = await api.post(`/transactions/listVehicle/${vehicleID}?skip=${limit}`, {duration, toDOB, fromDOB});

            if(result.data.transactions && result.data.transactions.length)
            {
                setTrans([...trans, ...result.data.transactions]);
            }
                
            setSkip(limit);

        } catch (error : any) {
            if (error.response !== undefined) 
                setMessage(error.response.data.message);
                else 
                setMessage(t("something_went_wrong"));
            setIserror(true);
        } 
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {

                const result = await api.get('/vehicles/' + vehicleID);

                setName(result.data.name);
                setPlate(result.data.plate);
                setShowLoading(false);
                
            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, vehicleID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_expenses")}</IonTitle>
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_expenses")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className="min-md">
                        
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_duration")}
                                            </IonLabel>
                                            <IonSelect value={duration}
                                                cancelText={t("cancel")}
                                                okText={t("ok")}
                                                placeholder={t("tran_duration")}
                                                onIonChange={
                                                    (e) => setDuration(e.detail.value)
                                                }>
                                                <IonSelectOption value="three">{t("three")}</IonSelectOption>
                                                <IonSelectOption value="six">{t("six")}</IonSelectOption>
                                                <IonSelectOption value="twelve">{t("twelve")}</IonSelectOption> 
                                                <IonSelectOption value="current">{t("current_fy")}</IonSelectOption>
                                                <IonSelectOption value="last">{t("last_fy")}</IonSelectOption> 
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                      
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-from-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_from")}
                                            </IonLabel>
                                            <IonInput value={formatDate(fromDate)} readonly={true} />
                                            <IonModal trigger="open-from-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7]} handle={false}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("tran_from")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                    cancelText={t("cancel")}
                                                    doneText={t("done")}
                                                        value={fromDate}
                                                        presentation="date"
                                                        onIonChange={ev => setFromDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-to-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("tran_to")}
                                            </IonLabel>
                                            <IonInput value={formatDate(toDate)} readonly={true} />
                                            <IonModal trigger="open-to-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7]} handle={false}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("tran_to")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                    cancelText={t("cancel")}
                                                    doneText={t("done")}
                                                        value={toDate}
                                                        presentation="date"
                                                        onIonChange={ev => setToDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
   
                    </IonRow>
                </IonGrid>
                <IonGrid className="ion-no-padding mt-10 mb-60">
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={() => handleSubmit() }
                                color="primary"
                                size="small"
                                className='ion-text-wrap'>{t("view_expenses")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <div className="ion-table mt-10">

                        <IonRow className="table-title ion-padding">

                            <IonCol size="4" className='table-heading'>
                                {t("date")}
                            </IonCol>
                            <IonCol size="5" className='table-heading'>
                                {t("remarks")}
                            </IonCol>
                            <IonCol size="3" className='table-heading ion-text-right'>
                                {t("amount")}
                            </IonCol>
                           
                        </IonRow>
                       
                        {(trans.length > 0) ? (trans.map((tran, i) => {
                            return (
                                <IonRow key={tran._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}>
                                    
                                        <IonCol size="4" className='table-field' >
                                            
                                         {formatDated(tran.dated)}
                                            
                                        </IonCol>
                                        <IonCol size="5" className='table-field'>
                                            {tran.remarks}
                                        </IonCol>
                                        <IonCol size="3" className='table-field ion-text-right'>
                                            {Math.abs(tran.amount)}
                                        </IonCol>
                                        
                                    
                                </IonRow>
                            )
                        })) : ( 
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                            <IonLabel className="list-title"><h3>{t("no_expenses")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        )
                        } 
                    </div>

                    {(trans.length > 0) &&
                    <IonRow>
                        <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCol>
                   </IonRow>
                    }
                    
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol size="6" className="footer-stats">
                            {t("showing")} {total === 0 ? t("no_records") : `1 - ${trans.length} ${t("of")} ${total}`} 
                        </IonCol>
                        <IonCol size="6" className="footer-stats">
                        {name}
                        </IonCol>
                        <IonCol size="6" className="footer-stats">
                        {t("total_expenses")} {Math.abs(sum)}
                        </IonCol>
                        <IonCol size="6" className="footer-stats">
                            {plate}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default VehicleExpenses;