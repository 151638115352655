import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import { alertCircleOutline, checkboxOutline, create } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';
import usePageUnloadHandler from '../../../../components/PageUnloadHandler';
import useApiService from '../../../../components/ApiService';

interface Entry {
    _id: string,
    calendarDate: Date,
    employee: Employee,
    classInfo: ClassInfo,
    info: string,
    files?: Attach[],
    isAssignment: boolean
}
interface Employee {
    userID: string,
    name: string
}
interface Attach {
    caption: string,
    filename: string
}
interface ClassInfo {
    sessionID: string,
    classID: string,
    className: string
}

interface AllSubject {
    _id: string,
    name: string,
    teachers: SubTeacher[],
    isActive: boolean
}

interface SubTeacher {
    teacherID: string,
    teacherName: string
}

interface ParamTypes {
    subjectID?: string
}

const SubjectDiary: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {subjectID} = useParams < ParamTypes > ();
    const [entries, setEntries] = useState < Entry[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);
    const [current, setCurrent] = useState < string > ("");

    const [selectedSubject, setSelectedSubject] = useState <string> ("");
    const [subjects, setSubjects] = useState < AllSubject[] > ([]);

    const [total, setTotal] = useState < number > (0);
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);

    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if(elem) {
            setScrollPosition(elem.scrollTop);
        }
    };

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const handleSubject = (subID : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/dailyDiary/getBySubject/${subID}`);
                setEntries(result.data.entries);
                setTotal(result.data.total);
                setSkip(0);

            } catch (error: any) {
               //
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
        setSelectedSubject(subID);
    }

    const loadItems = async () => {

        if (!selectedSubject || selectedSubject === "") {
            setMessage(t("subject_mandatory"));
            setIserror(true);
            return;
        }

        let limit = skip+30;

        try {
            const result = await api.get(`/dailyDiary/getBySubject/${selectedSubject}?skip=${limit}`);
            if(result.data.entries && result.data.entries.length)
            {
                setEntries([...entries, ...result.data.entries]);
                setSkip(limit);
            }
        } catch (error: any) {
           //
        }
    }

    useEffect(() => {

        const storedState = sessionStorage.getItem(history.location.pathname);
        if (history.action === "POP" && storedState) {
            const { entries, subjects, total, skip, selectedSubject, scrollPosition } = JSON.parse(storedState);
                // Data is present in local storage, use it to initialize state
                setEntries(entries);
                setSubjects(subjects);
                setTotal(total);
                setSkip(skip);
                setSelectedSubject(selectedSubject);
                setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
            } 
            else 
            {

                const fetchUp = async () => {
                    setShowLoading(true);
                    try {

                        const subs = await api.get(`/subjects/getByInstitute/${authInfo.user.instituteID}`);
                        setSubjects(subs.data);
                            
                        if (subjectID)
                        {
                            setSelectedSubject(subjectID);
        
                            const result = await api.get(`/dailyDiary/getBySubject/${subjectID}`);
                            setEntries(result.data.entries);
                            setTotal(result.data.total);
                        }
                        else
                        {
                            setSelectedSubject("");
                            setEntries([]);
                            setTotal(0);
                        }
                        setSkip(0);

                    } catch (error: any) {
                       //
                    } finally {
                        setShowLoading(false);
                    }
                    
                };

                fetchUp();
            }
   
        setInitialRender(false);

    }, [authInfo, subjectID, refreshed, history.action, history.location.pathname]);

    // Save state to local storage whenever it changes
    useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
                entries,
                subjects,
                total,
                skip,
                selectedSubject,
                scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));

        
        }
    }, [entries, subjects, total, skip, selectedSubject, scrollPosition, history.location.pathname, initialRender]);

    // Effect to handle page reload 
    usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/academics`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_subject_diary")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_subject_diary")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
              
                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>
                
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding max-xl">
                    <IonRow>

                        <IonCol size="12" sizeLg='6'>
                            <IonCard>
                                <IonCardContent className="ion-no-padding">                   
                                    
                                    <IonItem
                                        detail={false}
                                        lines='full'
                                        button={false}
                                    >
                                        <IonLabel position="stacked" className="input-label" color="secondary">{t("select_subject")}</IonLabel>
                                        <IonSelect value={selectedSubject}
                                            cancelText={t("cancel")}
                                            okText={t("ok")}
                                            className="input-field text-capitalize"
                                            placeholder={t("select_subject")}
                                            onIonChange={
                                                (e) => e.detail.value && handleSubject(e.detail.value)
                                        }>
                                            {subjects.map((subject) => (
                                                (<IonSelectOption key={subject._id} value={`${subject._id}`} className='text-capitalize'>{subject.name}</IonSelectOption>)
                                            ))}
                                                
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                
                <IonGrid className="ion-no-padding mb-60">
                    <div className="ion-table">

                        <IonRow className="table-title ion-padding">
                            <IonCol size="4" className='table-heading'>
                                <IonIcon 
                                    icon={checkboxOutline}
                                    color="light"
                                    className='table-icon'
                                />
                                <IonText className='ion-margin-start'>{t("date")}</IonText>
                            </IonCol>

                            <IonCol size="7" className='table-heading'>
                                {t("updated_by")} ({(authInfo.institute.type === "school") ? t("class") : t("batch")})
                            </IonCol>
                           
                            <IonCol size="1">
                                <IonIcon 
                                    icon={create}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>
                        <IonRadioGroup value={current} onIonChange={
                                (e) => setCurrent(e.detail.value!)
                            }>

                            {(entries.length > 0) ? (entries.map((report, i) => {
                                return (
                                    <IonRow key={report._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                    onClick={
                                        () => setCurrent(report._id)
                                    }
                                    onDoubleClick={
                                        () => history.push(`/app/diary/${report._id}`)
                                    }>
                                        
                                        <IonCol size="4" className='table-field' >
                                            <IonRow>
                                                <IonCol>
                                                <IonRadio value={report._id} className='table-icon' color="secondary" />
                                                    <IonText className='ion-margin-start text-capitalize'>{formatDate(report.calendarDate)}</IonText>
                                                    
                                                </IonCol>
                                            </IonRow>
                                            
                                        </IonCol>
                                        
                                        <IonCol size="7" className='table-field text-capitalize'>
                                            {report.employee.name} ({report.classInfo.className})
                                        </IonCol>
                                        <IonCol size="1" >
                                                <IonIcon 
                                                icon={create}
                                                color="dark"
                                                className='table-icon mt-10'
                                                onClick={
                                                    () => history.push(`/app/diary/${report._id}`)
                                                }
                                                />

                                        </IonCol>
                                        
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_entries")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 
                        </IonRadioGroup>
                    </div>

                    {(entries.length > 0) &&
                        <IonRow>
                            <IonCol>
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                    }

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                                    {t("showing")} {total === 0 ? t("no_records") : `1 - ${entries.length} ${t("of")} ${total}`} 
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol className=''>
                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (current === "")
                                                {
                                                    setMessage(t("select_entry"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/app/diary/${current}`);
                                                }

                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("view_entry")}
                                    </IonButton>
                            
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                        
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default SubjectDiary;