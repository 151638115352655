import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonPopover,
    IonProgressBar,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTextarea,
    IonTitle,
    IonToggle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { addCircleOutline, alertCircleOutline, attach, camera, chevronForwardOutline, clipboardOutline, create, imageOutline, images, informationCircleOutline, star, starOutline, trash, trashOutline, videocam } from 'ionicons/icons';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Camera, CameraSource, CameraResultType } from '@capacitor/camera';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { PhotoViewer, Image } from '@capacitor-community/photoviewer';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {Calendar, Day} from '@hassanmojab/react-modern-calendar-datepicker';
import { useAuth } from "../../../../components/AuthContext";
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';
import useApiService from '../../../../components/ApiService';
import FileOpener from "../../../../components/FileOpener";

import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';

interface Entry {
    _id: string,
    employee: Employee,
    subjectInfo: SubjectInfo,
    info: string,
    files?: Attach[],
    isAssignment: boolean
}
interface Employee {
    userID: string,
    name: string
}
interface Attach {
    caption: string,
    filename: string
}
interface SubjectInfo {
    subjectID: string,
    subjectName: string
}
interface Attend {
    year: number,
    month: number,
    day: number,
    className: string
}
interface Session {
    _id: string,
    name: string,
    isActive: boolean
}
interface ClassInterface {
    _id: string,
    name: string,
    sessionID: string,
    year: string,
    subjects: Subject[],
    isActive: boolean
}
interface Subject {
    subjectID: string,
    subjectName: string,
    teacherID: string,
    teacherName: string
}
interface FileUpload {
    caption: string,
    file: File,
    imageURL: string,
    ext: string
}
interface FileInfo {
    imageURL: string,
    ext: string
}

const ClassDiary: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [showDownloading, setShowDownloading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string > ('');
    const [minDay, setMinDay] = useState < Day > ();
    const [maxDay, setMaxDay] = useState < Day > ();
    const [selectedDay, setSelectedDay] = useState < Day > ();
    const [attend, setAttend] = useState < Attend[] > ([]);
    const [entries, setEntries] = useState < Entry[] > ([]);
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [session, setSession] = useState < string > ("");
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [classID, setClassID] = useState <string> ("");
    const [className, setClassName] = useState <string> ("");
    const [subjectID, setSubjectID] = useState <string> ("general");
    const [isOpen, setIsOpen] = useState(false);
    const [pop, setPop] = useState < boolean > (false);
    const [title, setTitle] = useState < string > ("");
    const [info, setInfo] = useState < string > ("");
    const [caption, setCaption] = useState < string > ("");
    const [upload, setUpload] = useState < File[] > ([]);
    const [imageURL, setImageURL] = useState < FileInfo[] > ([]);
    const [fileUploads, setFileUploads] = useState < FileUpload[] > ([]);
    const [isAssignment, setIsAssignment] = useState < boolean > (false);
    const [subjects, setSubjects] = useState < Subject[] > ([]);
    const [viewTitle, setViewTitle] = useState < string > ("");
    const [viewInfo, setViewInfo] = useState < string > ("");
    const [viewIsAssignment, setViewIsAssignment] = useState < boolean > (false);
    const [files, setFiles] = useState < Attach[] > ([]);
    const [isView, setIsView] = useState(false);
    const [entryID, setEntryID] = useState < string > ("");
    const [employeeID, setEmployeeID] = useState < string > ("");
    const [showAlert, setShowAlert] = useState < boolean > (false);

    const formatDated = (value: Day) => {
        return value.day+"/"+value.month+"/"+value.year;
    };

    const formatDate = (value: string) => {
        const monthNames = [t("jan"), t("feb"), t("mar"), t("apr"), t("may"), t("jun"),
            t("jul"), t("aug"), t("sep"), t("oct"), t("nov"), t("dec")];

        const valueDate = new Date(value);
        return monthNames[valueDate.getMonth()] +' - '+ valueDate.getFullYear();
    };

    const getDaysInMonth = (year: number, month: number) => {
        return new Date(year, month, 0).getDate();
    };

    const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });

    const openImage = (url: string, location: string) => {

        let images: Image[] = [];
        let startFrom = 0;
        let j = -1;

        if (location === "web")
        {
            for (let i = 0; i < files.length; i++)
            {
                if(isFileImage(files[i].filename))
                {
                    j += 1;
                    images.push({url: `${process.env.REACT_APP_STATIC_S3}/diary/${files[i].filename}`, title: files[i].caption});
                    if (files[i].filename === url)
                    {
                        startFrom = j;
                    }
                }
            }
        }
        if (location === "local")
        {
            for (let i = 0; i < fileUploads.length; i++)
            {
                if((fileUploads[i].ext !== "mp4") && (fileUploads[i].ext !== "pdf"))
                {
                    j += 1;
                    images.push({url: fileUploads[i].imageURL, title: fileUploads[i].caption});
                    if (fileUploads[i].imageURL === url)
                    {
                        startFrom = j;
                    }
                }
                
            }
        }

        if (images.length > 1)
        {
            PhotoViewer.show({images, mode: isPlatform('mobile') ? 'slider' : 'gallery', startFrom});
        }
        if (images.length === 1)
        {
            PhotoViewer.show({images, mode: 'one'});
        }

    };

    const openFile = async (url: string, ext: string) => {
        const mimeType = getMimeType(ext);
        if (isPlatform('capacitor'))
        {
            try  {
                await FileOpener.open({filePath: url, contentType: mimeType});
            }
            catch {
                setMessage(t("file_error"));
                setIserror(true);
            }
            
        }
    };

    const downloadFile = async (fileName : string) => {

        const myArray = fileName.split(".");
        const mimeType = getMimeType(myArray[1]);
        const url = process.env.REACT_APP_STATIC_S3 + '/diary/' + fileName;

        if (isPlatform('capacitor') && !showDownloading)
        {
            try {
                Filesystem.checkPermissions().then(async (res) => {
                    if (res.publicStorage !== 'granted') {
                        Filesystem.requestPermissions().then(async (ress) => {
                            if (ress.publicStorage === 'denied') {
                                setMessage(t("storage_denied"));
                                setIserror(true);
                            }
                            else {
                                setShowDownloading(true);
                                try {
                                    const savedFile = await Filesystem.downloadFile({
                                        path: fileName,
                                        url,
                                        directory: Directory.External,
                                    });
                                    
                                    if(savedFile.path) {
                                        await FileOpener.open({filePath: savedFile.path, contentType: mimeType});
                                    } else {
                                        setMessage(t("download_error"));
                                        setIserror(true);
                                    }
                                    
                                } catch(error: any) {
                                    setMessage(t("download_error"));  
                                    setIserror(true);
                                } finally {
                                    setShowDownloading(false);
                                }
                            }
                        });
                    }
                    else {
                        setShowDownloading(true);
                        try {
                            const savedFile = await Filesystem.downloadFile({
                                path: fileName,
                                url,
                                directory: Directory.External,
                            });
                            
                            if(savedFile.path) {
                                await FileOpener.open({filePath: savedFile.path, contentType: mimeType});
                            } else {
                                setMessage(t("download_error"));
                                setIserror(true);
                            }
                            
                        } catch(error: any) {
                            setMessage(t("download_error"));  
                            setIserror(true);
                        } finally {
                            setShowDownloading(false);
                        }
                    }
                });

            } catch {
                setMessage(t("download_error"));
                setIserror(true);
            } 
        }
        if (isPlatform('capacitor') && showDownloading)
        {
            setMessage(t("download_in_progress"));
            setIserror(true);
        }

        if (!isPlatform('capacitor'))
        {
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.rel = 'noreferrer';
            link.download = 'download'; // Triggers download

            // Append the anchor to the body
            document.body.appendChild(link);

            // Programmatically click the link
            link.click();

            // Remove the anchor from the document
            document.body.removeChild(link);
        }

    };

    const getMimeType = (ext: string) => {
        let mime = "";
        if (ext === 'jpeg')
            mime = 'image/jpeg';
        if (ext === 'png')
            mime = 'image/png';
        if (ext === 'pdf')
            mime = 'application/pdf';
        if (ext === 'mp4')
            mime = 'video/mp4';
        if (ext === 'mov')
            mime = 'video/quicktime';
        if (ext === 'docx')
            mime = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        if (ext === 'xlsx')
            mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (ext === 'pptx')
            mime = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        if (ext === 'doc')
            mime = 'application/msword';
        if (ext === 'xls')
            mime = 'application/vnd.ms-excel';
        if (ext === 'ppt')
            mime = 'application/vnd.ms-powerpoint';
        return mime;
    };

    const getExtension = (ext: string) => {
        let mime = "";
        if (ext === 'image/jpeg')
            mime = 'jpeg';
        if (ext === 'image/png')
            mime = 'png';
        if (ext === 'application/pdf')
            mime = 'pdf';
        if (ext === 'video/mp4')
            mime = 'mp4';
        if (ext === 'video/quicktime')
            mime = 'mov';
        if (ext === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
            mime = 'docx';
        if (ext === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
            mime = 'xlsx';
        if (ext === 'application/vnd.openxmlformats-officedocument.presentationml.presentation')
            mime = 'pptx';
        if (ext === 'application/msword')
            mime = 'doc';
        if (ext === 'application/vnd.ms-excel')
            mime = 'xls';
        if (ext === 'application/vnd.ms-powerpoint')
            mime = 'ppt';
        return mime;
    };

    const convertFileSrc = (path: string) => {
        return Capacitor.convertFileSrc(path);
    };

    const isFileImage= (fileName: string) => {
        // Extract the extension from the file name
        const extension = fileName.split('.').pop();
    
        // Check if the extension matches 'jpeg' or 'png'
        return extension === 'jpeg' || extension === 'png';
    };

    const isFileVideo= (fileName: string) => {
        const extension = fileName.split('.').pop();
        return extension === 'mp4' || extension === 'mov';
    };

    const takePhoto = async () => {
        const theUploads =  [...upload];
        const theURLs =  [...imageURL];
        try {
            Camera.checkPermissions().then(async (res) => {
                if (res.camera !== 'granted') {
                    Camera.requestPermissions({ permissions: ['camera'] }).then(async (ress) => {
                        if (ress.camera === 'denied') {
                            setMessage(t("photo_denied"));
                            setIserror(true);
                        } else {
                            try {
                                const image = await Camera.getPhoto({
                                    source: CameraSource.Camera,
                                    quality: 90,
                                    allowEditing: true,
                                    resultType: CameraResultType.Uri
                                });
                            
                                const webPath = image.webPath;

                                if (webPath) {
                                    const blob = await fetch(webPath).then((r) => r.blob());
                                    const rawFile = new File([blob], 'camera_image', {
                                        type: getMimeType(image.format),
                                    });
                                    theUploads.push(rawFile);
                                    const base64 = await fileToDataUri(rawFile);
                                    theURLs.push({imageURL: base64, ext: "jpeg"});
                                }
                            } catch(error: any) {
                                setMessage(t("camera_error"));  
                                setIserror(true);
                            }
                        }
                    });
                }
                else {
                    try {
                        const image = await Camera.getPhoto({
                            source: CameraSource.Camera,
                            quality: 90,
                            allowEditing: true,
                            resultType: CameraResultType.Uri
                        });
                    
                        const webPath = image.webPath;

                        if (webPath) {
                            const blob = await fetch(webPath).then((r) => r.blob());
                            const rawFile = new File([blob], 'camera_image', {
                                type: getMimeType(image.format),
                            });
                            theUploads.push(rawFile);
                            const base64 = await fileToDataUri(rawFile);
                            theURLs.push({imageURL: base64, ext: "jpeg"});
                        }
                        
                    } catch(error: any) {
                        setMessage(t("camera_error"));  
                        setIserror(true);
                    }
                }
            });

            setUpload(theUploads);
            setImageURL(theURLs);

        } catch {
            setMessage(t("camera_error"));
            setIserror(true);
        } 
    };

    const pickImages = async () => {
        isPlatform('capacitor') && setShowLoading(true);
        try {
            const result = await FilePicker.pickImages();
            const theUploads =  [...upload];
            const theURLs =  [...imageURL];

            for (let i=0; i < result.files.length; i++)
            {
                const file = result.files[i];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        const webPath = convertFileSrc(file.path);
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);

                        if (file.mimeType === 'image/jpeg')
                        {
                            const base64 = await fileToDataUri(rawFile);
                            theURLs.push({imageURL: base64, ext: "jpeg"});
                        }
                        if (file.mimeType === 'image/png')
                        {
                            const base64 = await fileToDataUri(rawFile);
                            theURLs.push({imageURL: base64, ext: "png"});
                        }
                    }
                    if ((file.mimeType === 'image/heic' || file.mimeType === 'image/heif'))
                    {
                        const jpegPath = await FilePicker.convertHeicToJpeg({path: file.path});
                        const webPath = convertFileSrc(jpegPath.path);
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                        const base64 = await fileToDataUri(rawFile);
                        theURLs.push({imageURL: base64, ext: "jpeg"});
                    }
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        const rawFile = new File([file.blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);

                        if (file.mimeType === 'image/jpeg')
                        {
                            const base64 = await fileToDataUri(rawFile);
                            theURLs.push({imageURL: base64, ext: "jpeg"});
                        }
                        if (file.mimeType === 'image/png')
                        {
                            const base64 = await fileToDataUri(rawFile);
                            theURLs.push({imageURL: base64, ext: "png"});
                        }
                    }
                }
            }
            setUpload(theUploads);
            setImageURL(theURLs);
        } 
        catch (error: any) {
            setMessage(t("no_files_selected"));  
            setIserror(true);
        } finally {
            setShowLoading(false);
        }

    };

    const pickFile = async () => {
        isPlatform('capacitor') && setShowLoading(true);
        try {

            const result = await FilePicker.pickFiles({limit: 1});
            const theUploads =  [...upload];
            const theURLs =  [...imageURL];

            if (result.files.length === 1)
            {
                const file = result.files[0];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    const webPath = convertFileSrc(file.path);
                    if ((file.mimeType === 'application/pdf') || (file.mimeType === 'application/msword') || (file.mimeType === 'application/vnd.ms-excel') || (file.mimeType === 'application/vnd.ms-powerpoint') 
                        || (file.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'))
                    {
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                        theURLs.push({imageURL: file.path, ext: getExtension(file.mimeType)});
                    }
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    if ((file.mimeType === 'application/pdf') || (file.mimeType === 'application/msword') || (file.mimeType === 'application/vnd.ms-excel') || (file.mimeType === 'application/vnd.ms-powerpoint') 
                        || (file.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'))
                    {
                        const rawFile = new File([file.blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                        theURLs.push({imageURL: "", ext: getExtension(file.mimeType)});
                    }
                }
            }
            setUpload(theUploads);
            setImageURL(theURLs);
        } 
        catch(error: any) {
            setMessage(t("no_files_selected"));  
            setIserror(true);
        } finally {
            setShowLoading(false);
        }
    };

    const pickVideo = async () => {
        isPlatform('capacitor') && setShowLoading(true);
        try {
           const result = await FilePicker.pickVideos({limit: 1});
           const theUploads =  [...upload];
           const theURLs =  [...imageURL];

           if (result.files.length === 1)
           {
               const file = result.files[0];
               //for native
               if (file && file.path && file.name && file.mimeType) {
                    if ((file.mimeType === 'video/mp4') || (file.mimeType === 'video/quicktime'))
                    {
                        const webPath = convertFileSrc(file.path);
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                        theURLs.push({imageURL: file.path, ext: getExtension(file.mimeType)});
                    }
               }
               //for web
               else if (file && file.blob && file.name && file.mimeType) {
                    if ((file.mimeType === 'video/mp4') || (file.mimeType === 'video/quicktime'))
                    {
                        const rawFile = new File([file.blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                        theURLs.push({imageURL: "", ext: getExtension(file.mimeType)});
                    }
               }
           }
           setUpload(theUploads);
           setImageURL(theURLs);
       } 
       catch(error: any) {
           setMessage(t("no_files_selected"));  
           setIserror(true);
       } finally {
            setShowLoading(false);
        }
   };

   const handleAdd = () => {

        if (!caption || caption === "") {
            setMessage(t("title_mandatory"));
            setIserror(true);
            return;
        }

        if (!upload.length || !imageURL.length || (upload.length !== imageURL.length)) {
            setMessage(t("file_invalid"));
            setIserror(true);
            return;
        }

        let uploads = [...fileUploads];
        for (let i=0; i < upload.length; i++)
        {
            uploads.push({caption: `${caption} (${i+1})`, file: upload[i], imageURL: imageURL[i].imageURL, ext: imageURL[i].ext});
        }
        
        setFileUploads(uploads);
        setUpload([]);
        setImageURL([]);
        setPop(false);

    }

    const deleteImage = (index: number) => {

        let uploads = [...fileUploads];
        if (index > -1) {
            uploads.splice(index, 1); // 2nd parameter means remove one item only
        }
        setFileUploads(uploads);

    }
    
    const handleDate = async (dated : Day) => {

        setSelectedDay(dated);

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const res = await api.post('/dailyDiary/getByDay', {classID, 'day': dated});
                if (res.data)
                {
                    setEntries(res.data);
                }
                else
                {
                    setEntries([]);
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();     
    }

    const handleSubmit = (theDate: string | string[]) => {

        if (typeof(theDate) === "string")
        {
            setSelectedDate(theDate);

            const onDate = new Date(theDate);

            const aajDate = new Date(Date.now());

            const minDate = {
                day: 1,
                month: onDate.getMonth()+1,
                year: onDate.getFullYear()
                
            }

            let maxDate : Day;

            if ((aajDate.getFullYear() === onDate.getFullYear()) && (aajDate.getMonth() === onDate.getMonth()))
            {
                maxDate = {
                    day: onDate.getDate()+1,
                    month: onDate.getMonth()+1,
                    year: onDate.getFullYear()
                }
            }
            else
            {
                maxDate = {
                    day: getDaysInMonth(onDate.getFullYear(), onDate.getMonth()+1),
                    month: onDate.getMonth()+1,
                    year: onDate.getFullYear()
                }
            }

            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    const events = await api.get(`/dailyDiary/getByMonth/${classID}/${theDate}`);

                    let result: Array < Attend > = [];

                    for (let i = 0; i < events.data.length ; i++) {
                        let dated: Date = new Date(events.data[i].calendarDate);
                        let dateA: number = dated.getDate();

                        let calObj: Attend = {year: onDate.getFullYear(), month: onDate.getMonth()+1, day: dateA, className: "eventDay"}; 
                        result.push(calObj);
                
                    }
                    setAttend(result);
                    setMinDay(minDate);
                    setMaxDay(maxDate);
                    setSelectedDay(minDate);

                    const res = await api.post('/dailyDiary/getByDay', {classID, 'day': minDate});
                    if (res.data)
                    {
                        setEntries(res.data);
                    }
                    else
                    {
                        setEntries([]);
                    }
                
                } catch (error: any) {
                    //
                } finally {
                    setShowLoading(false);
                }
                
            };

            fetchUp();
        }
    }

    const handleSession = (sessionID : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allWithSubjects/${authInfo.user.instituteID}/${sessionID}`);
                setClasses(result.data);
            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setSession(sessionID);
        setClassID("");
        setSelectedDay(undefined);
    }

    const handleClassID = (classID : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const onDate = new Date(Date.now());
                setSelectedDate(onDate.toISOString());

                const minDate = {
                    day: 1,
                    month: onDate.getMonth()+1,
                    year: onDate.getFullYear()
                }
                const maxDate = {
                    day: onDate.getDate(),
                    month: onDate.getMonth()+1,
                    year: onDate.getFullYear()
                }

                const events = await api.get(`/dailyDiary/getByMonth/${classID}/${onDate.toISOString()}`);

                let result: Array < Attend > = [];

                for (let i = 0; i < events.data.length ; i++) {
                    let dated: Date = new Date(events.data[i].calendarDate);
                    let dateA: number = dated.getDate();

                    let calObj: Attend = {year: onDate.getFullYear(), month: onDate.getMonth()+1, day: dateA, className: "eventDay"}; 
                    result.push(calObj);
            
                }
                setAttend(result);
                setMinDay(minDate);
                setMaxDay(maxDate);
                setSelectedDay(maxDate);

                const res = await api.post('/dailyDiary/getByDay', {classID, 'day': maxDate});
                if (res.data)
                {
                    setEntries(res.data);
                }
                else
                {
                    setEntries([]);
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        if (classID)
        {
            fetchUp();
            setClassID(classID);

            const classObj = classes.find(val => val._id === classID)!;
            setClassName(classObj.name);


            if (classObj.subjects)
                setSubjects(classObj.subjects);

            if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && !authInfo.user.roles.includes("Academic") && authInfo.user.roles.includes("Teacher")))
            {
                setSession(classObj.sessionID);
            }

        }
    
    }

    const handleEntry = () => {

        let subjectName = "";

        if (subjectID === "general") {
            if (!title || title.trim() === "") {
                setMessage(t("title_mandatory"));
                setIserror(true);
                return;
            }
            subjectName = title;
        } else {
            const classObj = classes.find(val => val._id === classID)!;
            const subjectObj = classObj.subjects.find(val => val.subjectID === subjectID)!;
            subjectName = subjectObj.subjectName;
        }
        
        if (!selectedDay) {
            setMessage(t("event_date"));
            setIserror(true);
            return;
        }

        if (!info || info.trim() === "") {
            setMessage(t("info_mandatory"));
            setIserror(true);
            return;
        }

        const byear = selectedDay.year;
        const bmonth = selectedDay.month;
        const zeromonth = ('0'+bmonth).slice(-2);
        const bday = selectedDay.day;
        const zeroday = ('0'+bday).slice(-2);
        const calendarDate = new Date(`${byear}-${zeromonth}-${zeroday}T06:00:00.000`);

        const addReport = () => {
            return new Promise((resolve, reject) => {
              api.post('/dailyDiary/add', { sessionID: session, classID, className, calendarDate, subjectID, subjectName, info, isAssignment }).then(res => {
                return resolve(res.data.dailyDiary);
              }).catch(err => reject(err));
            });
        };

        const addReportAttach = (formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/dailyDiary/attach/add', formData).then(res => {
                    return resolve(res.data.dailyDiary);
              }).catch(err => reject(err));
            });
        };

        setShowLoading(true);
        if (fileUploads.length === 0)
        {
            addReport()
            .then((data: any) => {

                let theEntries = [...entries];
                theEntries.unshift(data);
                setEntries(theEntries);
                setIsOpen(false);
                setPass(t("entry_updated"));
                setIspass(true);

            })
            .catch((error) => {
                //
            })
            .finally(() => setShowLoading(false));
        }
        else
        {
            let formData = new FormData();
            formData.append('sessionID', session);
            formData.append('classID', classID);
            formData.append('className', className);
            formData.append('subjectID', subjectID);
            formData.append('subjectName', subjectName);
            formData.append('info', info);
            formData.append('theDate', `${byear}-${zeromonth}-${zeroday}T06:00:00.000`);
            formData.append('assignment', isAssignment ? 'yes' : 'no');

            for (let i=0; i < fileUploads.length; i++)
            {
                formData.append('diary[]', fileUploads[i].file, fileUploads[i].caption);
            }

            addReportAttach(formData)
            .then((data: any) => {

                let theEntries = [...entries];
                theEntries.unshift(data);
                setEntries(theEntries);

                setFileUploads([]);
                setIsOpen(false);

                setPass(t("entry_updated"));
                setIspass(true);
            })
            .catch((error) => {
                //
            })
            .finally(() => setShowLoading(false));
        }


        /** 
        const upReport = () => {
            return new Promise((resolve, reject) => {
                api.put('/dailyDiary/'+diaryID, { info }).then(res => {
                    return resolve(res.data);
                }).catch(err => reject(err));
            });
        };

        const upAttach = (formData: FormData) => {
            return new Promise((resolve, reject) => {
                api.post('/dailyDiary/attach/update', formData).then(res => {
                    return resolve(res.data.dailyDiary);
                }).catch(err => reject(err));
            });
        };

        if (diaryID !== "")
            {
                if (fileUploads.length === 0)
                {
                    upReport()
                    .then(data => {
                        setPass(t("entry_updated"));
                        setIspass(true);
                    })
                    .catch((error) => {
                        //
                    })
                    .finally(() => setShowLoading(false));
                }
                else
                {
                    let formData = new FormData();
    
                    formData.append('diaryID', diaryID);
                    formData.append('info', info);
    
                    for (let i=0; i < fileUploads.length; i++)
                    {
                        formData.append('diary[]', fileUploads[i].file, fileUploads[i].caption);
                    }
        
                    setShowLoading(true);
                    upAttach(formData)
                    .then(data => {
                        setFileUploads([]);
                        setPass(t("entry_updated"));
                        setIspass(true);
                    })
                    .catch((error) => {
                       //
                    })
                    .finally(() => setShowLoading(false));
                }
            }
        */
        
    }

    const handleDelete = () => {
  
        const deleteApp = () => {
            return new Promise((resolve, reject) => {
                api.delete(`/dailyDiary/${entryID}`).then(res => {
                    return resolve(true);
                }).catch(err => reject(err));
            });
        }
  
        setShowLoading(true);
        deleteApp()
        .then(() => {
            let theEntries = [...entries];
            const index = theEntries.findIndex((a) => a._id === entryID);
            if(index > -1)
            {
                theEntries.splice(index, 1);
            }
            setEntries(theEntries);
            setEntryID("");
            setIsView(false);
            setPass(t("entry_removed"));
            setIspass(true);

        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
        
    }

    const openDiary = (diaryID: string) => {
        const entryObj = entries.find(val => val._id === diaryID)!;
        setEntryID(diaryID);
        setEmployeeID(entryObj.employee.userID);
        setViewTitle(entryObj.subjectInfo.subjectName);
        setViewInfo(entryObj.info);
        setViewIsAssignment(entryObj.isAssignment);
        setFiles(entryObj.files || []);
        setIsView(true);
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && !authInfo.user.roles.includes("Academic") && authInfo.user.roles.includes("Teacher")))
                {
                    const classy = await api.get(`/classes/getByTeacher/${authInfo.user._id}`);
                    setClasses(classy.data);
                }
                if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
                {
                    const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                    setSessions(res.data);
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };
      
        fetchData();
     
    }, [authInfo]);


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/academics`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_diary")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                    {showDownloading && (
                        <IonProgressBar type="indeterminate"></IonProgressBar>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_diary")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                        }

                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    header={t("confirm")}
                    message={t("confirm_delete_entry")}
                    buttons={[
                        {
                        text: t("no"),
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: t("yes"),
                        handler: () => {
                            handleDelete();
                        }
                        }
                    ]}
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding mb-60">
                    {/** 
                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className='note-card-1'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="secondary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            <p>
                                                {t("diary_note")}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    */}
                    <IonRow className='mt-10'>
                          
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonRow>
                            {((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic"))) && (
                    
                                <IonCol size="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("class_session")}
                                                </IonLabel>
                                                <IonSelect value={session}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("class_session")}
                                                    onIonChange={
                                                        (e) => handleSession(e.detail.value)
                                                }>

                                                {sessions.map((session) => 
                                                    (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                )}
                                                </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            )}

                                <IonCol size="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {(authInfo.institute.type === "school") ? t("class") : t("batch")}
                                                </IonLabel>
                                                <IonSelect value={classID}
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                        onIonChange={
                                                            (e) => handleClassID(e.detail.value)
                                                    }>
                                                        {classes.map((classy) => (
                                                            (classy.isActive) &&
                                                            (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                        ))}
                                                </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>

                    {(selectedDay && (<>

                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>

                                <IonCardContent className="ion-no-padding">

                                        <IonItem detail={false}
                                            lines='full'
                                            button={true}
                                            id="open-date-input">
                                            <IonLabel position="floating" color="secondary">{t("month")}</IonLabel>
                                            <IonInput value={selectedDate === '' ? t("pick_month") : formatDate(selectedDate)} readonly={true} />
                                            <IonPopover trigger="open-date-input" showBackdrop={false} size='cover'>
                                                <IonDatetime
                                                    cancelText={t("cancel")}
                                                    doneText={t("ok")}
                                                    value={selectedDate}
                                                    presentation="month-year"
                                                    max={new Date(Date.now()).toISOString()}
                                                    onIonChange={ev => handleSubmit(ev.detail.value!)}
                                                    showDefaultButtons={true}
                                                    size="cover"
                                                />
                                            </IonPopover>
                                        </IonItem>

                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow className="ion-padding">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                        {(minDay && maxDay) && (
                            <div className="vertical-container">
                                <div className="vertical-center"> 
                                    <Calendar 
                                        value={selectedDay}
                                        onChange={handleDate}
                                        minimumDate={minDay}
                                        maximumDate={maxDay}
                                        shouldHighlightWeekends={true}
                                        customDaysClassName={attend}
                                        calendarClassName="custom-calendar"
                                    />
                                </div>
                            </div>
                       
                         )}
                        </IonCol>
                    </IonRow>
                    
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12" className='ion-text-center'>
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("entries_for")} {formatDated(selectedDay)}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className='action-card-one'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonItem lines="none"
                                        button={true}
                                        onClick={() => setIsOpen(true)}
                                    >
                                        <IonIcon icon={addCircleOutline}
                                            slot="start"
                                            color="secondary" />
                                        <IonLabel className="action-title-one"><h3>{t("add_entry")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                            
                        </IonCol>
                    </IonRow>
                    <IonModal isOpen={isOpen} showBackdrop={true} onDidDismiss={() => setIsOpen(false)}
                        initialBreakpoint={0.95} breakpoints={[0, 0.95]} handle={true}>

                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                            <IonToolbar>
                                <IonTitle>{t("add_entry")}</IonTitle>
                            </IonToolbar>

                            {(authInfo.institute.type === "school") && (
                            <IonRow>
                                <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}
                                                >
                                                <IonLabel position="stacked" color="secondary" className="input-label">{t("subject")}*</IonLabel>
                                                    <IonSelect value={subjectID}
                                                        className="input-field"
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        placeholder={t("select_subject")}
                                                        onIonChange={
                                                            (e) => {
                                                                e.detail.value && setSubjectID(e.detail.value);
                                                            }

                                                    }>
                                                        <IonSelectOption value='general'>{t("general")}</IonSelectOption>
                                                        { subjects.map((subject) => {
                                                                
                                                            return (<IonSelectOption key={subject.subjectID} value={`${subject.subjectID}`}>{subject.subjectName}</IonSelectOption>);
                                                                
                                                            })
                                                        }
                                                    </IonSelect>       
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )}
                            {(subjectID === "general") && (
                            <IonRow>
                                <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("entry_title")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("entry_title")}
                                                        value={title}
                                                        onIonInput={
                                                            (e) => setTitle(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )}
                             <IonRow>
                                <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                            <IonItem detail={false}
                                                lines='none'
                                                button={false}
                                            >
                                                <IonLabel position="floating" color="dark">{t("entry_info")}</IonLabel>
                                                <IonTextarea rows={6} autoGrow={true} value={info} onIonInput={
                                                        (e) => setInfo(e.detail.value!)
                                                }></IonTextarea>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}
                                                >
                                                <IonLabel className="input-label">{t("allow_submissions")}</IonLabel>
                                                <IonToggle color="dark" checked={isAssignment} onIonChange={e => setIsAssignment(e.detail.checked)} />
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                    <IonCard className='action-card-one'>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                            <IonItem lines="none"
                                                color="primary"
                                            button={true}
                                            onClick={() => setPop(true)}
                                            >
                                                <IonIcon icon={addCircleOutline}
                                                    slot="start" />
                                                <IonLabel className="action-title-default"><h3>{t("attach_files")}</h3></IonLabel>
    
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                    <IonPopover className='upload-popup' isOpen={pop} onDidDismiss={e => setPop(false)}>
                                        <IonRow>
                                            <IonCol>
                                                <IonCard>
                                                    <IonCardContent className="ion-no-padding">
                                                        <IonItem detail={false}
                                                            lines='full'
                                                            button={false}>
                                                            <IonLabel position="stacked" color="secondary">{t("title")}*</IonLabel>
                                                            <IonInput type="text"
                                                                value={caption}
                                                                placeholder={t("title")}
                                                                onIonInput={
                                                                    (e) => setCaption(e.detail.value !)
                                                            }></IonInput>
                                                        </IonItem>
                                                        {isPlatform("capacitor") && (
                                                        <IonItem detail={true}
                                                            lines='none'
                                                            button={true}
                                                            onClick={takePhoto}
                                                            >
                                                            <IonIcon
                                                                slot="start"
                                                                icon={camera}
                                                            />
                                                            <IonLabel>
                                                                {t("take_photo")}
                                                            </IonLabel>
                                                            
                                                        </IonItem>
                                                        )}
                                                        <IonItem detail={true}
                                                            lines='none'
                                                            button={true}
                                                            onClick={pickImages}
                                                            >
                                                            <IonIcon
                                                                slot="start"
                                                                icon={images}
                                                            />
                                                            <IonLabel>
                                                                {t("upload_photos")}
                                                            </IonLabel>
                                                            
                                                        </IonItem>
                                                        <IonItem detail={true}
                                                            lines='none'
                                                            button={true}
                                                            onClick={pickVideo}
                                                            >
                                                            <IonIcon
                                                                slot="start"
                                                                icon={videocam}
                                                            />
                                                            <IonLabel>
                                                                {t("upload_video")}
                                                            </IonLabel>
                                                                
                                                        </IonItem>
                                                        <IonItem detail={true}
                                                            lines='none'
                                                            button={true}
                                                            onClick={pickFile}
                                                            >
                                                            <IonIcon
                                                                slot="start"
                                                                icon={attach}
                                                            />
                                                            <IonLabel>
                                                                {t("attach_doc")}
                                                            </IonLabel>
                                                                
                                                        </IonItem>
                                                        {(upload.length > 0) && (
                                                        <IonItem detail={true}
                                                                color="danger"
                                                                detailIcon={trash}
                                                                lines='none'
                                                                button={true}
                                                                onClick={() => {
                                                                    setUpload([]);
                                                                    setImageURL([]);
                                                                }}
                                                            >
                                                            <IonBadge color="light" slot="start">{upload.length}</IonBadge>
                                                            <IonLabel>
                                                                {t("remove_files")}
                                                            </IonLabel>
                                                            
                                                        </IonItem>
                                                        )}
                                                    
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="ion-padding-horizontal">
                                                <IonButton onClick={handleAdd}
                                                    expand="block"
                                                    color="secondary">{t("add_files")}</IonButton>
    
                                            </IonCol>
                                        </IonRow>
                                    </IonPopover>
                                </IonCol>
                            </IonRow>
                            
                            <IonRow>
                                <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
    
                                        {(fileUploads.length > 0) && (fileUploads.map((afile, i) => {
                                        return (
    
                                            <IonCard key={i}>
                                                <IonCardContent className="ion-no-padding ion-no-margin">
                                                    <IonItem lines="none"
                                                    button={false}
                                                    >
                                                        <IonIcon icon={((afile.ext === "jpeg") || (afile.ext === "png")) ? imageOutline : ((afile.ext === "mp4") || (afile.ext === "mov")) ? videocam : attach}
                                                            slot="start"
                                                            color="tertiary"
                                                            onClick={() => ((afile.ext === "jpeg") || (afile.ext === "png")) ? openImage(afile.imageURL, "local") : openFile(afile.imageURL, afile.ext) } 
                                                        />
                                                        <IonLabel className="action-title-two" onClick={() => ((afile.ext === "jpeg") || (afile.ext === "png")) ? openImage(afile.imageURL, "local") : openFile(afile.imageURL, afile.ext)}>
                                                            <h3>{afile.caption}</h3>
                                                        </IonLabel>
                                                        <IonIcon icon={trashOutline}
                                                            slot="end"
                                                            color="danger"
                                                            onClick={() => deleteImage(i)} 
                                                        />
                                                        <IonIcon icon={chevronForwardOutline}
                                                            slot="end"
                                                            className='ml-16'
                                                            color="dark"
                                                            onClick={() => ((afile.ext === "jpeg") || (afile.ext === "png")) ? openImage(afile.imageURL, "local") : openFile(afile.imageURL, afile.ext)} 
                                                        />
    
                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
    
                                        )}))}
    
                                        {(fileUploads.length === 0) && (
                                            <IonCard className='red-card'>
                                                <IonCardContent>
                                                    <IonItem lines="none">
                                                        <IonIcon icon={alertCircleOutline}
                                                            slot="start" 
                                                            color="danger" 
                                                        />
                                                        <IonLabel className="list-title">
                                                            <h3>{t("no_files_uploaded")}</h3>
                                                        </IonLabel>
    
                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
    
                                        )}
                                </IonCol>
                            </IonRow>
                            
                            <IonRow className='mb-60'>
                                <IonCol className="ion-padding-horizontal ion-text-center">
                                    <IonButton onClick={handleEntry}
                                        className="first-button"
                                        fill="clear"
                                    >
                                        {t("submit_entry")}
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonContent>
                        
                    </IonModal>
                    <IonModal isOpen={isView} showBackdrop={true} onDidDismiss={() => setIsView(false)}
                        initialBreakpoint={0.95} breakpoints={[0, 0.95]} handle={true}>

                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                            <IonToolbar>
                                <IonTitle>{t("view_entry")}</IonTitle>
                            </IonToolbar>
                           
                            <IonRow>
                                <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("entry_title")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("entry_title")}
                                                        value={viewTitle}
                                                        readonly={true}
                                                    ></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            
                             <IonRow>
                                <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                            <IonItem detail={false}
                                                lines='none'
                                                button={false}
                                            >
                                                <IonLabel position="floating" color="dark">{t("entry_info")}</IonLabel>
                                                <IonTextarea rows={6} autoGrow={true} value={viewInfo} readonly={true}></IonTextarea>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}
                                                >
                                                <IonLabel className="input-label">{t("allow_submissions")}</IonLabel>
                                                <IonToggle color="dark" checked={viewIsAssignment} disabled={true} />
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            
                            
                            <IonRow>
                                <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                    {(files.length > 0) && (files.map((afile, i) => {
                                        return (
    
                                            <IonCard key={i}>
                                                <IonCardContent className="ion-no-padding ion-no-margin">
                                                    <IonItem lines="none"
                                                    button={false}
                                                    >
                                                        <IonIcon icon={isFileImage(afile.filename) ? imageOutline : isFileVideo(afile.filename) ? videocam : attach}
                                                            slot="start"
                                                            color="tertiary" 
                                                            onClick={() => isFileImage(afile.filename) ? openImage(afile.filename, "web") : downloadFile(afile.filename)} />
                                                        <IonLabel className="action-title-two" onClick={() => isFileImage(afile.filename) ? openImage(afile.filename, "web") : downloadFile(afile.filename)}><h3>{afile.caption}</h3></IonLabel>
                                                        
                                                        <IonIcon icon={chevronForwardOutline}
                                                            slot="end"
                                                            color="dark"
                                                            onClick={() => isFileImage(afile.filename) ? openImage(afile.filename, "web") : downloadFile(afile.filename)} />
    
                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
    
                                        )}))}
    
                                        {(files.length === 0) && (
                                            <IonCard className='red-card'>
                                                <IonCardContent>
                                                    <IonItem lines="none">
                                                        <IonIcon icon={alertCircleOutline}
                                                            slot="start" 
                                                            color="danger" 
                                                        />
                                                        <IonLabel className="list-title">
                                                            <h3>{t("no_files_uploaded")}</h3>
                                                        </IonLabel>
    
                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
    
                                        )}
                                </IonCol>
                            </IonRow>
                            {((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")) 
                            || (authInfo.user._id === employeeID)) && (
                    
                                <IonRow className='mb-60'>
                                    <IonCol className="ion-padding-horizontal ion-text-center">
                                        <IonButton onClick={() => setShowAlert(true)}
                                            className="first-button"
                                            fill="clear"
                                        >
                                            {t("delete_diary")}
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            )}
                        </IonContent>
                        
                    </IonModal>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                        {(entries.length > 0) ? (entries.map((entry) => {
                            return (
                            <IonCard className='border-radius-10 mt-15' key={entry._id} onClick={() => openDiary(entry._id)}>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow className="tran-pad tran-row">
                                        <IonCol size="11">
                                            <IonText className='text-bold'>{entry.subjectInfo.subjectName}</IonText>
                                            <IonText className='tran-label'><br/>{t("updated_by")} {entry.employee.name}</IonText>
                                        </IonCol>
                                         <IonCol size="1">
                                            
                                            <IonIcon className='tran-icon-2 mt-10'
                                                icon={create}
                                                color='dark'
                                                onClick={() => null}
                                            />
                                            
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='ion-padding border-top'>
                                        <IonCol className='diary-col'>
                                            <p>{entry.info}</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="tran-pad class-row">
                                        <IonCol className='ion-text-center'>
                                            <IonText>{entry.files?.length} {t("attachments")}</IonText>
                                        </IonCol>
                                        
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                            
                            )})) : (
                            <IonCard className='red-card'>
                                <IonCardContent className='ion-no-margin ion-no-padding ion-padding-vertical'>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_entries")}</h3></IonLabel>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                            )}
                        </IonCol>
                    </IonRow>
                    </>))}
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default ClassDiary;
