import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, checkboxOutline, construct } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../../components/AuthContext';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';

interface Vehicle {
    _id: string,
    name: string,
    plate: string,
    isActive: boolean
}

interface ParamTypes {
    instituteID: string
}

const Vehicles: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {instituteID} = useParams < ParamTypes > ();
    const [vehicles, setVehicles] = useState < Vehicle[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);
    const [current, setCurrent] = useState < string > ("");
    const [total, setTotal] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    const loadItems = async () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        let limit = skip+30;

        try {
            const result = await api.get(`/vehicles/getByInstitute/${instituteID}?skip=${limit}`);

            if(result.data.vehicles && result.data.vehicles.length)
            {
                setVehicles([...vehicles, ...result.data.vehicles]);
            }
                
            setSkip(limit);

        } catch (error : any) {
            if (error.response !== undefined) 
                setMessage(error.response.data.message);
                else 
                setMessage(t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/vehicles/getByInstitute/${instituteID}`);
                setVehicles(result.data.vehicles);
                setTotal(result.data.total);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, instituteID, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/${instituteID}/transport`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("manage_vehicles")}</IonTitle>
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_vehicles")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding mb-60">
                    <div className="ion-table">

                        <IonRow className="table-title ion-padding">
                            <IonCol size="6" className='table-heading'>
                                <IonIcon 
                                    icon={checkboxOutline}
                                    color="light"
                                    className='table-icon'
                                    />
                                    <IonText className='ion-margin-start'>{t("vehicle_name")}</IonText>
                            </IonCol>
                            <IonCol size="5" className='table-heading'>
                              {t("vehicle_plate")}
                            </IonCol>

                            <IonCol size="1">
                                <IonIcon 
                                    icon={construct}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>
                        <IonRadioGroup value={current} onIonChange={
                                    (e) => setCurrent(e.detail.value!)
                            }>

                            {(vehicles.length > 0) ? (vehicles.map((vehicle, i) => {
                                return (
                                    <IonRow key={vehicle._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                    onClick={
                                        () => setCurrent(vehicle._id)
                                    }>
                                        
                                            <IonCol size="6" className='table-field ' >
                                                <IonRadio value={vehicle._id} className='table-icon' color="secondary" />
                                                <IonText className='ion-margin-start'>{vehicle.name}</IonText>
                                            </IonCol>
                                            <IonCol size="5" className='table-field'>
                                                {vehicle.plate}
                                            </IonCol>
                                            
                                            <IonCol size="1" >
                                                    <IonIcon 
                                                    icon={construct}
                                                    color={vehicle.isActive ? "dark" : "danger"}
                                                    className='table-icon mt-10'
                                                    onClick={
                                                        () => history.push(`/app/vehicle/${vehicle._id}`)
                                                    }
                                                    />

                                            </IonCol>
                                        
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_vehicles")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 
                        </IonRadioGroup>
                    </div>

                            {(vehicles.length > 0) &&
                             <IonRow>
                                <IonCol>
                                    <IonInfiniteScroll
                                        onIonInfinite={async (ev) => {
                                            await loadItems();
                                            ev.target.complete();
                                        }}
                                        >
                                        <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                    </IonInfiniteScroll>
                                    </IonCol>
                                </IonRow>
                            }

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                            {t("showing")} {total === 0 ? t("no_records") : `1 - ${vehicles.length} ${t("of")} ${total}`} 
                        </IonCol>
                        </IonRow>
                            <IonRow className="ion-padding-vertical overflow-x">
                                <IonCol className='min-lg'>

                            <IonButton 
                                onClick={
                                    () => {
                                        if (current === "")
                                        {
                                            setMessage(t("select_vehicle"));
                                            setIserror(true);
                                        }
                                        else
                                        {
                                            history.push(`/app/vehicle/${current}`)
                                        }

                                    }
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("manage_vehicle")}</IonButton>
                            
                            <IonButton 
                                onClick={
                                    () => history.push(`/app/${instituteID}/vehicles/add`)
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("add_new_vehicle")}</IonButton>

                            <IonButton 
                                onClick={
                                    () => {
                                        if (current === "")
                                        {
                                            setMessage(t("select_vehicle"));
                                            setIserror(true);
                                        }
                                        else
                                        {
                                            history.push(`/app/${instituteID}/transactions/transport/${current}`)
                                        }

                                    }
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("add_expense")}</IonButton>

                            <IonButton 
                                onClick={
                                    () => {
                                        if (current === "")
                                        {
                                            setMessage(t("select_vehicle"));
                                            setIserror(true);
                                        }
                                        else
                                        {
                                            history.push(`/app/vehicle/expenses/${current}`)
                                        }

                                    }
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("view_expenses")}</IonButton>
                        </IonCol>
                    </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Vehicles;