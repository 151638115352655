import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { addCircle, alertCircleOutline, arrowUndoCircle, closeCircle, construct, informationCircleOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import useApiService from '../../../../components/ApiService';

interface ParamTypes {
    classID: string
}

interface Subject {
    subjectID: string,
    subjectName: string,
    teacherID: string,
    teacherName: string
}

interface AllSubject {
    _id: string,
    name: string,
    teachers: SubTeacher[],
    isActive: boolean
}

interface SubTeacher {
    teacherID: string,
    teacherName: string
}

const ClassSubjects: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {classID} = useParams < ParamTypes > ();
    const [subjects, setSubjects] = useState < Subject[] > ([]);
    const [allSubjects, setAllSubjects] = useState < AllSubject[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [year, setYear] = useState < string > ("");
    const popover = useRef<HTMLIonPopoverElement>(null);
    const [selectedSubject, setSelectedSubject] = useState < string > ("");
    const [selectedTeacher, setSelectedTeacher] = useState < string > ("");
    const [pop, setPop] = useState < boolean > (false);
    const [refreshed, setRefreshed] = useState < number > (0);

    const handleAdd = () => {

        if (!selectedSubject || selectedSubject === "") {
            setMessage(t("subject_mandatory"));
            setIserror(true);
            return;
        }

        if (subjects.find(e => e.subjectID === selectedSubject)) {
            setMessage(t("subject_exists"));
            setIserror(true);
            return;
          }

        if (!selectedTeacher || selectedTeacher === "") {
            setMessage(t("teacher_mandatory"));
            setIserror(true);
            return;
        }

        const sub = allSubjects.find(item => item._id === selectedSubject)!;
        const teacher = sub.teachers.find(e => e.teacherID === selectedTeacher)!;

        let subs = [...subjects];
        subs.push({'subjectID': selectedSubject, 'subjectName': sub.name, 'teacherID': selectedTeacher, 'teacherName': teacher.teacherName});
        setSubjects(subs);

        popover.current?.dismiss();
    }

    const handleDelete = (index: number) => {
        let subs = [...subjects];
        if (index > -1) {
            subs.splice(index, 1); // 2nd parameter means remove one item only
          }
        setSubjects(subs);
    }

    const handleTeacher = (value: string, index: number) => {

        let subs = [...subjects];
        subs[index].teacherID = value;
        setSubjects(subs);
    }

    const handleSubject = () => {
  
        const upClass = (subjects: Subject[]) => {
          return new Promise((resolve, reject) => {
            api.put('/classes/'+classID, { subjects }).then(res => {
                return resolve(res.data.name);
            }).catch(err => reject(err));
          });
        }
    
        setShowLoading(true);
        upClass(subjects)
        .then(data => {
  
            setPass(data+t("has_been_updated"));
            setIspass(true);
          
        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
        
    }
    
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/classes/' + classID);

                setName(result.data.name);
                setYear(result.data.year);

                if (result.data.subjects)
                    setSubjects(result.data.subjects);

                const subs = await api.get(`/subjects/getByInstitute/${result.data.instituteID}`);
                setAllSubjects(subs.data);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, classID, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref={`/app/class/${classID}`} />
                    </IonButtons>
                   
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("map_subjects")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("map_subjects")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding mb-60">
                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem color="secondary">
                                        
                                        <IonLabel position="stacked" className="input-label">
                                            {t("class_name")}
                                        </IonLabel>
                                            <IonInput type="text"
                                                value={`${name} (${year})`}
                                                readonly={true}>
                                                
                                            </IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className='note-card'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="primary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            <p>
                                                {t("subjects_info")}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                   
                    <IonRow className='mt-30'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12" className='border-bottom-sec ion-padding-bottom'>
                          
                            <IonRow>
                                <IonCol size="8" className='ion-padding-horizontal mt-05'>
                                    <IonText className='list-title'>
                                    {t("class_subjects")}
                                    </IonText>
                                </IonCol>
                                <IonCol size="2" className='ion-text-right'>
                                    
                                    <IonIcon size="large"
                                        icon={arrowUndoCircle}
                                        color='secondary'
                                        onClick={() => setRefreshed(Math.random())}
                                    />
                                
                                
                                </IonCol>
                                <IonCol size="2" className='ion-text-center'>
                                    
                                        <IonIcon size="large"
                                            icon={addCircle}
                                            color='secondary'
                                            onClick={() => setPop(true)}
                                        />
                                    
                                    
                                </IonCol>
                            </IonRow>
                                   
                        </IonCol>
                    </IonRow>
                    <IonPopover ref={popover} isOpen={pop} size='auto' onIonPopoverDidDismiss={() => setPop(false)}>
                        <IonGrid className="ion-no-padding ion-no-margin">
                            <IonRow>
                                <IonCol className='ion-padding'>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}
                                                >
                                                
                                                <IonLabel position="stacked" color="secondary">{t("select_subject")}*</IonLabel>
                                                    <IonSelect value={selectedSubject}
                                                                cancelText={t("cancel")}
                                                                okText={t("ok")}
                                                                    placeholder={t("select_subject")}
                                                                    onIonChange={
                                                                        (e) => {
                                                                            setSelectedSubject(e.detail.value);
                                                                            setSelectedTeacher('');
                                                                            }

                                                                }>
                                                                { allSubjects.map((subject) => {
                                                                        
                                                                    return (<IonSelectOption key={subject._id} value={`${subject._id}`}>{subject.name}</IonSelectOption>);
                                                                        
                                                                    })
                                                                }
                                                    </IonSelect>
                                                            
                                            </IonItem>
                                            {selectedSubject && (
                                                <>
                                                        <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        
                                                        <IonLabel position="stacked" color="secondary">{t("select_teacher")}*</IonLabel>
                                                            <IonSelect value={selectedTeacher}
                                                                        cancelText={t("cancel")}
                                                                        okText={t("ok")}
                                                                            placeholder={t("select_teacher")}
                                                                            onIonChange={
                                                                                (e) => setSelectedTeacher(e.detail.value)
                                                                        }>
                                                                      
                                                                        { allSubjects.find(item => item._id === selectedSubject)!.teachers.map((teacher) => {
                                                                                
                                                                            return (<IonSelectOption key={teacher.teacherID} value={`${teacher.teacherID}`}>{teacher.teacherName}</IonSelectOption>);
                                                                                
                                                                            })
                                                                        }
                                                            </IonSelect>
                                                                    
                                                        </IonItem>
                                                        <IonButton onClick={() => {
                                                                setPop(false);
                                                                popover.current?.dismiss();
                                                                history.push(`/app/subject/${selectedSubject}`);
                                                            }}
                                                            className="third-button"
                                                            fill="clear" >{t("teacher_not_listed")}</IonButton>
                                                 </>
                                            )}

                                        
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="ion-padding-horizontal ion-padding-bottom">
                                    <IonButton onClick={handleAdd}
                                        expand="block"
                                        className="first-button"
                                        fill="clear" >{t("add_subject")}</IonButton>

                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonPopover>
                    
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
           
                             {subjects && (subjects.length > 0) ? (subjects.map((subject, i) => {
                                    return (
                                        <IonCard className='action-card-b' key={subject.subjectID}>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonGrid className="ion-no-padding ion-no-margin">
                                                
                                                    <IonRow className='ion-no-padding mt-10'>
                                                        <IonCol size="11" className='ion-padding-horizontal'>
                                                            <IonText className='sub-label'>{subject.subjectName}</IonText>
                                                        </IonCol>
                                                        <IonCol size="1">
                                                            
                                                                 <IonIcon className='tran-icon'
                                                                    icon={closeCircle}
                                                                    color='dark'
                                                                    onClick={() => handleDelete(i)}
                                                                />
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol size="11">
                                                            <IonItem detail={false}
                                                                lines='none'
                                                                button={false}
                                                                className="item-transparent"
                                                                >
                                                                <IonLabel position="fixed" color="dark">{t("taught_by")}</IonLabel>
                                                                    {(allSubjects.length > 0) && (
                                                                        <IonSelect value={subject.teacherID}
                                                                                cancelText={t("cancel")}
                                                                                okText={t("ok")}
                                                                                placeholder={t("select_teacher")}
                                                                                onIonChange={
                                                                                    (e) => handleTeacher(e.detail.value, i)
                                                                            }>
                                                                            { allSubjects.find(item => item._id === subject.subjectID)!.teachers.map((teacher) => {
                                                                                    
                                                                                return (<IonSelectOption key={teacher.teacherID} value={`${teacher.teacherID}`}>{teacher.teacherName}</IonSelectOption>);
                                                                                    
                                                                                })
                                                                            }
                                                                    
                                                                        </IonSelect>
                                                                        )}
                                                               
                                                            </IonItem>
                                                            
                                                        </IonCol>
                                                        <IonCol size="1" className='mt-10'>
                                                            <IonIcon className='tran-icon'
                                                                    icon={construct}
                                                                    color='dark'
                                                                    onClick={() => history.push(`/app/subject/${subject.subjectID}`)}
                                                                />
                                                        </IonCol>
                                                    </IonRow>
                                                   
                                                </IonGrid>
                                            </IonCardContent>
                                        </IonCard>
                                    )
                                })) : ( 
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                        slot="start" 
                                        color="danger" />
                                        <IonLabel className="list-label"><h3>{t("no_subjects")}</h3></IonLabel>

                                    </IonItem>
                                )
                                } 
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubject}
                                className="first-button"
                                fill="clear">{t("save_changes")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default ClassSubjects;
