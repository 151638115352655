import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { alertCircleOutline, cash, fileTrayFull, notifications } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';
import usePageUnloadHandler from '../../../../components/PageUnloadHandler';
import useApiService from '../../../../components/ApiService';

// Interfaces defining the data structure
interface Student {
    _id: string;
    name: string;
    due: number;
    isActive: boolean;
}
interface ClassInterface {
    _id: string;
    name: string;
    year: string;
    isActive: boolean;
}
interface Session {
    _id: string;
    name: string;
    isActive: boolean;
}

const ViewDues: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const { authInfo } = useAuth()!;
    const history = useHistory();
    const { t } = useTranslation();

    // State variables
    const [students, setStudents] = useState<Student[]>([]);
    const [classes, setClasses] = useState<ClassInterface[]>([]);
    const [sessions, setSessions] = useState<Session[]>([]);
    const [classi, setClassi] = useState<string>("");
    const [session, setSession] = useState<string>("");
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [iserror, setIserror] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [ispass, setIspass] = useState<boolean>(false);
    const [pass, setPass] = useState<string>("");
    const scrollContent = useRef<HTMLIonContentElement | null>(null);
    const [scrollPosition, setScrollPosition] = useState<number>(0);
    const [initialRender, setInitialRender] = useState<boolean>(true);

    // Fetch data from API and handle success or error
    const fetchData = async (url: string, onSuccess: Function) => {
        setShowLoading(true);
        try {
            const result = await api.get(url);
            onSuccess(result.data);
        } catch (error: any) {
            //
        } finally {
            setShowLoading(false);
        }
    };

    // Handler for class selection
    const handleClassi = (classID: string) => {
        fetchData(`/students/getByClass/${classID}`, (data: Student[]) => setStudents(data));
        setClassi(classID);
    };

    // Handler for session selection
    const handleSession = (sessionID: string) => {
        fetchData(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionID}`, (data: ClassInterface[]) => setClasses(data));
        setSession(sessionID);
        setClassi("");
        setStudents([]);
    };

    // Notify parents about payment due
    const handleNotify = (studentID: string, due: number) => {
        setShowLoading(true);
        api.post(`/students/notification`, {
            studentID,
            title: "Payment Due",
            text: `Dear Parent, Rs. ${due} is overdue. Kindly clear your dues now.`,
            type: "payment",
        })
        .then(() => {
            setPass(t("parent_reminded"));
            setIspass(true);
        })
        .catch((error) => {
           //
        })
        .finally(() => setShowLoading(false));
    };

    // Track scroll position
    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if (elem) setScrollPosition(elem.scrollTop);
    };

    // Effect to handle page navigation and restore state on back navigation
    useEffect(() => {

        const storedState = sessionStorage.getItem(history.location.pathname);
        if (history.action === "POP" && storedState) {
            const { students, classes, sessions, classi, session, scrollPosition } = JSON.parse(storedState);
            setStudents(students);
            setClasses(classes);
            setSessions(sessions);
            setClassi(classi);
            setSession(session);
            setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
        } else {
            fetchData(`/sessions/getAllInstitute/${authInfo.user.instituteID}`, (data: Session[]) => setSessions(data));
            setStudents([]);
            setClasses([]);
            setClassi("");
            setSession("");
        }
    
        setInitialRender(false);
    }, [authInfo, history.action, history.location.pathname]);

    // Effect to handle session storage
    useEffect(() => {
        if (!initialRender) {
            const currentState = { students, classes, sessions, classi, session, scrollPosition };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        }
    }, [initialRender, students, classes, sessions, classi, session, scrollPosition, history.location.pathname]);

    // Effect to handle page reload 
    usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/accounting`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("outstanding_dues")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("outstanding_dues")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding mb-60">

                    <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonRow>
                                <IonCol size="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("class_session")}</IonLabel>
                                                    <IonSelect value={session}
                                                            className="input-field"
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={t("class_session")}
                                                            onIonChange={
                                                                (e) => handleSession(e.detail.value)
                                                        }>

                                                            {sessions.map((session) => (
                                                                (session.isActive) && 
                                                                (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                            ))}

                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol size="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                {(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}</IonLabel>
                                                    <IonSelect value={classi}
                                                            className="input-field"
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                            onIonChange={
                                                                (e) => handleClassi(e.detail.value)
                                                        }>
                                                            
                                                            {classes.map((classy) => (
                                                                (classy.isActive) &&
                                                                (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                            ))}
                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>

                            </IonRow>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("students_overdue")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                  
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                          <IonList className='ion-no-padding'>
                             {(students.length > 0) ? (students.map((student, i) => {
                                    return (
                                        <IonItem detail={false}
                                            detailIcon={cash}
                                            lines='full'
                                            button={false}
                                            key={student._id}
                                            className={((i%2) === 0) ? '' : 'alt-item'}
                                            
                                            >
                                            {(student.due > 0) && (
                                            <IonIcon slot="start"
                                                icon={notifications}
                                                color="warning" 
                                                onClick={
                                                    () => handleNotify(student._id, student.due)
                                                }/>
                                            )}
                                            <IonLabel className="list-label text-capitalize" onClick={
                                                    () => history.push(`/app/student/ledger/${student._id}`)
                                                }>
                                                <h3>{student.name} (Rs. {student.due})</h3>
                                            </IonLabel>
                                            <IonIcon slot="end"
                                                icon={fileTrayFull}
                                                color={
                                                    (student.due > 0) ? 'danger' : 'dark'
                                                }
                                                onClick={
                                                    () => history.push(`/app/student/ledger/${student._id}`)
                                                }/>
                                        </IonItem>
                                    )
                                })) : ( 
                                    <IonItem lines="none" className='red-card'>
                                        <IonIcon icon={alertCircleOutline}
                                        slot="start" color="danger"
                                                size="large" />
                                        <IonLabel className="list-label"><h3>{t("no_students")}</h3></IonLabel>

                                    </IonItem>
                                   
                                )
                                } </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default ViewDues;
