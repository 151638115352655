import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonProgressBar,
    IonRow,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { alertCircleOutline, attach, chevronForwardOutline, imageOutline, videocam } from 'ionicons/icons';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { PhotoViewer, Image } from '@capacitor-community/photoviewer';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';
import FileOpener from "../../../components/FileOpener";

interface Attach {
    caption: string,
    filename: string
}
interface ParamTypes {
    diaryID: string
}

const DiaryDetail: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {t} = useTranslation();
    const {diaryID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [showDownloading, setShowDownloading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");

    const [viewTitle, setViewTitle] = useState < string > ("");
    const [viewInfo, setViewInfo] = useState < string > ("");
    const [viewIsAssignment, setViewIsAssignment] = useState < boolean > (false);
    const [files, setFiles] = useState < Attach[] > ([]);
    const [selectedDate, setSelectedDate] = useState < Date > ();

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const isFileImage= (fileName: string) => {
        // Extract the extension from the file name
        const extension = fileName.split('.').pop();
    
        // Check if the extension matches 'jpeg' or 'png'
        return extension === 'jpeg' || extension === 'png';
    };

    const isFileVideo= (fileName: string) => {
        const extension = fileName.split('.').pop();
        return extension === 'mp4' || extension === 'mov';
    };
    
    const openImage = (url: string, location: string) => {

        let images: Image[] = [];
        let startFrom = 0;
        let j = -1;

        if (location === "web")
        {
            for (let i = 0; i < files.length; i++)
            {
                if(isFileImage(files[i].filename))
                {
                    j += 1;
                    images.push({url: `${process.env.REACT_APP_STATIC_S3}/diary/${files[i].filename}`, title: files[i].caption});
                    if (files[i].filename === url)
                    {
                        startFrom = j;
                    }
                }
            }
        }

        if (images.length > 1)
        {
            PhotoViewer.show({images, mode: isPlatform('mobile') ? 'slider' : 'gallery', startFrom});
        }
        if (images.length === 1)
        {
            PhotoViewer.show({images, mode: 'one'});
        }

    };

    const downloadFile = async (fileName : string) => {

        const myArray = fileName.split(".");
        const mimeType = getMimeType(myArray[1]);
        const url = process.env.REACT_APP_STATIC_S3 + '/diary/' + fileName;

        if (isPlatform('capacitor') && !showDownloading)
        {
            try {
                Filesystem.checkPermissions().then(async (res) => {
                    if (res.publicStorage !== 'granted') {
                        Filesystem.requestPermissions().then(async (ress) => {
                            if (ress.publicStorage === 'denied') {
                                setMessage(t("storage_denied"));
                                setIserror(true);
                            }
                            else {
                                setShowDownloading(true);
                                try {
                                    const savedFile = await Filesystem.downloadFile({
                                        path: fileName,
                                        url,
                                        directory: Directory.External,
                                    });
                                    
                                    if(savedFile.path) {
                                        await FileOpener.open({filePath: savedFile.path, contentType: mimeType});
                                    } else {
                                        setMessage(t("download_error"));
                                        setIserror(true);
                                    }
                                    
                                } catch(error: any) {
                                    setMessage(t("download_error"));  
                                    setIserror(true);
                                } finally {
                                    setShowDownloading(false);
                                }
                            }
                        });
                    }
                    else {
                        setShowDownloading(true);
                        try {
                            const savedFile = await Filesystem.downloadFile({
                                path: fileName,
                                url,
                                directory: Directory.External,
                            });
                            
                            if(savedFile.path) {
                                await FileOpener.open({filePath: savedFile.path, contentType: mimeType});
                            } else {
                                setMessage(t("download_error"));
                                setIserror(true);
                            }
                            
                        } catch(error: any) {
                            setMessage(t("download_error"));  
                            setIserror(true);
                        } finally {
                            setShowDownloading(false);
                        }
                    }
                });

            } catch {
                setMessage(t("download_error"));
                setIserror(true);
            } 
        }
        if (isPlatform('capacitor') && showDownloading)
        {
            setMessage(t("download_in_progress"));
            setIserror(true);
        }

        if (!isPlatform('capacitor'))
        {
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.rel = 'noreferrer';
            link.download = 'download'; // Triggers download

            // Append the anchor to the body
            document.body.appendChild(link);

            // Programmatically click the link
            link.click();

            // Remove the anchor from the document
            document.body.removeChild(link);
        }

    };

    const getMimeType = (ext: string) => {
        let mime = "";
        if (ext === 'jpeg')
            mime = 'image/jpeg';
        if (ext === 'png')
            mime = 'image/png';
        if (ext === 'pdf')
            mime = 'application/pdf';
        if (ext === 'mp4')
            mime = 'video/mp4';
        if (ext === 'mov')
            mime = 'video/quicktime';
        if (ext === 'docx')
            mime = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        if (ext === 'xlsx')
            mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (ext === 'pptx')
            mime = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        if (ext === 'doc')
            mime = 'application/msword';
        if (ext === 'xls')
            mime = 'application/vnd.ms-excel';
        if (ext === 'ppt')
            mime = 'application/vnd.ms-powerpoint';
        return mime;
    };

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const result = await api.get(`/dailyDiary/${diaryID}`);

                const entryObj = result.data;

                setViewTitle(entryObj.subjectInfo.subjectName);
                setViewInfo(entryObj.info);
                setViewIsAssignment(entryObj.isAssignment);
                setFiles(entryObj.files || []);
                setSelectedDate(entryObj.calendarDate);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };
      
        fetchData();
     
    }, [diaryID]);


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/diary`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_entry")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                    {showDownloading && (
                        <IonProgressBar type="indeterminate"></IonProgressBar>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_entry")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding mb-60">

                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("entry_date")}
                                        </IonLabel>
                                            <IonInput type="text"
                                                className="input-field"
                                                placeholder={t("entry_date")}
                                                value={selectedDate && formatDate(selectedDate)}
                                                readonly={true}
                                            ></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("entry_title")}
                                        </IonLabel>
                                            <IonInput type="text"
                                                className="input-field"
                                                placeholder={t("entry_title")}
                                                value={viewTitle}
                                                readonly={true}
                                            ></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}
                                    >
                                        <IonLabel position="floating"  className="input-label" color="secondary">{t("entry_info")}</IonLabel>
                                        <IonTextarea rows={6} autoGrow={true} value={viewInfo} readonly={true}></IonTextarea>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            {(files.length > 0) && (files.map((afile, i) => {
                                return (

                                    <IonCard key={i}>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                            <IonItem lines="none"
                                            button={false}
                                            >
                                                <IonIcon icon={isFileImage(afile.filename) ? imageOutline : isFileVideo(afile.filename) ? videocam : attach}
                                                    slot="start"
                                                    color="tertiary" 
                                                    onClick={() => isFileImage(afile.filename) ? openImage(afile.filename, "web") : downloadFile(afile.filename)} />
                                                <IonLabel className="action-title-two" onClick={() => isFileImage(afile.filename) ? openImage(afile.filename, "web") : downloadFile(afile.filename)}><h3>{afile.caption}</h3></IonLabel>
                                                
                                                <IonIcon icon={chevronForwardOutline}
                                                    slot="end"
                                                    color="dark"
                                                    onClick={() => isFileImage(afile.filename) ? openImage(afile.filename, "web") : downloadFile(afile.filename)} />

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>

                                )}))}

                                {(files.length === 0) && (
                                    <IonCard className='red-card'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start" 
                                                    color="danger" 
                                                />
                                                <IonLabel className="list-title">
                                                    <h3>{t("no_files_uploaded")}</h3>
                                                </IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>

                                )}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default DiaryDetail;
