import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToggle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { addCircle, alertCircleOutline, closeCircle, personRemove, woman } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';
import useApiService from '../../../../components/ApiService';

interface Course {
    _id: string,
    name: string,
    isActive: boolean
}
interface AllTeacher {
    _id: string,
    userInfo: UserInfo
}
interface UserInfo {
    userID: string,
    name: string
}
interface Teacher {
    teacherID: string,
    teacherName: string,
}
interface Chapter {
    chapterName: string,
    isActive: boolean,
    order?: number
}

const AddSubject: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [teachers, setTeachers] = useState < Teacher[] > ([]);
    const [allTeachers, setAllTeachers] = useState < AllTeacher[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [academic, setAcademic] = useState < boolean > (false);
    const [courses, setCourses] = useState < Course[] > ([]);
    const [course, setCourse] = useState < string > ("");
    const [selectedTeacher, setSelectedTeacher] = useState < string > ("");
    const popover = useRef<HTMLIonPopoverElement>(null);
    const [pop, setPop] = useState < boolean > (false);
    const [chapters, setChapters] = useState < Chapter[] > ([]);

    const handleToggle = (syllabus: boolean) => {
        
        if (syllabus)
        {
    
            const fetchData = async () => {
                setShowLoading(true);
                try {
                    const result = await api.get('/courses/getMaster');
                    setCourses(result.data);
    
                } catch (error: any) {
                    //
                } finally {
                    setShowLoading(false);
                }
            };
    
            fetchData();
        }
        else
        {
            setCourses([]);
            setCourse("");
        }
        setAcademic(syllabus);
    }

    const handleName = (name: string, index: number) => {

        let comps = [...chapters];
        comps[index].chapterName = name;
        setChapters(comps);
    
    }

    const handleDelete = (index: number) => {
        let comps = [...chapters];
        if (index > -1) {
            comps.splice(index, 1); // 2nd parameter means remove one item only
          }
        setChapters(comps);
    }

    const handleChapterAdd = () => {
        let comps = [...chapters];
        comps.push({chapterName: '', isActive: true});
        setChapters(comps);
    }

    const handleAdd = () => {

        if (!selectedTeacher || selectedTeacher.trim() === "") {
            setMessage(t("teacher_mandatory"));
            setIserror(true);
            return;
        }

        const result = allTeachers.find(item => item._id === selectedTeacher)!;

        if (teachers.find(e => e.teacherID === result.userInfo.userID)) {
            setMessage(t("teacher_added"));
            setIserror(true);
            return;
        }

        let teachs = [...teachers];
        teachs.push({'teacherName': result.userInfo.name, 'teacherID': result.userInfo.userID});
        setTeachers(teachs);

        popover.current?.dismiss();
    }

    const handleRemove = (index: number) => {
        let teachs = [...teachers];
        if (index > -1) {
            teachs.splice(index, 1); // 2nd parameter means remove one item only
          }
        setTeachers(teachs);
    }

    const handleSubmit = () => {

        if (!name || name.trim() === "") {
            setMessage(t("name_mandatory"));
            setIserror(true);
            return;
        }

        let chaps: Chapter[] = [];
        for (let i = 0; i < chapters.length; i++)
        {
            if (chapters[i].chapterName.trim() === "")
            {
                setMessage(t("chapter_mandatory"));
                setIserror(true);
                return;
            }
            chaps.push({chapterName: chapters[i].chapterName, isActive: chapters[i].isActive, order: i+1})
        }
  
        const addSubject = (chapters: Chapter[]) => {
          return new Promise((resolve, reject) => {
            api.post('/subjects/add', { 'instituteID': authInfo.user.instituteID, name, teachers, chapters }).then(res => {
                return resolve(res.data.subject.name);
            }).catch(err => reject(err));
          });
        }
  
        const addAcadSubject = (courseInfo: { courseID: string; courseName: string; } ) => {
            return new Promise((resolve, reject) => {
              api.post('/subjects/add', { 'instituteID': authInfo.user.instituteID, name, teachers, courseInfo }).then(res => {
                    return resolve(res.data.subject.name);
              }).catch(err => reject(err));
            });
        }

          
        if (academic && course && course.trim() !== "")
        {
            const courseInfo = {
                'courseID': course,
                'courseName': courses.find((val) => val._id === course)!.name
            };
            setShowLoading(true);
            addAcadSubject(courseInfo)
            .then(data => {
      
                setPass(data+t("has_been_added"));
                setIspass(true);
              
            })
            .catch((error) => {
               //
            })
            .finally(() => setShowLoading(false));
        }
        else
        {
            setShowLoading(true);
            addSubject(chaps)
            .then(data => {
            
                setPass(data+t("has_been_added"));
                setIspass(true);
            })
            .catch((error) => {
                //
            })
            .finally(() => setShowLoading(false));
        }
        
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const all = await api.get(`/employees/getTeachers/${authInfo.user.instituteID}`);
                setAllTeachers(all.data);
                setTeachers([]);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchData();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/academics`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("add_subject")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("add_subject")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app/academics`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding">
                    <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("subject_details")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("subject_name")}
                                        </IonLabel>
                                            <IonInput type="text"
                                                className="input-field"
                                                placeholder={t("subject_name")}
                                                value={name}
                                                onIonInput={
                                                    (e) => setName(e.detail.value!)
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className='mt-30'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12" className='border-bottom-sec ion-padding-bottom'>
                            <IonRow>
                                <IonCol size="10" className='ion-padding-horizontal mt-05'>
                                    <IonText className='list-title'>
                                        {t("subject_teachers")}
                                    </IonText>
                                </IonCol>
                                
                                <IonCol size="2" className='ion-text-center'>
                                    <IonIcon size="large"
                                        icon={addCircle}
                                        color='secondary'
                                        onClick={() => setPop(true)}
                                    />
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    <IonPopover ref={popover} isOpen={pop} size='auto' onIonPopoverDidDismiss={() => setPop(false)}>
                        <IonGrid className="ion-no-padding ion-no-margin">
                            <IonRow>
                                <IonCol className='ion-padding'>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}
                                                
                                            >
                                                
                                                <IonLabel position="stacked" color="secondary">{t("teacher")}*</IonLabel>
                                                <IonSelect value={selectedTeacher}
                                                    placeholder={t("select_teacher")}
                                                    onIonChange={
                                                        (e) => setSelectedTeacher(e.detail.value)
                                                }>
                                                { allTeachers.map((teacher) => {
                                                        
                                                        return (<IonSelectOption key={teacher._id} value={teacher._id}>{teacher.userInfo.name}</IonSelectOption>);
                                                        
                                                    })
                                                }
                                                </IonSelect>
                                                            
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="ion-padding-horizontal ion-padding-bottom">
                                    <IonButton onClick={handleAdd}
                                        expand="block"
                                        className="first-button"
                                        fill="clear">{t("add_teacher")}</IonButton>

                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonPopover>

                   <IonRow className='mt-30'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                             {(teachers.length > 0) ? (teachers.map((teacher, i) => {
                                    return (
                                        <IonCard className='ion-no-padding ion-no-margin' key={i}>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonItem detail={true}
                                                            detailIcon={personRemove}
                                                            lines='full'
                                                            button={true}
                                                            key={teacher.teacherID}
                                                            className={((i%2) === 0) ? '' : 'alt-item'}
                                                            onClick={
                                                                () => handleRemove(i)
                                                            }>
                                                            <IonIcon slot="start"
                                                                icon={woman}
                                                                />
                                                            <IonLabel className='list-label'>
                                                                <h3>{teacher.teacherName}</h3>
                                                            </IonLabel>
                                                        </IonItem>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    )
                                })) : ( 
                                     <IonCard className='red-card'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_teachers")}</h3></IonLabel>
        
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                )}
                        </IonCol>
                    </IonRow>

                     {/*
                        <IonItem detail={false}
                            lines='full'
                            button={false}
                            color="tertiary"
                            >
                            <IonLabel>Assign Syllabus?</IonLabel>
                            <IonToggle slot="end" color="secondary" checked={academic} onIonChange={e => handleToggle(e.detail.checked)} />
                        </IonItem>

                        {academic && (courses.length > 0) && (
                            <IonItem detail={false}
                                lines='full'
                                button={false}>
                                    <IonLabel position="fixed" color="secondary">Course</IonLabel>
                                        <IonSelect value={course}
                                                placeholder="Select Course"
                                                onIonChange={
                                                    (e) => setCourse(e.detail.value)
                                            }>

                                            { courses.map((cou) => {
                                                    if (cou.isActive)
                                                    {
                                                        return (<IonSelectOption key={cou._id} value={`${cou._id}`}>{cou.name}</IonSelectOption>);
                                                    }
                                                    else
                                                    {
                                                        return null;
                                                    }
                                                })
                                            }
                                </IonSelect>
                            </IonItem>
                        )}
                    */}

                    <IonRow className='mt-30'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12" className='border-bottom-sec ion-padding-bottom'>
                            
                            <IonRow>
                                <IonCol size="10" className='ion-padding-horizontal mt-05'>
                                    <IonText className='list-title'>
                                        {t("chapters")}
                                    </IonText>
                                </IonCol>
                                
                                <IonCol size="2" className='ion-text-center'>
                                    <IonIcon size="large"
                                        icon={addCircle}
                                        color='secondary'
                                        onClick={handleChapterAdd}
                                    />
                                </IonCol>
                            </IonRow>
                                    
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                        {(chapters.length > 0) ? (chapters.map((chapter, i) => {
                        return (
                        <IonCard className='ion-no-padding ion-no-margin' key={i}>
                            <IonCardContent className="ion-no-padding ion-no-margin">
                                <IonRow>
                                    <IonCol size="11">
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">{t("chapter")} #{i+1}</IonLabel>
                                            <IonInput type="text"
                                                placeholder={t("chapter_title")}
                                                value={chapter.chapterName}
                                                onIonInput={
                                                    (e) => handleName(e.detail.value!, i)
                                            }></IonInput>
                                        </IonItem>
                                    </IonCol>
                                    
                                    <IonCol size="1">
                                        <IonIcon className='tran-icon mt-10'
                                            icon={closeCircle}
                                            color='dark'
                                            onClick={() => handleDelete(i)}
                                        />
                                    </IonCol>
                                </IonRow>
                            </IonCardContent>
                        </IonCard>
                        )})) : (
                            <IonCard className='red-card'>
                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_chapters")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        )}
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("add_subject")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddSubject;
