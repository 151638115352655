import {
  IonRouterOutlet,
} from "@ionic/react";

import React from 'react';
import { Redirect, Route, Switch } from "react-router";

import AdmissionHome from "../pages/Home/AdmissionHome";
import AllSchools from "../pages/General/AllSchools";
import SchoolProfile from '../pages/General/SchoolProfile';
import CourseDetail from '../pages/General/CourseDetail';
import AllWorkshops from "../pages/General/AllWorkshops";
import WorkshopDetail from '../pages/General/WorkshopDetail';

import BookWorkshop from "../pages/Admission/Workshop/BookWorkshop";
import Workshops from "../pages/Admission/Workshop/Workshops";
import WorkshopHistory from "../pages/Admission/Workshop/WorkshopHistory";
import Dashboard from "../pages/Admission/Dashboard";
import Shortlisted from "../pages/Admission/Shortlisted";
import Apply from "../pages/Admission/Application/Apply";
import CommonForm from "../pages/Admission/Form/CommonForm";
import ApplicationDetail from "../pages/Admission/Application/ApplicationDetail";
import PendingApplications from "../pages/Admission/Application/PendingApplications";
import SubmittedApplications from "../pages/Admission/Application/SubmittedApplications";
import StudentProfile from "../pages/Admission/Student/StudentProfile";
import Students from "../pages/Admission/Student/Students";
import AddStudent from "../pages/Admission/Student/AddStudent";
import Payments from "../pages/Admission/Payment/Payments";
import Checkout from "../pages/Admission/Payment/Checkout";
import AppSuccess from "../pages/Admission/Payment/AppSuccess";
import AppFailed from "../pages/Admission/Payment/AppFailed";

import AdmnInquiries from "../pages/Admission/Inquiry/AdmnInquiries";
import AdmnInquiryDetail from "../pages/Admission/Inquiry/AdmnInquiryDetail";
import Appointments from "../pages/Admission/Application/Appointments";

import UserNotifications from "../pages/User/UserNotifications";
import Profile from "../pages/User/Profile";
import UpdatePersonal from "../pages/User/UpdatePersonal";
import UpdatePassword from "../pages/User/UpdatePassword";
import Terms from "../pages/General/Policies/TermsAndConditions";
import Privacy from "../pages/General/Policies/PrivacyPolicy";
import ContactUs from "../pages/General/ContactUs";
import DeleteAccount from "../pages/General/DeleteAccount";

const AdmissionTabs: React.FC = () => {
  return (
      <IonRouterOutlet>
        <Switch>
            <Route path="/app/home" exact={true} component={AdmissionHome} />
            <Route path="/app/dashboard" exact={true} component={Dashboard} />
            <Route path="/app/shortlisted" exact={true} component={Shortlisted} />
            <Route path="/app/schools/:regionID" exact={true} component={AllSchools} />
            <Route path="/app/school/:schoolID" exact={true} component={SchoolProfile}/>
            <Route path="/app/school/:schoolID/course/:courseID" exact={true} component={CourseDetail}/>
            <Route path="/app/workshops" exact={true} component={AllWorkshops} />
            <Route path="/app/workshop/:workshopID" exact={true} component={WorkshopDetail}/>
            <Route path="/app/workshop/:workshopID/book" exact={true} component={BookWorkshop}/>
            <Route path="/app/workshops/upcoming" exact={true} component={Workshops} />
            <Route path="/app/workshops/attended" exact={true} component={WorkshopHistory} />
            
            <Route path="/app/applications/pending" exact={true} component={PendingApplications} />
            <Route path="/app/applications/submitted" exact={true} component={SubmittedApplications} />
            <Route path="/app/form/:applicationID" exact={true} component={ApplicationDetail} />
            <Route path="/app/payments" exact={true} component={Payments} />
            <Route path="/app/students" exact={true} component={Students} />
            <Route path="/app/students/add" exact={true} component={AddStudent} />
            <Route path="/app/student/:studentID" exact={true} component={StudentProfile} />
            <Route path="/app/application/:schoolID/:classID" exact={true} component={Apply} />
            <Route path="/app/application/:schoolID/:classID/:studentID" exact={true} component={CommonForm} />
            <Route path="/app/application/:applicationID" exact={true} component={Checkout} />
            <Route path="/app/checkout/success/:tranID" exact={true} component={AppSuccess} />
            <Route path="/app/checkout/failed/:tranID" exact={true} component={AppFailed} />
            <Route path="/app/admission/inquiries" exact={true} component={AdmnInquiries} />
            <Route path="/app/admission/inquiry/:inquiryID" exact={true} component={AdmnInquiryDetail} />
            <Route path="/app/appointments" exact={true} component={Appointments} />
            
            <Route path="/app/user/notification" exact={true} component={UserNotifications} />
            <Route path="/app/user/profile" exact={true} component={Profile} />
            <Route path="/app/user/profile/personal" exact={true} component={UpdatePersonal} />
            <Route path="/app/user/profile/password" exact={true} component={UpdatePassword} />
            <Route path="/app/policies/terms" exact={true} component={Terms} />
            <Route path="/app/policies/privacy" exact={true} component={Privacy} />
            <Route path="/app/support/contact" exact={true} component={ContactUs} />
            <Route path="/app/support/delete" exact={true} component={DeleteAccount} />

            <Redirect exact={true} path="/app" to="/app/home" />
            <Redirect from="*" to="/app"/>
          </Switch>
      </IonRouterOutlet>
  );
};

export default AdmissionTabs;
