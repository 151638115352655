import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { download } from 'ionicons/icons';
import { Directory, Filesystem } from '@capacitor/filesystem';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import html2canvas from 'html2canvas';
import { useAuth } from '../../../../components/AuthContext';
import FileOpener from "../../../../components/FileOpener";
import useApiService from '../../../../components/ApiService';

interface ParamTypes {
    receiptID: string
}

interface Due {
    _id: string,
    remarks: string,
    amount: number,
    dueDate: Date,
    createdAt: Date
}

const timestamp = new Date().getTime();

const ReceiptDetail: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {receiptID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [amount, setAmount] = useState < number > (0);
    const [remarks, setRemarks] = useState < string > ("");
    const [mode, setMode] = useState < string > ("");
    const [student, setStudent] = useState < string > ("");
    const [institute, setInstitute] = useState < string > ("");
    const [address, setAddress] = useState < string > ("");
    const [phone, setPhone] = useState < string > ("");
    const [mail, setMail] = useState < string > ("");
    const [fileName, setFileName] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < Date > ();
    const [dues, setDues] = useState < Due[] > ([]);
    const [className, setClassName] = useState < string > ("");
    const [classSession, setClassSession] = useState < string > ("");
    const [admnNo, setAdmnNo] = useState < string > ("");
    const [recNo, setRecNo] = useState < string > ("");
    const [advance, setAdvance] = useState < number > (0);
    const [adjusted, setAdjusted] = useState < number > (0);
    const [reversed, setReversed] = useState < boolean > (false);
    const captureRef = useRef<HTMLIonRowElement | null>(null);

    const downloadImage = async () => {
        try {
            const element = captureRef.current; // Target the element you want to capture

            if (element) {
                const canvas = await html2canvas(element, {
                    useCORS: true, // Enable CORS for images
                    allowTaint: false, // Disable tainting for cross-origin content
                });

                const image = canvas.toDataURL('image/png'); // Convert canvas to image

                const base64Image = image.split(',')[1]; // Remove the data URL header

                const filePath = ((recNo !== "") ? recNo : timestamp) + '.png';

                if (isPlatform("capacitor")) {

                    try {
                        Filesystem.checkPermissions().then(async (res) => {
                            if (res.publicStorage !== 'granted') {
                                Filesystem.requestPermissions().then(async (ress) => {
                                    if (ress.publicStorage === 'denied') {
                                        setMessage(t("storage_denied"));
                                        setIserror(true);
                                    } else {
                                        try {
                                            const savedFile = await Filesystem.writeFile({
                                                path: filePath,
                                                data: base64Image,
                                                directory: Directory.External,
                                            });

                                            if(savedFile.uri) {
                                                // Open the saved image using the FileOpener plugin
                                              await FileOpener.open({filePath: savedFile.uri, contentType: 'image/png'});
                                            } else {
                                                setMessage(t("download_error"));
                                                setIserror(true);
                                            }
                                            
                                        } catch(error: any) {
                                            setMessage(t("download_error"));  
                                            setIserror(true);
                                        }
                                    }
                                });
                            } else {
                                try {
                                    const savedFile = await Filesystem.writeFile({
                                        path: filePath,
                                        data: base64Image,
                                        directory: Directory.External,
                                    });

                                    if(savedFile.uri) {
                                        // Open the saved image using the FileOpener plugin
                                      await FileOpener.open({filePath: savedFile.uri, contentType: 'image/png'});
                                    } else {
                                        setMessage(t("download_error"));
                                        setIserror(true);
                                    }
                                    
                                } catch(error: any) {
                                    setMessage(t("download_error"));  
                                    setIserror(true);
                                } 
                            }
                        });
        
                    } catch {
                        setMessage(t("download_error"));
                        setIserror(true);
                    } 
                   
                } else {
                    const link = document.createElement('a'); // Create a link for download
                    link.href = image;
                    link.download = filePath; // Specify the file name
                    link.click(); // Trigger the download
                }

                
            } else {
                setMessage(t("unable_to_download"));
                setIserror(true);
            }

        } catch (error: any) {
            setMessage(t("unable_to_download"));
            setIserror(true);
        }
      
    };

    const getDate = (utcdate: Date) => {
        const bookDate = new Date(utcdate);
        return bookDate.toLocaleDateString('en-GB');
    };

    const inWords = (num: number) => {
        var a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
        var b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];
        
        if ((num.toString()).length > 9) return '';

        let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!n) return ''; 
        var str = '';
        str += (parseInt(n[1]) !== 0) ? (a[Number(n[1])] || b[Number(n[1][0])] + ' ' + a[Number(n[1][1])]) + 'crore ' : '';
        str += (parseInt(n[2]) !== 0) ? (a[Number(n[2])] || b[Number(n[2][0])] + ' ' + a[Number(n[2][1])]) + 'lakh ' : '';
        str += (parseInt(n[3]) !== 0) ? (a[Number(n[3])] || b[Number(n[3][0])] + ' ' + a[Number(n[3][1])]) + 'thousand ' : '';
        str += (parseInt(n[4]) !== 0) ? (a[Number(n[4])] || b[Number(n[4][0])] + ' ' + a[Number(n[4][1])]) + 'hundred ' : '';
        str += (parseInt(n[5]) !== 0) ? ((str !== '') ? 'and ' : '') + (a[Number(n[5])] || b[Number(n[5][0])] + ' ' + a[Number(n[5][1])]) + 'only' : ' only';

        return str;

    };

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/transactions/' + receiptID);

                setRemarks(result.data.remarks);
                setMode(result.data.mode);
                setAmount(result.data.amount);
                setSelectedDate(result.data.dated);
                setRecNo(result.data.receiptNo);
                
                setStudent(result.data.student.name);
                const stud = await api.get('/students/' + result.data.student.studentID);
                setClassName(stud.data.classInfo.className);
                setClassSession(stud.data.classInfo.session);
                setAdmnNo(stud.data.admnNo);

                const res = await api.get(`/dues/getByReceipt/${result.data.receiptID}`);
                setDues(res.data);

                if (result.data.advance)
                {
                    setAdvance(result.data.advance);
                }
                if (result.data.adjusted)
                {
                    setAdjusted(result.data.adjusted);
                }

                if (result.data.reversed)
                {
                    setReversed(result.data.reversed);
                }

                const insti = await api.get(`/institutes/${authInfo.user.instituteID}`);
                setInstitute(insti.data.name);
                setAddress(insti.data.address);
                setPhone(insti.data.phone);
                setMail(insti.data.email);
                setFileName(insti.data.logo);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, receiptID]);
    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle color="secondary">Receipt</IonTitle>
                    {!reversed && 
                    <IonButtons slot="end">
                        <IonButton onClick={downloadImage}><IonIcon icon={download} color="primary" /></IonButton>
                    </IonButtons>
                    }
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Receipt</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding mb-60">
                    <IonRow ref={captureRef} className="receipt-shade max-lg">
                        <IonCol> 
                            <IonRow className="rec-logo">
                               
                                <IonCol size="2">
                                    <div className="vertical-container">
                                        <div className="vertical-center"> 
                                            {fileName && (fileName !== "") ?
                                                (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${fileName}?v=${timestamp}`} alt="Logo" width="70" crossOrigin="" />)
                                                : (<img src={`assets/images/institute.png`} alt="Logo" width="70" />)
                                            }
                                        </div>
                                    </div>
                                </IonCol>
                                <IonCol size="10" className='ion-padding-horizontal'>
                                    <p className="rec-insti">{institute}</p>
                                    <p className="logo-address">{address}</p>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-horizontal ion-margin-top">
                                <IonCol sizeLg="1.5" size="3.5">
                                    <IonText className='rec-label'>Receipt No. :</IonText>
                                </IonCol>
                                <IonCol sizeLg="4.5" size="8.5">
                                    <IonText className={reversed ? 'rec-label-2' : 'rec-label-1'}>{reversed ? "Reversed" : recNo}</IonText>
                                </IonCol>
    
                                <IonCol sizeLg="1.5" size="3.5">
                                    <IonText className='rec-label'>Date : </IonText>
                                </IonCol>
                                <IonCol sizeLg="4.5" size="8.5">
                                    <IonText className='rec-label-1'>
                                        {selectedDate && getDate(selectedDate)}
                                        </IonText>
                                </IonCol>
                                {admnNo && (admnNo !== "") && (<>
                                <IonCol sizeLg="1.5" size="3.5">
                                    <IonText className='rec-label'>Student No. :</IonText>
                                </IonCol>
                                <IonCol sizeLg="4.5" size="8.5">
                                    <IonText className='rec-label-1'>{admnNo}</IonText>
                                </IonCol>
                                </>)}
                                <IonCol sizeLg="1.5" size="3.5">
                                    <IonText className='rec-label'>Student Name : </IonText>
                                </IonCol>
                                <IonCol sizeLg="4.5" size="8.5">
                                    <IonText className='rec-label-1 text-capitalize'>
                                        {student}
                                        </IonText>
                                </IonCol>
                                <IonCol sizeLg="1.5" size="3.5">
                                    <IonText className='rec-label'>{(authInfo.institute.type === "school") ? "Class" : "Batch"} : </IonText>
                                </IonCol>
                                <IonCol sizeLg="4.5" size="8.5">
                                    <IonText className='rec-label-1'>
                                        {className}
                                        </IonText>
                                </IonCol>
                                <IonCol sizeLg="1.5" size="3.5">
                                    <IonText className='rec-label'>Session : </IonText>
                                </IonCol>
                                <IonCol sizeLg="4.5" size="8.5">
                                    <IonText className='rec-label-1'>
                                        {classSession}
                                        </IonText>
                                </IonCol>
                            </IonRow>

                            <IonRow className="tran-row ion-margin-top">
                                <IonCol size="9" className='ion-text-center ion-padding'>
                                    <IonText className='rec-title'>Fee Head</IonText>

                                </IonCol>
                                <IonCol size="3" className='ion-text-center border-left ion-padding'>
                                    <IonText className='rec-title'>Amount</IonText>
                                
                                </IonCol>
                            </IonRow>
                            {(dues.length > 0) && (dues.map((due, i) => {
                                return (
                                    <IonRow key={due._id} className='receipt-row'>
                                        <IonCol size="9" className='receipt-col'>
                                            {due.remarks}
                                        </IonCol>
                                        
                                        <IonCol size="3" className='receipt-col border-left ion-text-right'>
                                            {due.amount}
                                        </IonCol>
                                    </IonRow>
                                    )
                            }))} 
                            {(advance > 0) && (
                                <IonRow className='receipt-row'>
                                    <IonCol size="9" className='receipt-col'>
                                        Advance
                                    </IonCol>
                                    
                                    <IonCol size="3" className='receipt-col border-left ion-text-right'>
                                        {advance}
                                    </IonCol>
                                </IonRow>
                            )}
                            {(adjusted > 0) && (
                                <IonRow className='receipt-row'>
                                    <IonCol size="9" className='receipt-col'>
                                        Adjusted from account balance
                                    </IonCol>
                                    
                                    <IonCol size="3" className='receipt-col border-left ion-text-right'>
                                        -{adjusted}
                                    </IonCol>
                                </IonRow>
                            )}

                            <IonRow className='receipt-row'>
                                <IonCol size="9" className='receipt-col text-bold'>
                                    Total
                                </IonCol>
                                <IonCol size="3" className='receipt-col border-left ion-text-right text-bold'>
                                    {amount}
                                </IonCol>
                            </IonRow>
                             <IonRow className="ion-padding-horizontal mt-30">
                                <IonCol size="4" className=''>
                                    <IonText className='rec-label'>In Words : </IonText>
                                </IonCol>
                                <IonCol size="8" >
                                    <IonText className='rec-paid'>{inWords(amount)}</IonText>
                                </IonCol>
                            </IonRow>
                            
                            <IonRow className="ion-padding-horizontal">
                                <IonCol size="4" className=''>
                                    <IonText className='rec-label'>Payment Mode : </IonText>
                                </IonCol>
                                <IonCol size="8" >
                                    <IonText className='rec-paid'>{mode}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-horizontal">
                                <IonCol size="4" className=''>
                                    <IonText className='rec-label'>Payment Remarks : </IonText>
                                </IonCol>
                                <IonCol size="8" >
                                    <IonText className='rec-paid'>{remarks}</IonText>
                                </IonCol>
                            </IonRow>

                            <IonRow className="ion-padding mt-30 rec-row">
                                <IonCol className='ion-text-center'>
                                    <IonText className='rec-text'>THANK YOU!</IonText>
                                    <br/><br/>
                                    <IonText className='rec-foot'>For questions or any other inquiries please contact us at: {phone} or {mail}</IonText>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                  
                </IonGrid>

            </IonContent>
          
        </IonPage>
    );
};

export default ReceiptDetail;
