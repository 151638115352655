import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import { alertCircleOutline, book, chevronForwardOutline } from 'ionicons/icons';
import React, { useEffect,  useState } from 'react';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';


interface AllSubject {
    _id: string,
    name: string,
    subjectType: string,
    teach: string[],
    isActive: boolean
}

const Subjects: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [subjects, setSubjects] = useState < AllSubject[] > ([]);

    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };
    
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/subjects/getByTeacher/${authInfo.user._id}`);
                setSubjects(result.data);
  
            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("my_subjects")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("my_subjects")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding mt-30 mb-60">
 
                    <IonRow className="max-xl">
                        
                             {subjects && (subjects.length > 0) ? (subjects.map((subject) => {
                                return (
                                    <IonCol sizeMd="6" size="12" key={subject._id}>
                                         <IonCard className="tertiary-card ion-margin" routerLink={`/app/chapters/${subject._id}`}>
                                            <IonCardContent>
                                                <IonRow>
                                                    <IonCol size="11">
                                                        <IonRow>
                                                            <IonCol>            
                                                                <IonItem lines="none"
                                                                    button={false}
                                                                    detail={false} >
                                                                        <IonIcon icon={book}
                                                                            slot="start"
                                                                            color="tertiary" />
                                                                        <IonLabel className="action-title-two">
                                                                            <h3>{subject.name}</h3>
                                                                        
                                                                        </IonLabel>
                                                                    
                                                                </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>            
                                                                {(subject.teach.length > 0) && (subject.teach.map((tag, i) => {
                                                                    return (<IonText className='tran-tag-two' key={i} >{tag}</IonText>)
                                                                }))}
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCol>
                                                    <IonCol size="1" className='mt-20'>
                                                                                                                    
                                                        <IonIcon className='second-icon'
                                                            icon={chevronForwardOutline}
                                                            color='dark'
                                                        />
                                                        
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    )
                                })) : ( 
                                    <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                        <IonCard className='red-card ion-margin'>
                                            <IonCardContent>
                                                <IonItem lines="none">
                                                    <IonIcon icon={alertCircleOutline}
                                                        slot="start"
                                                        color="danger"
                                                        size="large" />
                                                    <IonLabel className="list-title ion-text-wrap"><h3>{t("no_subjects")}</h3></IonLabel>

                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                )
                                } 

                    </IonRow>

                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Subjects;