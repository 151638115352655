import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import { alertCircleOutline, locationSharp, lockClosedSharp, megaphoneSharp } from 'ionicons/icons';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../components/AuthContext';
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';
import usePageUnloadHandler from '../../components/PageUnloadHandler';
import useApiService from '../../components/ApiService';

interface Institute {
    _id: string,
    name: string,
    address: string,
    phone: string,
    email: string,
    logo: string,
    admnPhoto?: string,
    admnOpen: boolean
}
interface ParamTypes {
    regionID: string
}

const noapi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: parseInt(process.env.REACT_APP_API_TO!)
});

const AllSchools: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { authInfo } = useAuth()!;
    const { api, renderApiAlerts } = useApiService(); 
    const {regionID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [iserror, setIserror] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [institutes, setInstitutes] = useState < Institute[] > ([]);
    const [scrollPosition, setScrollPosition] = useState<number>(0);
    const scrollContent = useRef<HTMLIonContentElement | null>(null);
    const [initialRender, setInitialRender] = useState<boolean>(true);
    const [skip, setSkip] = useState<number>(0);
    const [refreshed, setRefreshed] = useState<number>(0);

    // Refresh handler
    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    // Scroll end handler
    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if (elem) setScrollPosition(elem.scrollTop);
    }

    const shortlist = (schoolID: string, schoolName: string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                await api.get(`/admissionUsers/shortlist/${authInfo.user._id}/${schoolID}`);
                setPass(schoolName+t("has_been_shorted"));
                setIspass(true);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
    }

    // Load items from API
    const loadItems = async () => {
        let limit = skip + 30;

        try {
            const result = await noapi.get(`/regions/getAllHome/${regionID}?skip=${limit}`);

            if (result.data && result.data.length) {
                setInstitutes([...institutes, ...result.data]); 
                setSkip(limit); // Update skip if new institutes are added
            }
                
        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        }
    }

    // Handle side effects
    useEffect(() => {
        const storedState = sessionStorage.getItem(history.location.pathname);

        if (history.action === "POP" && storedState) {
            const { institutes, skip, scrollPosition } = JSON.parse(storedState);
            setInstitutes(institutes);
            setSkip(skip);
            setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
        } else {
            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    const result = await noapi.get(`/regions/getAllHome/${regionID}`);
                    setInstitutes(result.data);
                    setSkip(0);
                } catch (error: any) {
                    setMessage(error.response?.data.message || t("something_went_wrong"));
                    setIserror(true);
                } finally {
                    setShowLoading(false);
                }
            };

            fetchUp();
        }
        setInitialRender(false);
    }, [regionID, refreshed, history.action, history.location.pathname]);

    // Save state to local storage whenever it changes
    useEffect(() => {
        if (!initialRender) {
            const currentState = {
                institutes,
                skip,
                scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        }
    }, [institutes, skip, scrollPosition, history.location.pathname, initialRender]);

   // Effect to handle page reload 
   usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        {(authInfo.loggedIn === true) && (
                            <IonMenuButton color="secondary"/>
                        )}
                        <IonBackButton color="secondary" defaultHref={`/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("all_institutions")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("all_institutions")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>
              
                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-margin-top">
                        <IonCol className="ion-no-padding ion-no-margin" offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                        {
                            (institutes.length > 0) ? (institutes.map((insti) => {
                                return (
                                        <IonCard key={insti._id} className="ion-margin-vertical">
                                            {(insti.admnPhoto !== "") &&
                                            (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${insti.admnPhoto}`} alt={insti.name} onClick={() => (authInfo.loggedIn !== true) ? history.push(`/school/${insti._id}`) : history.push(`/app/school/${insti._id}`)} 
                                                style={{'width': '100%', 'height': 'auto'}} />)
                                            }
                                            <IonCardContent className="ion-no-padding">
                                                <IonGrid className="white-shade">
                                                    <IonRow>
                                                    
                                                        <IonCol size="9" className="insti-info">
                                                            <IonRow className="ion-margin-horizontal pb-08">
                                                                <IonCol>
                                                                    <p><span className="insti-name" onClick={() => (authInfo.loggedIn !== true) ? history.push(`/school/${insti._id}`) : history.push(`/app/school/${insti._id}`)}>{insti.name}</span></p>
                                                                    <p><IonIcon icon={locationSharp} color="secondary" /><span className="insti-address">{insti.address}</span></p>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow className="ion-margin-horizontal pb-08">
                                                                <IonCol>
                                                                    <IonButton className="second-button" fill="clear" onClick={() => (authInfo.loggedIn !== true) ? history.push(`/school/${insti._id}`) : history.push(`/app/school/${insti._id}`)}>{insti.admnOpen ? t("apply_now") : t("profile")}</IonButton>
                                                                    <IonButton className="forth-button ml-08" fill="clear" onClick={() => (authInfo.loggedIn !== true) ? history.push(`/login`) : shortlist(insti._id, insti.name)}>{t("shortlist")}</IonButton>
                                                                    
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow className="ion-margin-horizontal pb-08">
                                                                <IonCol>
                                                                    <p><IonIcon className="mt-05" icon={insti.admnOpen ? megaphoneSharp : lockClosedSharp} color="danger" /><span className="admn-open">{insti.admnOpen ? t("admn_open") : t("admn_closed")}</span></p>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonCol>
                                                        <IonCol size="3" className="ion-text-center">
                                                            <div className="vertical-container">
                                                                <div className="vertical-center"> 
                                                                {(insti.logo !== "") ?
                                                                    (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${insti.logo}`} alt={insti.name} width="60" onClick={() => (authInfo.loggedIn !== true) ? history.push(`/school/${insti._id}`) : history.push(`/app/school/${insti._id}`)}/>)
                                                                    : (<img src={`assets/images/institute.png`} alt={insti.name} width="60" onClick={() => (authInfo.loggedIn !== true) ? history.push(`/school/${insti._id}`) : history.push(`/app/school/${insti._id}`)}/>)
                                                                }
                                                                </div>
                                                            </div>
                                                                
                                                        </IonCol>
                                                    </IonRow>
                                                    
                                                </IonGrid>
                                            </IonCardContent>
                                        </IonCard>
                                        );
                                        })) : (
                                            <IonCard className="ion-margin-vertical red-card">
                                                <IonCardContent>
                                                    <IonItem lines="none">
                                                        <IonIcon icon={alertCircleOutline}
                                                            color="danger"
                                                            slot="start"
                                                            size="large" />
                                                        <IonLabel className="list-title"><h3>{t("no_institutes")}</h3></IonLabel>
                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        )
                                    }

                        </IonCol>
                    </IonRow>

                    {(institutes.length > 0) &&
                        <IonRow>
                            <IonCol>
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                    }
                  
                 
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default AllSchools;