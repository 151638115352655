import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import useApiService from '../../../../components/ApiService';

interface TimeSlot {
    _id: string,
    name: string,
    startTime: string,
    endTime: string,
    isActive: boolean,
    isChecked: boolean
}

interface DaySchedule {
    timeSlot: Slot,
    subject: Subject,
    teacher: Teacher
}

interface Slot {
    timeSlotID: string,
    startTime: string,
    endTime: string
}

interface Subject {
    subjectID: string,
    subjectName: string
}

interface Teacher {
    teacherID: string,
    teacherName: string
}

interface AllSubject {
    subjectID: string,
    subjectName: string,
    teacherID: string,
    teacherName: string
}


interface LState {
    selectedSlots: Array < TimeSlot >,
    classID: string,
    name: string
}

const AddTimeTableTwo: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const location = useLocation < LState > ();
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [session, setSession] = useState < string > ("");
    const [mon, setMon] = useState < DaySchedule[] > ([]);
    const [tue, setTue] = useState < DaySchedule[] > ([]);
    const [wed, setWed] = useState < DaySchedule[] > ([]);
    const [thu, setThu] = useState < DaySchedule[] > ([]);
    const [fri, setFri] = useState < DaySchedule[] > ([]);
    const [sat, setSat] = useState < DaySchedule[] > ([]);
    const [subjects, setSubjects] = useState < AllSubject[] > ([]);
    const [day, setDay] = useState < string > ("mon");

    const handleSubject = (sub: string, index: number, day: string) => {

        if (day === 'mon')
        {
           let mondays: DaySchedule[] = [...mon];

           let times = location.state.selectedSlots[index];

           let foundIndex = mondays.findIndex(x => x.timeSlot.timeSlotID === times._id);

           if (foundIndex >= 0) 
           {

            if (sub === 'Free')
            {
                mondays[foundIndex] = {'timeSlot': {'timeSlotID': times._id, 'startTime': times.startTime, 'endTime': times.endTime}, 
                'subject': {'subjectID': 'Free', 'subjectName': 'Free Period'}, 
                'teacher' : {'teacherID': 'None', 'teacherName': 'None'}}; 
            }
            else if (sub === 'Recess')
            {
               mondays[foundIndex] = {'timeSlot': {'timeSlotID': times._id, 'startTime': times.startTime, 'endTime': times.endTime}, 
               'subject': {'subjectID': 'Recess', 'subjectName': 'Recess'}, 
               'teacher' : {'teacherID': 'None', 'teacherName': 'None'}}; 
            }
           else
            {
                let subject = subjects.find(item => item.subjectID === sub)!;
    
                mondays[foundIndex] = {'timeSlot': {'timeSlotID': times._id, 'startTime': times.startTime, 'endTime': times.endTime}, 
                'subject': {'subjectID': subject.subjectID, 'subjectName': subject.subjectName}, 
                'teacher' : {'teacherID': subject.teacherID, 'teacherName': subject.teacherName}}; 
           }       

        }
        
           setMon(mondays);
        }

        if (day === 'tue')
        {
           let tuesdays: DaySchedule[] = [...tue];

           let times = location.state.selectedSlots[index];

           let foundIndex = tuesdays.findIndex(x => x.timeSlot.timeSlotID === times._id);

           if (foundIndex >= 0) 
           {

            if (sub === 'Free')
            {
                tuesdays[foundIndex] = {'timeSlot': {'timeSlotID': times._id, 'startTime': times.startTime, 'endTime': times.endTime}, 
                'subject': {'subjectID': 'Free', 'subjectName': 'Free Period'}, 
                'teacher' : {'teacherID': 'None', 'teacherName': 'None'}}; 
            }
            else if (sub === 'Recess')
            {
               tuesdays[foundIndex] = {'timeSlot': {'timeSlotID': times._id, 'startTime': times.startTime, 'endTime': times.endTime}, 
               'subject': {'subjectID': 'Recess', 'subjectName': 'Recess'}, 
               'teacher' : {'teacherID': 'None', 'teacherName': 'None'}}; 
            }
           else
            {
                let subject = subjects.find(item => item.subjectID === sub)!;
    
                tuesdays[foundIndex] = {'timeSlot': {'timeSlotID': times._id, 'startTime': times.startTime, 'endTime': times.endTime}, 
                'subject': {'subjectID': subject.subjectID, 'subjectName': subject.subjectName}, 
                'teacher' : {'teacherID': subject.teacherID, 'teacherName': subject.teacherName}}; 
           }       

        }
        
           setTue(tuesdays);
        }

        if (day === 'wed')
        {
           let wednesdays: DaySchedule[] = [...wed];

           let times = location.state.selectedSlots[index];

           let foundIndex = wednesdays.findIndex(x => x.timeSlot.timeSlotID === times._id);

           if (foundIndex >= 0) 
           {

            if (sub === 'Free')
            {
                wednesdays[foundIndex] = {'timeSlot': {'timeSlotID': times._id, 'startTime': times.startTime, 'endTime': times.endTime}, 
                'subject': {'subjectID': 'Free', 'subjectName': 'Free Period'}, 
                'teacher' : {'teacherID': 'None', 'teacherName': 'None'}}; 
            }
            else if (sub === 'Recess')
            {
               wednesdays[foundIndex] = {'timeSlot': {'timeSlotID': times._id, 'startTime': times.startTime, 'endTime': times.endTime}, 
               'subject': {'subjectID': 'Recess', 'subjectName': 'Recess'}, 
               'teacher' : {'teacherID': 'None', 'teacherName': 'None'}}; 
            }
           else
            {
                let subject = subjects.find(item => item.subjectID === sub)!;
    
                wednesdays[foundIndex] = {'timeSlot': {'timeSlotID': times._id, 'startTime': times.startTime, 'endTime': times.endTime}, 
                'subject': {'subjectID': subject.subjectID, 'subjectName': subject.subjectName}, 
                'teacher' : {'teacherID': subject.teacherID, 'teacherName': subject.teacherName}}; 
           }       

        }
        
           setWed(wednesdays);
        }

        if (day === 'thu')
        {
           let thursdays: DaySchedule[] = [...thu];

           let times = location.state.selectedSlots[index];

           let foundIndex = thursdays.findIndex(x => x.timeSlot.timeSlotID === times._id);

           if (foundIndex >= 0) 
           {

            if (sub === 'Free')
            {
                thursdays[foundIndex] = {'timeSlot': {'timeSlotID': times._id, 'startTime': times.startTime, 'endTime': times.endTime}, 
                'subject': {'subjectID': 'Free', 'subjectName': 'Free Period'}, 
                'teacher' : {'teacherID': 'None', 'teacherName': 'None'}}; 
            }
            else if (sub === 'Recess')
            {
               thursdays[foundIndex] = {'timeSlot': {'timeSlotID': times._id, 'startTime': times.startTime, 'endTime': times.endTime}, 
               'subject': {'subjectID': 'Recess', 'subjectName': 'Recess'}, 
               'teacher' : {'teacherID': 'None', 'teacherName': 'None'}}; 
            }
           else
            {
                let subject = subjects.find(item => item.subjectID === sub)!;
    
                thursdays[foundIndex] = {'timeSlot': {'timeSlotID': times._id, 'startTime': times.startTime, 'endTime': times.endTime}, 
                'subject': {'subjectID': subject.subjectID, 'subjectName': subject.subjectName}, 
                'teacher' : {'teacherID': subject.teacherID, 'teacherName': subject.teacherName}}; 
           }       

        }
        
           setThu(thursdays);
        }

        if (day === 'fri')
        {
           let fridays: DaySchedule[] = [...fri];

           let times = location.state.selectedSlots[index];

           let foundIndex = fridays.findIndex(x => x.timeSlot.timeSlotID === times._id);

           if (foundIndex >= 0) 
           {

            if (sub === 'Free')
            {
                fridays[foundIndex] = {'timeSlot': {'timeSlotID': times._id, 'startTime': times.startTime, 'endTime': times.endTime}, 
                'subject': {'subjectID': 'Free', 'subjectName': 'Free Period'}, 
                'teacher' : {'teacherID': 'None', 'teacherName': 'None'}}; 
            }
            else if (sub === 'Recess')
            {
               fridays[foundIndex] = {'timeSlot': {'timeSlotID': times._id, 'startTime': times.startTime, 'endTime': times.endTime}, 
               'subject': {'subjectID': 'Recess', 'subjectName': 'Recess'}, 
               'teacher' : {'teacherID': 'None', 'teacherName': 'None'}}; 
            }
           else
            {
                let subject = subjects.find(item => item.subjectID === sub)!;
    
                fridays[foundIndex] = {'timeSlot': {'timeSlotID': times._id, 'startTime': times.startTime, 'endTime': times.endTime}, 
                'subject': {'subjectID': subject.subjectID, 'subjectName': subject.subjectName}, 
                'teacher' : {'teacherID': subject.teacherID, 'teacherName': subject.teacherName}}; 
           }       

        }
        
           setFri(fridays);
        }

        if (day === 'sat')
        {
           let saturdays: DaySchedule[] = [...sat];

           let times = location.state.selectedSlots[index];

           let foundIndex = saturdays.findIndex(x => x.timeSlot.timeSlotID === times._id);

           if (foundIndex >= 0) 
           {

            if (sub === 'Free')
            {
                saturdays[foundIndex] = {'timeSlot': {'timeSlotID': times._id, 'startTime': times.startTime, 'endTime': times.endTime}, 
                'subject': {'subjectID': 'Free', 'subjectName': 'Free Period'}, 
                'teacher' : {'teacherID': 'None', 'teacherName': 'None'}}; 
            }
            else if (sub === 'Recess')
            {
               saturdays[foundIndex] = {'timeSlot': {'timeSlotID': times._id, 'startTime': times.startTime, 'endTime': times.endTime}, 
               'subject': {'subjectID': 'Recess', 'subjectName': 'Recess'}, 
               'teacher' : {'teacherID': 'None', 'teacherName': 'None'}}; 
            }
           else
            {
                let subject = subjects.find(item => item.subjectID === sub)!;
    
                saturdays[foundIndex] = {'timeSlot': {'timeSlotID': times._id, 'startTime': times.startTime, 'endTime': times.endTime}, 
                'subject': {'subjectID': subject.subjectID, 'subjectName': subject.subjectName}, 
                'teacher' : {'teacherID': subject.teacherID, 'teacherName': subject.teacherName}}; 
           }       

        }
        
           setSat(saturdays);
        }
    }

    const handleSubmit = () => {

        for (let i = 0; i < location.state.selectedSlots.length; i++)
        {
            if ((mon[i].subject.subjectID === '') || (tue[i].subject.subjectID === '') || (wed[i].subject.subjectID === '') 
                || (thu[i].subject.subjectID === '') || (fri[i].subject.subjectID === '') || (sat[i].subject.subjectID === ''))
            {
                setMessage(t("subjects_mandatory"));
                setIserror(true);
                return;
            }
        }
  
        const addTimeTable = ( mon: DaySchedule[], tue: DaySchedule[], wed: DaySchedule[], thu: DaySchedule[], fri: DaySchedule[], 
            sat: DaySchedule[] ) => {
          return new Promise((resolve, reject) => {
            api.post('/timeTables/add', { mon, tue, wed, thu, fri, sat, 'instituteID': authInfo.user.instituteID, 'className': name, session, 'classID': location.state.classID, 'name': location.state.name }).then(res => {
      
              return resolve(res.data.timeTable);
              
            }).catch(err => reject(err));
  
          });
        }
  
        setShowLoading(true);
        addTimeTable(mon, tue, wed, thu, fri, sat)
        .then(data => {
          
          setPass(name+"'s Time Table "+t("has_been_added"));
          setIspass(true);
        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
        
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const result = await api.get('/classes/' + location.state.classID);

                setName(result.data.name);
                setSession(result.data.sessionID);

                if (result.data.subjects)
                    setSubjects(result.data.subjects);

                let mondays: DaySchedule[] = [];

                let array = location.state.selectedSlots;

                array.sort(function(a,b){
                    return parseInt(a.startTime) - parseInt(b.startTime);
                  });
                
                    for (let i = 0; i < array.length; i++)
                    {
                        mondays.push({'timeSlot': {'timeSlotID': array[i]._id, 'startTime': array[i].startTime, 'endTime': array[i].endTime}, 
                                    'subject': {'subjectID': '', 'subjectName': ''}, 
                                    'teacher' : {'teacherID': '', 'teacherName': ''}});
                                   
                    }
                    setMon(mondays);
                    setTue(mondays);
                    setWed(mondays);
                    setThu(mondays);
                    setFri(mondays);
                    setSat(mondays);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, location]);

    if (!location.state) {
        history.replace("/app/timetables/add");
        return null; // Prevents rendering anything before redirect
    }

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/timetables/add`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("assign_subjects")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("assign_subjects")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app/timetables`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className='mt-30'>
                        <IonCol offsetXl="2" offset="0" sizeXl="8" size="12">
                            <IonCard className='note-card'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="primary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            <p>
                                                {t("subjects_note")} <Link to={`/app/class/subjects/${location.state.classID}`}>{t("click_here")}</Link>
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                {(!isPlatform("mobile")) && (
                    <div className="ion-table mt-30 mb-60 min-xl">

                        <IonRow className="table-title ion-padding-vertical ion-text-center">

                            <IonCol size="1.5" className='table-heading heading-fixed'>
                            {t("period")}
                            </IonCol>
                            <IonCol size="1.75" className='table-heading'>
                            {t("monday")}
                            </IonCol>
                            <IonCol size="1.75" className='table-heading'>
                            {t("tuesday")}
                            </IonCol>
                            <IonCol size="1.75" className='table-heading'>
                            {t("wednesday")}
                            </IonCol>
                            <IonCol size="1.75" className='table-heading'>
                            {t("thursday")}
                            </IonCol>
                            <IonCol size="1.75" className='table-heading'>
                            {t("friday")}
                            </IonCol>
                            <IonCol size="1.75" className='table-heading'>
                            {t("saturday")}
                            </IonCol>
                        </IonRow>
                        {location.state && location.state.selectedSlots && location.state.selectedSlots.length > 0 && location.state.selectedSlots.map((slot, i) => (
                        <IonRow key={slot._id} className={((i%2) === 0) ? 'row-table' : 'row-table-alt'}>
                            <IonCol size="1.5" className='table-heading heading-fixed'>     
                                <div className="vertical-container">
                                    <div className="vertical-center">     
                                        {slot.name}<br/>({slot.startTime} - {slot.endTime})
                                    </div>
                                </div>
                            </IonCol>
                            <IonCol size="1.75" className='table-field'>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel position="stacked" color="secondary" className="input-label">{t("subject")}*</IonLabel>
                                                <IonSelect value={mon.find(e => e.timeSlot.timeSlotID === slot._id)?.subject.subjectID}
                                                                className="input-field"
                                                                cancelText={t("cancel")}
                                                                okText={t("ok")}
                                                                placeholder={t("select_subject")}
                                                                onIonChange={
                                                                    (e) => {
                                                                        e.detail.value && handleSubject(e.detail.value, i, 'mon');
                                                                        }

                                                            }>
                                                                
                                                            { subjects.map((subject) => {
                                                                    
                                                                return (<IonSelectOption key={subject.subjectID} value={`${subject.subjectID}`}>{subject.subjectName}</IonSelectOption>);
                                                                    
                                                                })
                                                            }
                                                            <IonSelectOption value='Recess' >{t("recess")}</IonSelectOption>
                                                            <IonSelectOption value='Free' >{t("free_period")}</IonSelectOption>
                                                </IonSelect>       
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol size="1.75" className='table-field'>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel position="stacked" color="secondary" className="input-label">{t("subject")}*</IonLabel>
                                                <IonSelect value={tue.find(e => e.timeSlot.timeSlotID === slot._id)?.subject.subjectID}
                                                                className="input-field"
                                                                cancelText={t("cancel")}
                                                                okText={t("ok")}
                                                                placeholder={t("select_subject")}
                                                                onIonChange={
                                                                    (e) => {
                                                                        e.detail.value && handleSubject(e.detail.value, i, 'tue');
                                                                        }

                                                            }>
                                                                
                                                            { subjects.map((subject) => {
                                                                    
                                                                return (<IonSelectOption key={subject.subjectID} value={`${subject.subjectID}`}>{subject.subjectName}</IonSelectOption>);
                                                                    
                                                                })
                                                            }
                                                            <IonSelectOption value='Recess' >{t("recess")}</IonSelectOption>
                                                            <IonSelectOption value='Free' >{t("free_period")}</IonSelectOption>
                                                </IonSelect>       
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol size="1.75" className='table-field'>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel position="stacked" color="secondary" className="input-label">{t("subject")}*</IonLabel>
                                                <IonSelect value={wed.find(e => e.timeSlot.timeSlotID === slot._id)?.subject.subjectID}
                                                                className="input-field"
                                                                cancelText={t("cancel")}
                                                                okText={t("ok")}
                                                                placeholder={t("select_subject")}
                                                                onIonChange={
                                                                    (e) => {
                                                                        e.detail.value && handleSubject(e.detail.value, i, 'wed');
                                                                        }

                                                            }>
                                                                
                                                            { subjects.map((subject) => {
                                                                    
                                                                return (<IonSelectOption key={subject.subjectID} value={`${subject.subjectID}`}>{subject.subjectName}</IonSelectOption>);
                                                                    
                                                                })
                                                            }
                                                            <IonSelectOption value='Recess' >{t("recess")}</IonSelectOption>
                                                            <IonSelectOption value='Free' >{t("free_period")}</IonSelectOption>
                                                </IonSelect>       
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol size="1.75" className='table-field'>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel position="stacked" color="secondary" className="input-label">{t("subject")}*</IonLabel>
                                                <IonSelect value={thu.find(e => e.timeSlot.timeSlotID === slot._id)?.subject.subjectID}
                                                                className="input-field"
                                                                cancelText={t("cancel")}
                                                                okText={t("ok")}
                                                                placeholder={t("select_subject")}
                                                                onIonChange={
                                                                    (e) => {
                                                                        e.detail.value && handleSubject(e.detail.value, i, 'thu');
                                                                        }

                                                            }>
                                                                
                                                            { subjects.map((subject) => {
                                                                    
                                                                return (<IonSelectOption key={subject.subjectID} value={`${subject.subjectID}`}>{subject.subjectName}</IonSelectOption>);
                                                                    
                                                                })
                                                            }
                                                            <IonSelectOption value='Recess' >{t("recess")}</IonSelectOption>
                                                            <IonSelectOption value='Free' >{t("free_period")}</IonSelectOption>
                                                </IonSelect>       
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol size="1.75" className='table-field'>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel position="stacked" color="secondary" className="input-label">{t("subject")}*</IonLabel>
                                                <IonSelect value={fri.find(e => e.timeSlot.timeSlotID === slot._id)?.subject.subjectID}
                                                                className="input-field"
                                                                cancelText={t("cancel")}
                                                                okText={t("ok")}
                                                                placeholder={t("select_subject")}
                                                                onIonChange={
                                                                    (e) => {
                                                                        e.detail.value && handleSubject(e.detail.value, i, 'fri');
                                                                        }

                                                            }>
                                                                
                                                            { subjects.map((subject) => {
                                                                    
                                                                return (<IonSelectOption key={subject.subjectID} value={`${subject.subjectID}`}>{subject.subjectName}</IonSelectOption>);
                                                                    
                                                                })
                                                            }
                                                            <IonSelectOption value='Recess' >{t("recess")}</IonSelectOption>
                                                            <IonSelectOption value='Free' >{t("free_period")}</IonSelectOption>
                                                </IonSelect>       
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol size="1.75" className='table-field'>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel position="stacked" color="secondary" className="input-label">{t("subject")}*</IonLabel>
                                                <IonSelect value={sat.find(e => e.timeSlot.timeSlotID === slot._id)?.subject.subjectID}
                                                                className="input-field"
                                                                cancelText={t("cancel")}
                                                                okText={t("ok")}
                                                                placeholder={t("select_subject")}
                                                                onIonChange={
                                                                    (e) => {
                                                                        e.detail.value && handleSubject(e.detail.value, i, 'sat');
                                                                        }

                                                            }>
                                                                
                                                            { subjects.map((subject) => {
                                                                    
                                                                return (<IonSelectOption key={subject.subjectID} value={`${subject.subjectID}`}>{subject.subjectName}</IonSelectOption>);
                                                                    
                                                                })
                                                            }
                                                            <IonSelectOption value='Recess' >{t("recess")}</IonSelectOption>
                                                            <IonSelectOption value='Free' >{t("free_period")}</IonSelectOption>
                                                </IonSelect>       
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        ))}
                    </div>
                )}

                {(isPlatform("mobile")) && (
                <>
                    <IonRow className="ion-padding-top">
                        <IonCol>
                            <IonSegment scrollable={true} value={day} onIonChange={e => setDay( e.detail.value!.toString() )}  >
                                <IonSegmentButton value="mon">
                                    <IonLabel>{t("mon")}</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="tue">
                                    <IonLabel>{t("tue")}</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="wed">
                                    <IonLabel>{t("wed")}</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="thu">
                                    <IonLabel>{t("thu")}</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="fri">
                                    <IonLabel>{t("fri")}</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="sat">
                                    <IonLabel>{t("sat")}</IonLabel>
                                </IonSegmentButton>
                               
                            </IonSegment>
                        </IonCol>
                    </IonRow>
                    {(day === 'mon') && (mon.length > 0) && (
                        <IonRow className="ion-margin-top">
                            <IonCol>
                                {location.state && location.state.selectedSlots && location.state.selectedSlots.length > 0 && location.state.selectedSlots.map((slot, i) => (
                                <IonCard className='border-radius-10' key={slot._id}>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonGrid className="ion-no-padding ion-no-margin">
                                            <IonRow className="tran-pad title-row">
                                                <IonCol>
                                                   <IonText>{slot.name} ({slot.startTime} - {slot.endTime})</IonText>
                                                </IonCol>
                                              
                                            </IonRow>
                                            <IonRow className='border-top'>
                                                <IonCol>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        
                                                        <IonLabel className="input-label" position="stacked" color="secondary">{t("subject")}*</IonLabel>
                                                            <IonSelect value={mon.find(e => e.timeSlot.timeSlotID === slot._id)?.subject.subjectID}
                                                                            className="input-field"
                                                                            cancelText={t("cancel")}
                                                                            okText={t("ok")}
                                                                            placeholder={t("select_subject")}
                                                                            onIonChange={
                                                                                (e) => {
                                                                                    e.detail.value && handleSubject(e.detail.value, i, 'mon');
                                                                                    }

                                                                        }>
                                                                            
                                                                        { subjects.map((subject) => {
                                                                                
                                                                            return (<IonSelectOption key={subject.subjectID} value={`${subject.subjectID}`}>{subject.subjectName}</IonSelectOption>);
                                                                                
                                                                            })
                                                                        }
                                                                <IonSelectOption value='Recess' >{t("recess")}</IonSelectOption>
                                                                <IonSelectOption value='Free' >{t("free_period")}</IonSelectOption>
                                                            </IonSelect>
                                                                    
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                           
                                        
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}

                    {(day === 'tue') && (tue.length > 0) && (
                        <IonRow className="ion-margin-top">
                            <IonCol>
                                {location.state && location.state.selectedSlots && location.state.selectedSlots.length > 0 && location.state.selectedSlots.map((slot, i) => (
                                <IonCard className='border-radius-10' key={slot._id}>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonGrid className="ion-no-padding ion-no-margin">
                                            <IonRow className="tran-pad title-row">
                                                <IonCol>
                                                   <IonText>{slot.name} ({slot.startTime} - {slot.endTime})</IonText>
                                                </IonCol>
                                              
                                            </IonRow>
                                            <IonRow className='border-top'>
                                                <IonCol>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        
                                                        <IonLabel className="input-label" position="stacked" color="secondary">{t("subject")}*</IonLabel>
                                                            <IonSelect value={tue.find(e => e.timeSlot.timeSlotID === slot._id)?.subject.subjectID}
                                                                            className="input-field"
                                                                            cancelText={t("cancel")}
                                                                            okText={t("ok")}
                                                                            placeholder={t("select_subject")}
                                                                            onIonChange={
                                                                                (e) => {
                                                                                    e.detail.value && handleSubject(e.detail.value, i, 'tue');
                                                                                    }

                                                                        }>
                                                                            
                                                                        { subjects.map((subject) => {
                                                                                
                                                                            return (<IonSelectOption key={subject.subjectID} value={`${subject.subjectID}`}>{subject.subjectName}</IonSelectOption>);
                                                                                
                                                                            })
                                                                        }
                                                                          <IonSelectOption value='Recess' >{t("recess")}</IonSelectOption>
                                                                        <IonSelectOption value='Free' >{t("free_period")}</IonSelectOption>
                                                            </IonSelect>
                                                                    
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                           
                                        
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}

                    {(day === 'wed') && (wed.length > 0) && (
                        <IonRow className="ion-margin-top">
                            <IonCol>
                                {location.state && location.state.selectedSlots && location.state.selectedSlots.length > 0 && location.state.selectedSlots.map((slot, i) => (
                               <IonCard className='border-radius-10' key={slot._id}>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonGrid className="ion-no-padding ion-no-margin">
                                            <IonRow className="tran-pad title-row">
                                                <IonCol>
                                                   <IonText>{slot.name} ({slot.startTime} - {slot.endTime})</IonText>
                                                </IonCol>
                                              
                                            </IonRow>
                                            <IonRow className='border-top'>
                                                <IonCol>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        
                                                        <IonLabel className="input-label" position="stacked" color="secondary">{t("subject")}*</IonLabel>
                                                            <IonSelect value={wed.find(e => e.timeSlot.timeSlotID === slot._id)?.subject.subjectID}
                                                                            className="input-field"
                                                                            cancelText={t("cancel")}
                                                                            okText={t("ok")}
                                                                            placeholder={t("select_subject")}
                                                                            onIonChange={
                                                                                (e) => {
                                                                                    e.detail.value && handleSubject(e.detail.value, i, 'wed');
                                                                                    }

                                                                        }>
                                                                            
                                                                        { subjects.map((subject) => {
                                                                                
                                                                            return (<IonSelectOption key={subject.subjectID} value={`${subject.subjectID}`}>{subject.subjectName}</IonSelectOption>);
                                                                                
                                                                            })
                                                                        }
                                                                          <IonSelectOption value='Recess' >{t("recess")}</IonSelectOption>
                                                                        <IonSelectOption value='Free' >{t("free_period")}</IonSelectOption>
                                                            </IonSelect>
                                                                    
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                           
                                        
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}

                    {(day === 'thu') && (thu.length > 0) && (
                        <IonRow className="ion-margin-top">
                            <IonCol>
                                {location.state && location.state.selectedSlots && location.state.selectedSlots.length > 0 && location.state.selectedSlots.map((slot, i) => (
                                    <IonCard className='border-radius-10' key={slot._id}>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonGrid className="ion-no-padding ion-no-margin">
                                            <IonRow className="tran-pad title-row">
                                                <IonCol>
                                                   <IonText>{slot.name} ({slot.startTime} - {slot.endTime})</IonText>
                                                </IonCol>
                                              
                                            </IonRow>
                                            <IonRow className='border-top'>
                                                <IonCol>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        
                                                        <IonLabel className="input-label" position="stacked" color="secondary">{t("subject")}*</IonLabel>
                                                            <IonSelect value={thu.find(e => e.timeSlot.timeSlotID === slot._id)?.subject.subjectID}
                                                                            className="input-field"
                                                                            cancelText={t("cancel")}
                                                                            okText={t("ok")}
                                                                            placeholder={t("select_subject")}
                                                                            onIonChange={
                                                                                (e) => {
                                                                                    e.detail.value && handleSubject(e.detail.value, i, 'thu');
                                                                                    }

                                                                        }>
                                                                            
                                                                        { subjects.map((subject) => {
                                                                                
                                                                            return (<IonSelectOption key={subject.subjectID} value={`${subject.subjectID}`}>{subject.subjectName}</IonSelectOption>);
                                                                                
                                                                            })
                                                                        }
                                                                         <IonSelectOption value='Recess' >{t("recess")}</IonSelectOption>
                                                                        <IonSelectOption value='Free' >{t("free_period")}</IonSelectOption>
                                                            </IonSelect>
                                                                    
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                           
                                        
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}

                    {(day === 'fri') && (fri.length > 0) && (
                        <IonRow className="ion-margin-top">
                            <IonCol>
                                {location.state && location.state.selectedSlots && location.state.selectedSlots.length > 0 && location.state.selectedSlots.map((slot, i) => (
                                <IonCard className='border-radius-10' key={slot._id}>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonGrid className="ion-no-padding ion-no-margin">
                                            <IonRow className="tran-pad title-row">
                                                <IonCol>
                                                   <IonText>{slot.name} ({slot.startTime} - {slot.endTime})</IonText>
                                                </IonCol>
                                              
                                            </IonRow>
                                            <IonRow className='border-top'>
                                                <IonCol>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        
                                                        <IonLabel className="input-label" position="stacked" color="secondary">{t("subject")}*</IonLabel>
                                                            <IonSelect value={fri.find(e => e.timeSlot.timeSlotID === slot._id)?.subject.subjectID}
                                                                            className="input-field"
                                                                            cancelText={t("cancel")}
                                                                            okText={t("ok")}
                                                                            placeholder={t("select_subject")}
                                                                            onIonChange={
                                                                                (e) => {
                                                                                    e.detail.value && handleSubject(e.detail.value, i, 'fri');
                                                                                    }

                                                                        }>
                                                                            
                                                                        { subjects.map((subject) => {
                                                                                
                                                                            return (<IonSelectOption key={subject.subjectID} value={`${subject.subjectID}`}>{subject.subjectName}</IonSelectOption>);
                                                                                
                                                                            })
                                                                        }
                                                                         <IonSelectOption value='Recess' >{t("recess")}</IonSelectOption>
                                                                        <IonSelectOption value='Free' >{t("free_period")}</IonSelectOption>
                                                            </IonSelect>
                                                                    
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                           
                                        
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}

                    {(day === 'sat') && (sat.length > 0) && (
                        <IonRow className="ion-margin-top">
                            <IonCol>
                                {location.state && location.state.selectedSlots && location.state.selectedSlots.length > 0 && location.state.selectedSlots.map((slot, i) => (
                                <IonCard className='border-radius-10' key={slot._id}>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonGrid className="ion-no-padding ion-no-margin">
                                            <IonRow className="tran-pad title-row">
                                                <IonCol>
                                                   <IonText>{slot.name} ({slot.startTime} - {slot.endTime})</IonText>
                                                </IonCol>
                                              
                                            </IonRow>
                                            <IonRow className='border-top'>
                                                <IonCol>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        
                                                        <IonLabel className="input-label" position="stacked" color="secondary">{t("subject")}*</IonLabel>
                                                            <IonSelect value={sat.find(e => e.timeSlot.timeSlotID === slot._id)?.subject.subjectID}
                                                                            className="input-field"
                                                                            cancelText={t("cancel")}
                                                                            okText={t("ok")}
                                                                            placeholder={t("select_subject")}
                                                                            onIonChange={
                                                                                (e) => {
                                                                                    e.detail.value && handleSubject(e.detail.value, i, 'sat');
                                                                                    }

                                                                        }>
                                                                            
                                                                        { subjects.map((subject) => {
                                                                                
                                                                            return (<IonSelectOption key={subject.subjectID} value={`${subject.subjectID}`}>{subject.subjectName}</IonSelectOption>);
                                                                                
                                                                            })
                                                                        }
                                                                        <IonSelectOption value='Recess' >{t("recess")}</IonSelectOption>
                                                                        <IonSelectOption value='Free' >{t("free_period")}</IonSelectOption>
                                                            </IonSelect>
                                                                    
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                           
                                        
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}

                </>)}
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("add_time_table")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddTimeTableTwo;
