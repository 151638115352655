import {
    ActionSheetButton, IonActionSheet, IonAlert, IonButton, IonButtons, IonCol, IonGrid, IonIcon, IonRow, IonSkeletonText, IonTitle, IonToolbar, isPlatform,
} from '@ionic/react';
import { businessOutline, businessSharp, chevronDownOutline, closeOutline } from 'ionicons/icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from './AuthContext';

interface Institute {
    _id: string,
    name: string,
    isActive: boolean
}

const SwitchBranch: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const {authInfo, switchInstitute} = useAuth()!;
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [showActionSheet, setShowActionSheet] = useState < boolean > (false);
    const [institutesList, setInstitutesList] = useState < ActionSheetButton[] > ([]);
    const [insti, setInsti] = useState < Institute > ();

    const swapInstitute = (selected: string) => {

        switchInstitute(authInfo.user._id, selected)
        .then((data) => {
            sessionStorage.removeItem(history.location.pathname);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        });
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            try {
                const institutes = await api.get(`/institutes/getBySuper`);
                setInsti(institutes.data.find((val: any) => val._id === authInfo.user.instituteID));
                if(institutes.data.length > 1)
                {
                    let cObjects: ActionSheetButton[] = [];

                    for (let i=0; i < institutes.data.length; i++)
                    {
                        let cObject: ActionSheetButton;
                        if ((institutes.data[i].isActive === true) && (institutes.data[i]._id !== authInfo.user.instituteID))
                        {
                            cObject = {
                                text: institutes.data[i].name,
                                icon: !isPlatform('ios') ? businessOutline : undefined,
                                handler: () => swapInstitute(institutes.data[i]._id)
                            }
                            cObjects.push(cObject);
                        }
                    }

                    cObjects.push({
                        text: t('cancel'),
                        icon: !isPlatform('ios') ? closeOutline : undefined,
                        role: 'cancel'
                    });

                    setInstitutesList(cObjects);
                }

            } catch (error : any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            }
        };

        fetchUp();
        
    }, [authInfo]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <IonAlert isOpen={iserror}
                cssClass="first-alert"
                onDidDismiss={
                    () => setIserror(false)
                }
                header={t("error")}
                message={message}
                buttons={
                    [`${t("close")}`]
                }
            />
       
            <IonActionSheet isOpen={showActionSheet}
                onDidDismiss={
                    () => setShowActionSheet(false)
                }
                cssClass='swap-sheet'
                subHeader={`${t("currently_selected")} - ${insti?.name}`}
                header={t("switch_branch")}
                buttons={institutesList} 
            />

            <IonGrid className="ion-no-padding">
                <IonRow className="ion-no-padding">
                    <IonCol>
                        <IonToolbar className="pl-16" color="secondary" mode="ios" onClick={() => setShowActionSheet(true)}>
                            {(institutesList.length > 0) && (
                            <IonIcon slot='start' icon={businessSharp} color="warning" />
                            )}
                            <IonTitle color="secondary" className='parent-home-title'>
                            {
                                insti ? (insti.name) : 
                                ((<IonSkeletonText animated={true} style={{ 'width': '140px', 'marginLeft': '20px' }}></IonSkeletonText>))
                            }
                            </IonTitle>
                            {(institutesList.length > 0) && (
                            <IonButtons slot="end">
                                <IonButton><IonIcon icon={chevronDownOutline} color="warning" /></IonButton>
                            </IonButtons>
                            )}
                        </IonToolbar> 
                    </IonCol>
                </IonRow>
            </IonGrid>
        </>         
    );
}

export default SwitchBranch;
