import {
    IonAlert,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonProgressBar,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import { helpCircleOutline } from 'ionicons/icons';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { SendIntent } from "send-intent";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Scrollbar } from 'swiper/modules';
import { useAuth } from "../../components/AuthContext";
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';
import useApiService from '../../components/ApiService';

import 'swiper/css';
import 'swiper/css/scrollbar';
import "swiper/css/autoplay";
import '@ionic/react/css/ionic-swiper.css';

const SuperAdminHome: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [showDownloading, setShowDownloading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [isold, setIsold] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [iserror1, setIserror1] = useState < boolean > (false);
    const [message1, setMessage1] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);

    const [total, setTotal] = useState < number > (0);
    const [classes, setClasses] = useState < number > (0);
    const [subjects, setSubjects] = useState < number > (0);
    const [staff, setStaff] = useState < number > (0);
    const [male, setMale] = useState < number > (0);
    const [female, setFemale] = useState < number > (0);
    const [circulars, setCirculars] = useState < number > (0);
    const [opened, setOpened] = useState < number > (0);
    const [processed, setProcessed] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };
   
    useEffect(() => {
        const upUser = async (push: Token) => {
            try {
                const response = await api.put(`/users/${authInfo.user._id}`, { push: push.value });
                return response.data;
            } catch (error) {
                throw error;
            }
        };
    
        const register = async () => {
            try {
                // Register with Apple / Google to receive push notifications
                await PushNotifications.register();
    
                // On success, we should be able to receive notifications
                await PushNotifications.addListener('registration', async (token: Token) => {
                    if (!authInfo.user.push || authInfo.user.push !== token.value) {
                        await upUser(token);
                    }
                });
    
                // Handle registration errors
                await PushNotifications.addListener('registrationError', () => {
                    setMessage(t("push_error"));
                    setIserror(true);
                });
            } catch (err) {
                console.error('Error in register function:', err);

            }
        };
    
        const fetchData = async () => {

            try {
                const stats = await api.get(`/institutes/superStats`);
                setTotal(stats.data.users);
                setClasses(stats.data.downloads);
                setSubjects(stats.data.notifs);
                setStaff(stats.data.branches);
                setMale(stats.data.ratio);
                setFemale(stats.data.average);
                setCirculars(stats.data.upcoming);
                setOpened(stats.data.events);
                setProcessed(stats.data.attendees);

            } catch (error: any) {
                //
                
            }
        };
    
        const checkIntent = async () => {
            try {
                const result = await SendIntent.checkSendIntentReceived();
                if (result) {
                    setMessage1(t("intent_error"));
                    setIserror1(true);
                }
            } catch (err) {
                console.error('Error checking intent:', err);
            }
        };
    
        const fetchVersion = async () => {
            setShowDownloading(true);
            try {
                const versionRes = await api.get(`/version-check`);
                const currentVersion = parseInt(process.env.REACT_APP_VERSION!);
    
                if (parseInt(versionRes.data) > currentVersion) {
                    setIsold(true);
                } else {

                    if (isPlatform('android') && isPlatform('capacitor')) {
                        await checkIntent();  // Check intent if android
                    }

                    await fetchData();  // Fetch data to display
                    
    
                    if (isPlatform('capacitor')) {
                        const permissionRes = await PushNotifications.checkPermissions();
                        if (permissionRes.receive !== 'granted') {
                            const requestRes = await PushNotifications.requestPermissions();
                            if (requestRes.receive === 'granted') {
                                await register();  // Register for push notifications
                            }
                        } else {
                            await register();  // Already has permission
                        }
                    }
                }
            } catch (error: any) {
                //
            } finally {
                setShowDownloading(false);
            }
        
        };
    
        fetchVersion();
    
    }, [authInfo, refreshed]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary" className="text-capitalize">{t("hello")}, {authInfo.user.name}!</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                     {showDownloading && (
                        <IonProgressBar type="indeterminate"></IonProgressBar>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary" className="text-capitalize">{t("hello")}, {authInfo.user.name}!</IonTitle>
                    </IonToolbar>
                </IonHeader>

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={iserror1}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => SendIntent.finish()
                    }
                    header={t("error")}
                    message={message1}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isold}
                    backdropDismiss={false}
                    onDidDismiss={
                        () => document.location.href = isPlatform('android') ? process.env.REACT_APP_PLAY_STORE! : process.env.REACT_APP_APP_STORE!
                    }
                    header={t("outdated_version")}
                    message={t("outdated_msg")}
                    buttons={
                        [`${t("update_app")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-margin-top">
                    <IonRow>
                        <IonCol className='ion-no-padding'>
                            <Swiper 
                                 modules={[Autoplay, Scrollbar]}
                                 spaceBetween={10}
                                 initialSlide={0}
                                 slidesPerView={1}
                                 scrollbar={{el: '.swiper-scrollbar', draggable: true}}
                                 loop={false}         
                                 autoplay={{
                                     delay: 3000
                                 }}
                                 breakpoints={{
                                     768: {
                                      slidesPerView: 2,
                                      spaceBetween: 20
                                     },
                                     1200: {
                                      slidesPerView: 3,
                                      spaceBetween: 30
                                     }
                                 }}
                            >
                                <SwiperSlide>
                                    <IonCard className="dash-card dash-bg-6">
                                        <IonCardContent className="ion-no-padding">
                                            <IonRow className='ion-padding-horizontal'>
                                                <IonCol size="9" className="dash-title text-dark">         
                                                    {t("master_overview")}
                                                </IonCol>
                                                <IonCol size="3" className='dash-icon'>
                                                    <div className="vertical-container">
                                                        <div className="vertical-center"> 
                                                            <img alt={t("master_overview")} src="assets/images/admin-stats.png" />
                                                        </div>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-padding-horizontal">
                                                <IonCol className="dash-box-info">
                                                    {t("master_overview_info")}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-padding">
                                                <IonCol size="3.5" className='dash-box'>
                                                    <IonRow className="dash-box-header">
                                                        <IonCol className="dash-box-title">
                                                        {t("registered_users")}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="dash-box-footer">
                                                        <IonCol className="dash-box-stat">
                                                            {total}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol offset="0.5" size="3.5" className='dash-box'>
                                                    <IonRow className="dash-box-header">
                                                        <IonCol className="dash-box-title">
                                                        {t("app_downloads")}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="dash-box-footer">
                                                        <IonCol className="dash-box-stat">
                                                        {classes}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol offset="0.5" size="3.5" className='dash-box'>
                                                    <IonRow className="dash-box-header">
                                                        <IonCol className="dash-box-title">
                                                        {t("notifications_sent")}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="dash-box-footer">
                                                        <IonCol className="dash-box-stat">
                                                        {subjects}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                            
                                        </IonCardContent>
                                    </IonCard>
                                </SwiperSlide>  
                                <SwiperSlide>
                                    <IonCard className="dash-card dash-bg-1">
                                        <IonCardContent className="ion-no-padding">
                                            <IonRow className='ion-padding-horizontal'>
                                                <IonCol size="9" className="dash-title text-dark">         
                                                    {t("branches_overview")}
                                                </IonCol>
                                                <IonCol size="3" className='dash-icon'>
                                                    <div className="vertical-container">
                                                        <div className="vertical-center"> 
                                                            <img alt={t("branches_overview")} src="assets/images/institute-stats.png" />
                                                        </div>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-padding-horizontal">
                                                <IonCol className="dash-box-info">
                                                    {t("branches_overview_info")}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-padding">
                                                <IonCol size="3.5" className='dash-box'>
                                                    <IonRow className="dash-box-header">
                                                        <IonCol className="dash-box-title">
                                                        {t("total_branches")}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="dash-box-footer">
                                                        <IonCol className="dash-box-stat">
                                                        {staff}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol offset="0.5" size="3.5" className='dash-box'>
                                                    <IonRow className="dash-box-header">
                                                        <IonCol className="dash-box-title">
                                                        {t("student_employee_ratio")}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="dash-box-footer">
                                                        <IonCol className="dash-box-stat">
                                                        {male} : 1
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol offset="0.5" size="3.5" className='dash-box'>
                                                    <IonRow className="dash-box-header">
                                                        <IonCol className="dash-box-title">
                                                        {t("avg_income")}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="dash-box-footer">
                                                        <IonCol className="dash-box-stat">
                                                        {female}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                            
                                        
                                        </IonCardContent>
                                    </IonCard>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <IonCard className="dash-card dash-bg-5">
                                        <IonCardContent className="ion-no-padding">
                                            <IonRow className="ion-padding-horizontal">
                                                <IonCol size="9" className="dash-title text-dark">         
                                                    {t("workshops_overview")}
                                                </IonCol>
                                                <IonCol size="3" className='dash-icon'>
                                                    <div className="vertical-container">
                                                        <div className="vertical-center"> 
                                                            <img alt={t("workshops_overview")} src="assets/images/class-stats.png" />
                                                        </div>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-padding-horizontal">
                                                <IonCol className="dash-box-info">
                                                    {t("workshops_overview_info")}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-padding">
                                                <IonCol size="3.5" className='dash-box'>
                                                    <IonRow className="dash-box-header">
                                                        <IonCol className="dash-box-title">
                                                        {t("upcoming_events")}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="dash-box-footer">
                                                        <IonCol className="dash-box-stat">
                                                        {circulars}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol offset="0.5" size="3.5" className='dash-box'>
                                                    <IonRow className="dash-box-header">
                                                        <IonCol className="dash-box-title">
                                                        {t("total_events")}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="dash-box-footer">
                                                        <IonCol className="dash-box-stat">
                                                        {opened}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol offset="0.5" size="3.5" className='dash-box'>
                                                    <IonRow className="dash-box-header">
                                                        <IonCol className="dash-box-title">
                                                        {t("total_attendees")}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="dash-box-footer">
                                                        <IonCol className="dash-box-stat">
                                                        {processed}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                        
                                        </IonCardContent>
                                    </IonCard>
                                </SwiperSlide>
                            </Swiper>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <div className="swiper-scrollbar" />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonGrid className='mb-60'>
                    <IonRow className="ion-padding-vertical max-xl">
                        <IonCol>

                            <IonRow className='ion-margin-top'>
                                <IonCol>
                                    <IonTitle id="open-configuration-info" className='border-bottom-sec'>
                                        {t("configuration")}
                                    <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                    </IonTitle>
                                    <IonPopover trigger="open-configuration-info" className='info-popover'>
                                        <IonCard className='note-card-3 ion-no-margin'>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonRow>
                                                    <IonCol className='note-text'>
                                                        <p>
                                                            {t("configuration_help")}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonPopover>
                                </IonCol>
                            </IonRow>
                            <IonRow className='ion-margin-top'>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/notification`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className='ion-no-padding'>
                                                    <img alt={t("send_notification")} src="assets/images/notif.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear" >{t("send_notification")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/banners`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("home_stories")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                    <IonCard className="action-card" routerLink={`/app/config/contact`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("home_contact")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                               
                            </IonRow>
                            <IonRow className='ion-margin-top'>
                                <IonCol>
                                    <IonTitle id="open-branches-info" className='border-bottom-sec'>
                                    {t("manage_branches")}
                                    <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                    </IonTitle>
                                    <IonPopover trigger="open-branches-info" className='info-popover'>
                                        <IonCard className='note-card-3 ion-no-margin'>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonRow>
                                                    <IonCol className='note-text'>
                                                        <p>
                                                        {t("branches_help")}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonPopover>
                                </IonCol>
                            </IonRow>
                            <IonRow className='ion-margin-top'>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/institutes`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className='ion-no-padding'>
                                                        <img alt={t("view_branches")} src="assets/images/institute-1.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("view_branches")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/admins`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className='ion-no-padding'>
                                                        <img alt={t("view_admins")} src="assets/images/admin-1.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("view_admins")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                               
                            </IonRow>
                            <IonRow className='ion-margin-top'>
                                <IonCol>
                                    <IonTitle id="open-workshops-info" className='border-bottom-sec'>
                                    {t("manage_workshops")}
                                    <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                    </IonTitle>
                                    <IonPopover trigger="open-workshops-info" className='info-popover'>
                                        <IonCard className='note-card-3 ion-no-margin'>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonRow>
                                                    <IonCol className='note-text'>
                                                        <p>
                                                        {t("workshops_help")}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonPopover>
                                </IonCol>
                            </IonRow>
                            <IonRow className='ion-margin-top'>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/workshops`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="ion-no-padding">
                                                    <img alt={t("view_workshops")} src="assets/images/class.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("view_workshops")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/workshop/add`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("add_workshop")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                    <IonCard className="action-card" routerLink={`/app/attendees`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("all_attendees")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                               
                            </IonRow>
                        </IonCol>
                    </IonRow>
                  
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default SuperAdminHome;