import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonProgressBar,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { helpCircleOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { useAuth } from '../../../components/AuthContext';
import useApiService from '../../../components/ApiService';

const Academics: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {t} = useTranslation();
    const {authInfo} = useAuth()!;

    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [showDownloading, setShowDownloading] = useState < boolean > (false);
    const [subjects, setSubjects] = useState < number > (0);
    const [male, setMale] = useState < number > (0);
    const [female, setFemale] = useState < number > (0);
            
    useEffect(() => {

        const fetchData = async () => {
            setShowDownloading(true);
            try {
                const stats = await api.get(`/institutes/academics/${authInfo.user.instituteID}`);
                const statsData = stats.data;

                setSubjects(statsData.subjects);
                setMale(statsData.male);
                setFemale(statsData.female);
            }
            catch (error: any) {
                 // Api Error handling is already done in the interceptor
            } finally {
                setShowDownloading(false);
            }
        };

        fetchData();

    }, [authInfo]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("academics")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                    {showDownloading && (
                        <IonProgressBar type="indeterminate"></IonProgressBar>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("academics")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className='mb-60'>

                    <IonRow className="ion-padding-vertical max-lg">
                        <IonCol>
                            <IonRow className='dash-border-bottom'>
                                <IonCol sizeMd="6" size="12">
                                    <IonCard className="dashboard-card dash-bg-2">
                                        <IonCardContent className="ion-no-padding">
                                            <IonRow className='ion-padding-horizontal'>
                                                <IonCol size="9" className="dash-title text-dark">         
                                                    {t("acad_overview")}
                                                </IonCol>
                                                <IonCol size="3" className='dash-icon'>
                                                    <div className="vertical-container">
                                                        <div className="vertical-center"> 
                                                            <img alt={t("acad_overview")} src="assets/images/attendance.png" />
                                                        </div>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-padding-horizontal">
                                                <IonCol className="dash-box-info">
                                                    {t("acad_overview_info")}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-padding">
                                                <IonCol size="3.5" className='dash-box'>
                                                    <IonRow className="dash-box-header">
                                                        <IonCol className="dash-box-title">
                                                        {authInfo.institute.timetable ? t("total_subjects") : t("total_courses")}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="dash-box-footer">
                                                        <IonCol className="dash-box-stat">
                                                        {subjects}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol offset="0.5" size="3.5" className='dash-box'>
                                                    <IonRow className="dash-box-header">
                                                        <IonCol className="dash-box-title">
                                                        {authInfo.institute.reportcard ? t("report_issued") : t("certi_issued")}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="dash-box-footer">
                                                        <IonCol className="dash-box-stat">
                                                        {male}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol offset="0.5" size="3.5" className='dash-box'>
                                                    <IonRow className="dash-box-header">
                                                        <IonCol className="dash-box-title">
                                                        {t("total_submissions")}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="dash-box-footer">
                                                        <IonCol className="dash-box-stat">
                                                        {female}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                           
                                        
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol sizeMd="6" size="12">
                                {authInfo.institute.timetable && (
                                    <>
                                    
                                    <IonRow className="ion-padding-top">
                                        <IonCol>
                                            <IonTitle id="open-timetable-info" className='border-bottom-sec'>
                                                {t("manage_time_tables")}
                                                <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                            </IonTitle>
                                            
                                            <IonPopover trigger="open-timetable-info" className='info-popover'>
                                                <IonCard className='note-card-3 ion-no-margin'>
                                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                                        <IonRow>
                                                            <IonCol className='note-text'>
                                                                <p>
                                                                {t("timetable_help")}
                                                                </p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonPopover>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-top">
                                        <IonCol size="6">
                                            <IonCard className="action-card action-card-2" routerLink={`/app/timetables`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={t("manage_timetables")} src="assets/images/class-1.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("manage_timetables")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                            </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard className="action-card action-card-2" routerLink={`/app/timetables/add`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("new_timetable")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card action-card-2" routerLink={`/app/timeslots`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear" >{t("manage_timeslots")}</IonButton>
                                                            </IonCol>
                                                        </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="6">
                                            <IonCard className="action-card action-card-2" routerLink={`/app/timetables/day`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={t("consolidated_timetable")} src="assets/images/institute-1.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("consolidated_timetable")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard className="action-card action-card-2" routerLink={`/app/timetables/teacher`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("teacher_timetable")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card action-card-2" routerLink={`/app/substitutes`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("substitutes")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        
                                    </IonRow>
                                    <IonRow className='ion-padding-top'>
                                        <IonCol>
                                            <IonTitle id="open-subjects-info" className='border-bottom-sec'>
                                                {t("manage_subjects")}
                                                <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                            </IonTitle>
                                            <IonPopover trigger="open-subjects-info" className='info-popover'>
                                                <IonCard className='note-card-3 ion-no-margin'>
                                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                                        <IonRow>
                                                            <IonCol className='note-text'>
                                                                <p>
                                                                {t("subjects_help")}
                                                                </p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonPopover>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-top">
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/subjects`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={t("manage_subjects")} src="assets/images/subject.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("manage_subjects")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/subjects/add`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("add_subject")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    
                                    
                                    </IonRow>
                                
                                    </>)}

                                    {!authInfo.institute.timetable && (
                                    <>
                                    
                                    <IonRow className='ion-padding-top'>
                                        <IonCol>
                                            <IonTitle id="open-schedules-info"  className='border-bottom-sec'>
                                                {t("course_schedule")}
                                                <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                            </IonTitle>
                                            <IonPopover trigger="open-schedules-info" className='info-popover'>
                                                <IonCard className='note-card-3 ion-no-margin'>
                                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                                        <IonRow>
                                                            <IonCol className='note-text'>
                                                                <p>
                                                                {t("schedules_help")}
                                                                </p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonPopover>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-top">
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/courses/view`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={t("view_schedules")} src="assets/images/planner.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("view_schedules")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                        
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/courses/add`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("add_new_schedule")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card" routerLink={`/app/batch/schedule`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("assign_batch_schedule")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/courses/topics`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("view_topics")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    
                                    </>
                                    )}
                           
                                </IonCol>
                                <IonCol sizeMd="6" size="12">

                                {authInfo.institute.reportcard && (
                                    <>
                                    <IonRow className="ion-padding-top">
                                        <IonCol>
                                            <IonTitle id="open-reportcards-info"  className='border-bottom-sec'>
                                                {t("manage_report_cards")}
                                                <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                            </IonTitle>
                                            <IonPopover trigger="open-reportcards-info" className='info-popover'>
                                                <IonCard className='note-card-3 ion-no-margin'>
                                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                                        <IonRow>
                                                            <IonCol className='note-text'>
                                                                <p>
                                                                {t("reportcards_help")}
                                                                </p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonPopover>
                                            
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-top">
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/hide/app/reportcard/view`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={t("view_report_card")} src="assets/images/admission.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("view_report_card")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/hide/app/reportcard/generate`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("generate_report_card")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonCard className="action-card" routerLink={`/hide/app/reportcard/formats`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("manage_formats")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    
                                    </IonRow>
                                </>
                                )}
                                {!authInfo.institute.reportcard && (
                                <>
                                    <IonRow className="ion-padding-top">
                                        <IonCol>
                                            <IonTitle id="open-certificates-info" className='border-bottom-sec'>
                                                {t("manage_certificates")}
                                                <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                            </IonTitle>
                                            <IonPopover trigger="open-certificates-info" className='info-popover'>
                                                <IonCard className='note-card-3 ion-no-margin'>
                                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                                        <IonRow>
                                                            <IonCol className='note-text'>
                                                                <p>
                                                                {t("certificates_help")}
                                                                </p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonPopover>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-top">
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/certificates`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={t("view_certificates")} src="assets/images/admission.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("view_certificates")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            {/* 
                                            <IonCard className="action-card" routerLink={`/hide/app/certificate/generate`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("generate_certificate")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            */}
                                            <IonCard className="action-card" routerLink={`/app/certificate/upload`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("upload_certificate")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        
                                    </IonRow>
                                </>
                                )}
                               
                                    <IonRow className="ion-padding-top">
                                        <IonCol>
                                            <IonTitle id="open-submissions-info"  className='border-bottom-sec'>
                                                {t("submissions")}
                                                <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                            </IonTitle>
                                            <IonPopover trigger="open-submissions-info" className='info-popover'>
                                                <IonCard className='note-card-3 ion-no-margin'>
                                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                                        <IonRow>
                                                            <IonCol className='note-text'>
                                                                <p>
                                                                {t("submissions_help")}
                                                                </p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonPopover>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-top">
                                        <IonCol size="6">
                                            <IonCard className="action-card action-card-2" routerLink={`/app/leaderboards`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="ion-no-padding">
                                                            <img alt={t("view_leaderboards")} src="assets/images/board-1.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("view_leaderboards")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard className="action-card action-card-2" routerLink={`/app/submissions/student`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("view_student_wise")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            {authInfo.institute.timetable ? (
                                            <IonCard className="action-card action-card-2" routerLink={`/app/submissions/subject`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("view_subject_wise")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            ) : (
                                            <IonCard className="action-card action-card-2" routerLink={`/app/submissions/topic`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("view_course_wise")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            )}
                                        </IonCol>
                                    
                                    </IonRow>
                           
                                    {authInfo.institute.diary && (<>
                                    <IonRow className='ion-padding-top'>
                                        <IonCol>
                                            <IonTitle id="open-report-info" className='border-bottom-sec'>
                                                {t("daily_diary")}
                                                <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                            </IonTitle>
                                            <IonPopover trigger="open-report-info" className='info-popover'>
                                                <IonCard className='note-card-3 ion-no-margin'>
                                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                                        <IonRow>
                                                            <IonCol className='note-text'>
                                                                <p>
                                                                    {t("diary_help")}
                                                                </p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonPopover>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-top">
                                        <IonCol size="6">
                                            <IonCard className="action-card" routerLink={`/app/diary`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className='ion-no-padding'>
                                                            <img alt={t("view_diary")} src="assets/images/template.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("view_diary")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            {(authInfo.institute.type === "school") && (
                                            <IonCard className="action-card" routerLink={`/app/diary/view/subject`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("view_subject_wise")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            )}
                                            <IonCard className="action-card" routerLink={`/app/diary/view/teacher`}>
                                                <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear"> {t("view_teacher_wise")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    </>)}

                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default Academics;