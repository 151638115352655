import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { addCircle, alertCircleOutline, closeCircle, helpCircleOutline } from 'ionicons/icons';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';
import useApiService from '../../../../components/ApiService';

interface ParamTypes {
    classID?: string
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}
interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}
interface Student {
    _id: string,
    name: string,
    feeComponents?: Component[]
}
interface Fee {
    _id: string,
    title: string,
    components: Component[],
    isActive: boolean
}

interface Component {
    head: FeeHead,
    frequency: string,
    amount: number
}

interface FeeHead {
    headID: string,
    name: string
}

interface AllHead {
    _id: string,
    name: string,
    isActive: boolean
}

const ClassFee: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {classID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [session, setSession] = useState < string > ("");
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [classi, setClassi] = useState < string > ("");
    const [students, setStudents] = useState < Student[] > ([]);
    const [heads, setHeads] = useState < AllHead[] > ([]);
    const [fees, setFees] = useState < Fee[] > ([]);
    const [selectedFees, setSelectedFees] = useState < string > ("");

    const handleStruct = (value: string) => {

        let comps = fees.find(val => val._id === value)!.components;
        let studs = [...students];

        for (let j = 0; j < studs.length; j++)
        {
            if (!studs[j].feeComponents || (studs[j].feeComponents && studs[j].feeComponents!.length === 0))
            {
                studs[j].feeComponents = [...comps];
            }
            
        }

        setStudents(studs);
        setSelectedFees(value);
            
    }

    const handleAmount = (amt: string, index: number, j: number) => {

        let studs = [...students];
        studs[j].feeComponents![index].amount = parseInt(amt);
        setStudents(studs);
        
    }

    const handleDelete = (index: number, j: number) => {
        let studs = [...students];
        
        if (index > -1) {
            studs[j].feeComponents!.splice(index, 1); // 2nd parameter means remove one item only
          }
        setStudents(studs);

    }

    const handleAdd = (j: number) => {
        let studs = [...students];
        if(studs[j].feeComponents)
        {
            studs[j].feeComponents!.push({head: {headID: "", name: ""}, frequency: '', amount: 0});
        }
        else
        {
            studs[j].feeComponents = [{head: {headID: "", name: ""}, frequency: '', amount: 0}];
        }
        setStudents(studs);

    }

    const handleHead = (headID: string, index: number, j: number) => {

        const head = heads.find(val => val._id === headID);
        if (head)
        {
            const name = head.name;
            const feehead: FeeHead = {
                headID,
                name
            };
            let studs = [...students];
            studs[j].feeComponents![index].head = feehead;
            setStudents(studs);
        }
    }
        
    const handleFreq = (freq: string, index: number, j: number) => {

        let studs = [...students];
        studs[j].feeComponents![index].frequency = freq;
        setStudents(studs);

    }

    const handleSubmit = () => {

        if (students.length < 1)
        {
            setMessage(t("no_students_mandatory"));
            setIserror(true);
            return;
        }

        for (let j = 0; j < students.length; j++)
        {
            if (students[j].feeComponents)
            {
                for (let i = 0; i < students[j].feeComponents!.length; i++)
                {
                    if (!students[j].feeComponents![i].head || !students[j].feeComponents![i].frequency || !students[j].feeComponents![i].amount 
                        || students[j].feeComponents![i].head.headID === '' || students[j].feeComponents![i].head.name === '' || students[j].feeComponents![i].frequency === '' || students[j].feeComponents![i].amount === 0)
                        {
                            setMessage(t("fee_comp_invalid"));
                            setIserror(true);
                            return;
                        }
                }
            }
        }
  
        const classStruct = () => {
          return new Promise((resolve, reject) => {
            api.post(`/classes/feeStructure`, { students }).then(res => {
              return resolve(res.data);
            }).catch(err => reject(err));
          });
        }
  
        setShowLoading(true);
        classStruct()
        .then(data => {
          
          setPass(((authInfo.institute.type === "school") ? t("class_students") : t("batch_students"))+t("have_been_updated"));
          setIspass(true);
        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
        
    }

    const handleSession = (sessionName : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionName}`);
                setClasses(result.data);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setSession(sessionName);
        setClassi("");
        setStudents([]);
    }

    const handleClassi = (classID : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/students/fee/getByClass/${classID}`);
                setStudents(studs.data);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
        setClassi(classID);
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                setSessions(res.data);

                const fees = await api.get(`/fees/getAllInstitute/${authInfo.user.instituteID}`);
                setFees(fees.data);

                const result = await api.get(`/heads/getAllInstitute/${authInfo.user.instituteID}`);
                setHeads(result.data);

                if (classID)
                {
                    const result = await api.get(`/classes/${classID}`);
                    setSession(result.data.sessionID);

                    const query = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${result.data.sessionID}`);
                    setClasses(query.data);
                    setClassi(classID);
                    const studs = await api.get(`/students/fee/getByClass/${classID}`);
                    setStudents(studs.data);
                }
                else
                {
                    setSession("");
                    setClasses([]);
                    setClassi("");
                    setStudents([]);
                }

            } catch (error: any) {
               //
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, classID]);
      
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/accounting`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{(authInfo.institute.type === "school") ? t("class_fee_struct") : t("batch_fee_struct")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{(authInfo.institute.type === "school") ? t("class_fee_struct") : t("batch_fee_struct")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app/accounting`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding">
                    <IonRow className="max-xxl">
                        <IonCol sizeLg="6" size="12" className='ion-padding-horizontal'>
                            <IonRow className="mt-30">
                                <IonCol>
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                    </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className='ion-margin-top'>
                                <IonCol>
                                    <IonRow>
                                        <IonCol size="6">
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                            {t("class_session")}</IonLabel>
                                                            <IonSelect value={session}
                                                                    className="input-field"
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    placeholder={t("class_session")}
                                                                    onIonChange={
                                                                        (e) => handleSession(e.detail.value)
                                                                }>

                                                                    {sessions.map((session) => (
                                                                        (session.isActive) && 
                                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                                    ))}

                                                            </IonSelect>
                                                        </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}</IonLabel>
                                                            <IonSelect value={classi}
                                                                    className="input-field"
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                                    onIonChange={
                                                                        (e) => handleClassi(e.detail.value)
                                                                }>
                                                                    
                                                                    {classes.map((classy) => (
                                                                        (classy.isActive) &&
                                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                                    ))}
                                                            </IonSelect>
                                                        </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>

                                    </IonRow>
                                </IonCol>
                            </IonRow>

                        </IonCol>
                        <IonCol sizeLg="6" size="12" className='ion-padding-horizontal'>
                            <IonRow className="mt-30">
                                <IonCol>
                                    <IonTitle id="open-fees-info" className='border-bottom-sec'>
                                        {t("set_fee_struct")}
                                        <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                    </IonTitle>
                                    <IonPopover trigger="open-fees-info" className='info-popover'>
                                        <IonCard className='note-card-3 ion-no-margin'>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonRow>
                                                    <IonCol className='note-text'>
                                                        <p>
                                                        {t("class_fees_help")}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonPopover>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("fee_struct")}</IonLabel>
                                                    <IonSelect value={selectedFees}
                                                            className="input-field"
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={t("fee_struct")}
                                                            onIonChange={
                                                                (e) => e.detail.value && handleStruct(e.detail.value)
                                                        }>

                                                            {fees.map((fee) => (
                                                                (<IonSelectOption key={fee._id} value={fee._id}>{fee.title}</IonSelectOption>)
                                                            ))}

                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-30">
                        <IonCol className='ion-padding-horizontal'>
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("students")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        {(students.length > 0) ? (students.map((stud, j) => {
                        return (
                                <IonCol size="12" sizeLg='6' className='ion-padding' key={stud._id} >
                                    <div className="ion-table">
                                    <IonRow className="table-title-2 ion-padding">
                                        <IonCol className='table-heading-1 mt-05 ion-text-center text-capitalize'>
                                                {stud.name}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="table-title ion-padding">
                                        <IonCol size="4" className='table-heading mt-05'>
                                            {t("fee_head")}
                                        </IonCol>
                                        <IonCol size="4" className='table-heading mt-05'>
                                            {t("fee_frequency")}
                                        </IonCol>
                                        <IonCol size="3" className='table-heading ion-text-right mt-05 ion-padding-end'>
                                            {t("fee_amount")}
                                        </IonCol>
                                        <IonCol size="1">
                                            <IonIcon 
                                                icon={addCircle}
                                                color="light"
                                                className='table-icon ml-05'
                                                size="large"
                                                onClick={() => handleAdd(j)}
                                                />
                                        </IonCol>
                                    </IonRow>
                                    {stud.feeComponents && (stud.feeComponents.length > 0) ? (stud.feeComponents.map((component, i) => {
                                        return (
                                            <IonRow key={i} className='row-table ion-padding-horizontal'>
                                                    <IonCol size="4" className='table-field' >
                                                        <IonItem>
                                                            <IonSelect value={component.head.headID}
                                                                    className='ion-no-padding' 
                                                                    style={{'maxWidth': '100%'}} 
                                                                    placeholder={t("fee_head")}
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    onIonChange={
                                                                        (e) => handleHead(e.detail.value!, i, j)
                                                                }>
                                                                    <IonLabel>{t("fee_head")}</IonLabel>
                                                                    {heads.length > 0 && heads.map((head) => (
                                                                        (head.isActive) && 
                                                                        (<IonSelectOption key={head._id} value={head._id}>{head.name}</IonSelectOption>)
                                                                    ))}
        
                                                            </IonSelect>
                                                        </IonItem>
                                                    </IonCol>

                                                    <IonCol size="4" className='table-field' >
                                                        <IonItem>
                                                            <IonSelect placeholder={t("fee_frequency")}
                                                                className='ion-no-padding' 
                                                                style={{'maxWidth': '100%'}} 
                                                                cancelText={t("cancel")}
                                                                okText={t("ok")}
                                                                value={component.frequency}
                                                                onIonChange={
                                                                    (e) => handleFreq(e.detail.value!, i, j)
                                                                }
                                                                >
                                                                <IonLabel>{t("fee_frequency")}</IonLabel>
                                                                <IonSelectOption value="once">{t("once")}</IonSelectOption>
                                                                <IonSelectOption value="annually">{t("annually")}</IonSelectOption>      
                                                                <IonSelectOption value="quaterly">{t("quaterly")}</IonSelectOption>   
                                                                <IonSelectOption value="monthly">{t("monthly")}</IonSelectOption>   
                                                            </IonSelect>
                                                            
                                                        </IonItem>
                                                    
                                                    </IonCol>

                                                    <IonCol size="3" className='table-field ' >
                                                        <IonItem className='fee-comp-height'>
                                                            <IonInput type="number"
                                                                className='ion-text-right'
                                                                placeholder='0'
                                                                value={component.amount}
                                                                onIonInput={
                                                                    (e) => e.detail.value && handleAmount(e.detail.value, i, j)
                                                            }></IonInput>
                                                        </IonItem>
                                                    </IonCol>
                                                    
                                                    <IonCol size="1" >
                                                            <IonIcon 
                                                            className='mt-15 ml-05'
                                                            icon={closeCircle}
                                                            color='dark'
                                                            onClick={() => handleDelete(i, j)}
                                                            size="large"
                                                            />

                                                    </IonCol>
                                                
                                            </IonRow>
                                        )
                                    })) : ( 
                                    <IonRow>
                                        <IonCol>
                                            <IonCard>
                                                <IonCardContent>
                                                    <IonItem lines="none">
                                                        <IonIcon icon={alertCircleOutline}
                                                            slot="start"
                                                            color="danger" />
                                                        <IonLabel className="list-title"><h3>{t("no_fee_comps")}</h3></IonLabel>

                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    )
                                } 
                                    
                                
                                </div>
                                
                            </IonCol>
                           
                          )
                        })) : ( 

                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                            <IonLabel className="list-title"><h3>{t("no_students")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>

                        )
                    } 
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{(authInfo.institute.type === "school") ? t("update_class_students") : t("update_batch_students")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default ClassFee;