import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCheckbox,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { attach, camera, trash } from 'ionicons/icons';
import { Capacitor } from '@capacitor/core';
import { Camera, CameraSource, CameraResultType } from '@capacitor/camera';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';
import useApiService from '../../../../components/ApiService';

interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    isActive: boolean,
    isChecked: boolean
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

const AddCircular: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [title, setTitle] = useState < string > ("");
    const [text, setText] = useState < string > ("");
    const [mode, setMode] = useState < string > ("Circular");
    const [imageURL, setImageURL] = useState < string > ("");
    const [upload, setUpload] = useState < File | null > (null);
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [session, setSession] = useState < string > ("");
    const [type, setType] = useState < string > ("");
    const [buttonColor, setButtonColor] = useState < string > ("light");

    const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });

    const convertFileSrc = (path: string) => {
        return Capacitor.convertFileSrc(path);
    };

    const takePhoto = async () => {
        try {
            Camera.checkPermissions().then(async (res) => {
                if (res.camera !== 'granted') {
                    Camera.requestPermissions({ permissions: ['camera'] }).then(async (ress) => {
                        if (ress.camera === 'denied') {
                            setMessage(t("photo_denied"));
                            setIserror(true);
                        } else {
                            try {
                                const image = await Camera.getPhoto({
                                    source: CameraSource.Camera,
                                    quality: 90,
                                    allowEditing: true,
                                    resultType: CameraResultType.Uri
                                });
                            
                                const webPath = image.webPath;

                                if (webPath) {
                                    const blob = await fetch(webPath).then((r) => r.blob());
                                    const rawFile = new File([blob], 'camera_image', {
                                        type: 'image/jpeg',
                                    });
                                    setUpload(rawFile);
                                    setImageURL(webPath);
                                }
                                
                                
                            } catch(error: any) {
                                setMessage(t("camera_error"));  
                                setIserror(true);
                            }
                        }
                    });
                }
                else {
                    try {
                        const image = await Camera.getPhoto({
                            source: CameraSource.Camera,
                            quality: 90,
                            allowEditing: true,
                            resultType: CameraResultType.Uri
                        });
                    
                        const webPath = image.webPath;

                        if (webPath) {
                            const blob = await fetch(webPath).then((r) => r.blob());
                            const rawFile = new File([blob], 'camera_image', {
                                type: 'image/jpeg',
                            });
                            setUpload(rawFile);
                            setImageURL(webPath);
                        }
                        
                        
                    } catch(error: any) {
                        setMessage(t("camera_error"));  
                        setIserror(true);
                    }
                }
            });

        } catch {
            setMessage(t("camera_error"));
            setIserror(true);
        } 
    };

    const pickFile = async () => {
        try {

           const result = await FilePicker.pickFiles({limit: 1});
           if (result.files.length === 1)
           {
               const file = result.files[0];
               //for native
               if (file && file.path && file.name && file.mimeType) {
                   const webPath = convertFileSrc(file.path);
                   if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                   {
                       setImageURL(webPath);
                   }
                   else
                    {
                        setImageURL("");
                    }
                   const blob = await fetch(webPath).then((r) => r.blob());
                   const rawFile = new File([blob], file.name, {
                       type: file.mimeType,
                   });
                   setUpload(rawFile);
               }
               //for web
               else if (file && file.blob && file.name && file.mimeType) {
                   const rawFile = new File([file.blob], file.name, {
                       type: file.mimeType,
                   });
                   setUpload(rawFile);
                   if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                   {
                       const base64 = await fileToDataUri(rawFile);
                       setImageURL(base64);
                   }
                   else
                    {
                        setImageURL("");
                    }
               }
           }
       } 
       catch(error: any) {

           setMessage(t("no_files_selected"));  
           setIserror(true);
       }
   };

    const handleSession = (sessionName : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionName}`);
                setClasses(result.data);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setSession(sessionName);
        setClasses([]);
    }

    const handleChecked = (value: boolean, id : number) => {

        let classic: Array < ClassInterface > = [];
        for (let i = 0; i < classes.length ; i++) {
            if (i === id) {
                classic[i] = {'_id': classes[i]._id, 'name': classes[i].name, 'year': classes[i].year, 'isActive': classes[i].isActive, 'isChecked' : !value};    
            }
            else 
            {
                classic[i] = classes[i];
            }       
        }
        setClasses(classic);
    }

    const handleAll = () => {

        if (buttonColor === "light")
        {
            let classic: Array < ClassInterface > = [];
            for (let i = 0; i < classes.length ; i++) {
                classic[i] = {'_id': classes[i]._id, 'name': classes[i].name, 'year': classes[i].year, 'isActive': classes[i].isActive, 'isChecked' : true};    
            }
            setClasses(classic);
            setButtonColor("warning");
        }
        else
        {
            let classic: Array < ClassInterface > = [];
            for (let i = 0; i < classes.length ; i++) {
                classic[i] = {'_id': classes[i]._id, 'name': classes[i].name, 'year': classes[i].year, 'isActive': classes[i].isActive, 'isChecked' : false};    
            }
            setClasses(classic);
            setButtonColor("light");
        }

       
    }

    const handleSubmit = () => {

        if (!title || title === "") {
            setMessage(t("title_mandatory"));
            setIserror(true);
            return;
        }

        if (!type || type === "") {
            setMessage(t("type_mandatory"));
            setIserror(true);
            return;
        }

        if (mode === "Circular")
        {
            if (!upload) {
                setMessage(t("circular_format"));
                setIserror(true);
                return;
            }
        }

        if (mode === "Announcement")
        {
            if (!text || text === "") {
                setMessage(t("message_mandatory"));
                setIserror(true);
                return;
            }
        }

        let classy: Array < string > = [];
        for (let i = 0; i < classes.length ; i++) {
            if (classes[i].isChecked)
                classy.push(classes[i]._id);
        }

        if(!classy.length)
        {
            setMessage((authInfo.institute.type === "school") ? t("select_classes") : t("select_batches"));
            setIserror(true);
            return;
        }

          const addCircular = (formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/circulars/add', formData).then(res => {
                return resolve(res.data.circular.title);
              }).catch(err => reject(err));
            });
          }

          const send = (classic: Array < string >) => {
            return new Promise((resolve, reject) => {
              api.post('/circulars/admnAdd', { 'instituteID': authInfo.user.instituteID, 'adminID': authInfo.user._id, 'classes': classic, title, text, type }).then(res => {
                return resolve(res.data.circular.title);
              }).catch(err => reject(err));
    
            });
          }

        if (mode === "Circular")
        {
           
            if (upload) {
            
            let formData = new FormData();

            formData.append('instituteID', authInfo.user.instituteID);
            formData.append('title', title);
            formData.append('type', type);
            formData.append('classes', JSON.stringify(classy));
            formData.append('adminID', authInfo.user._id);
            formData.append('circular', upload, 'circular');

            setShowLoading(true);
            addCircular(formData)
                .then(data => {
                    setPass(data+t("has_been_issued"));
                    setIspass(true);
                })
                .catch((error) => {
                    //
                })
                .finally(() => setShowLoading(false));
            }
        }

        if (mode === "Announcement")
        {
            setShowLoading(true);
            send(classy)
            .then(data => {
                setPass(data+t("has_been_issued"));
                setIspass(true);
            })
            .catch((error) => {
                //
            })
            .finally(() => setShowLoading(false));
        }
    }

      useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && !authInfo.user.roles.includes("Academic") && authInfo.user.roles.includes("Teacher")))
                {
                    const classy = await api.get(`/classes/getByTeacher/${authInfo.user._id}`);
                    setClasses(classy.data);
                }
                if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
                {
                    const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                    setSessions(res.data);
                    setClasses([]);
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref={`/app/communication`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{(authInfo.institute.type === "school") ? t("issue_class_comm") : t("issue_batch_comm")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{(authInfo.institute.type === "school") ? t("issue_class_comm") : t("issue_batch_comm")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false); 
                            history.push(`/app/circulars`);
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding">
                    <IonRow className="ion-padding-vertical max-xl">
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("communication_details")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding mt-15">
                                <IonCol>
                                    <IonSegment value={mode} onIonChange={e => setMode( e.detail.value!.toString() )} mode="ios" color="secondary">
                                        <IonSegmentButton value="Circular">
                                            <IonLabel>{t("circular")}</IonLabel>
                                        </IonSegmentButton>
                                        <IonSegmentButton value="Announcement">
                                            <IonLabel>{t("announcement")}</IonLabel>
                                        </IonSegmentButton>
                                    </IonSegment>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("comm_title")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("comm_title")}
                                                        value={title}
                                                        onIonInput={
                                                            (e) => setTitle(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("comm_type")}*
                                                    </IonLabel>
                                                    <IonSelect value={type}
                                                        className="input-field"
                                                        placeholder={t("comm_type")}
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        onIonChange={
                                                            (e) => setType(e.detail.value)
                                                        }>
                                                    
                                                        <IonSelectOption value="general">{t("general_comm")}</IonSelectOption>
                                                        <IonSelectOption value="academic">{t("academic_comm")}</IonSelectOption> 
                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                           
                            {(mode === "Circular") && (
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                                {isPlatform("capacitor") && (
                                                <IonItem detail={true}
                                                    lines='none'
                                                    button={true}
                                                    onClick={takePhoto}
                                                    >
                                                    <IonIcon
                                                        slot="start"
                                                        icon={camera}
                                                    />
                                                    <IonLabel>
                                                        {t("take_photo")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                )}
                                                <IonItem detail={true}
                                                    lines='none'
                                                    color="primary"
                                                    button={true}
                                                    onClick={pickFile}
                                                    >
                                                    <IonIcon
                                                        slot="start"
                                                        icon={attach}
                                                    />
                                                    <IonLabel>
                                                    {(upload) ? t("change_file") : t("attach_file")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                {(upload) && (
                                                <IonItem detail={true}
                                                    detailIcon={trash}
                                                    lines='none'
                                                    button={true}
                                                    onClick={
                                                        () => {
                                                            setUpload(null);
                                                            setImageURL("");
                                                        }
                                                    }
                                                >
                                                    <IonBadge color="danger" slot="start">1</IonBadge>
                                                    <IonLabel>
                                                        {t("remove_files")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                )}
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )}
                             {(mode === "Circular") && imageURL && (
                            <IonRow>
                                <IonCol>
                                    <IonCard className="ion-text-center">
                                        <IonCardHeader>
                                            <IonCardSubtitle>{t("circular_preview")}</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                        
                                            <img src={imageURL} 
                                                alt="Circular Preview" 
                                                width="320" />
        
                                        </IonCardContent>        
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )}
                            {(mode === "Announcement") && (
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}>
                                                <IonLabel position="floating" color="secondary">{t("circular_msg")}</IonLabel>
                                                <IonTextarea rows={20} value={text} onIonInput={
                                                        (e) => setText(e.detail.value!)
                                                }></IonTextarea>
                                            
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )}

                        </IonCol>
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {(authInfo.institute.type === "school") ? t("comm_classes") : t("comm_batches")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            
                    {((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic"))) && (
                      <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("class_session")}</IonLabel>
                                                    <IonSelect value={session}
                                                            className="input-field"
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={t("class_session")}
                                                            onIonChange={
                                                                (e) => handleSession(e.detail.value)
                                                        }>

                                                            {sessions.map((session) =>
                                                                (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                            )}

                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )}

                            <IonRow className="ion-margin-top">
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonList className="ion-no-padding">
                                                <IonItemDivider color="secondary">
                                                   
                                                    <IonButtons slot="start">
                                                        <IonButton onClick={handleAll} color={buttonColor}>{t("select_all")}</IonButton>
                                                    </IonButtons>
                                                </IonItemDivider>
                                                {classes.map((classi, i) => (
                                                    (classi.isActive) &&
                                                    (<IonItem key={classi._id}
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value={classi._id} checked={classi.isChecked} 
                                                            onClick={e => handleChecked(classi.isChecked, i)}/>
                                                        <IonLabel>{classi.name} ({classi.year})</IonLabel>
                                                    </IonItem>)
                                                ))}

                                            </IonList>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("issue_comm")} {mode === "Circular" ? t("circular") : t("announcement")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddCircular;
