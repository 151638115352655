import {
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { alertCircleOutline, book, documentText } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import useApiService from '../../../components/ApiService';

interface ParamTypes {
    subjectID: string
}

interface Chapter {
    _id: string,
    chapterName: string,
    isActive: boolean,
    order: number
}
interface Topic {
    _id: string,
    name: string,
    isActive: boolean,
    order?: number
}


const Chapters: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {t} = useTranslation();
    const history = useHistory();
    const {subjectID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [name, setName] = useState < string > ("");
    const [month, setMonth] = useState < string > ("");
    const [segmentsB, setSegmentsB] = useState < Chapter[] > ([]);
    const [topics, setTopics] = useState < Topic[] > ([]);

    const handleMonth = (chapter : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const tops = await api.get(`/topics/getByChapter/${chapter}`);
                setTopics(tops.data);

            } catch (error: any) {
               //
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
        setMonth(chapter);
        
    }

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
               
                const course = await api.get('/subjects/' + subjectID);
                setName(course.data.name);
                //sort chapters based on order
                const resu: Chapter[] = course.data.chapters.sort((a: Chapter, b: Chapter) => a.order! - b.order!);

                setSegmentsB(resu);

                setMonth(resu[0]._id);

                const tops = await api.get(`/topics/getByChapter/${resu[0]._id}`);
                setTopics(tops.data);
                

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [subjectID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/subjects/teacher`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary" className='text-capitalize'>{name}</IonTitle>
                    
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                    
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary" className='text-capitalize'>{name}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonGrid className="ion-no-padding">

           
                     <IonRow>
                        <IonCol>
                            <IonSegment mode="md" scrollable={true} value={month} onIonChange={(e) => handleMonth(e.detail.value!.toString())} className="planner-segment">

                            {segmentsB && segmentsB.map((seg, i) => seg.isActive && (
                                <IonSegmentButton key={i} value={seg._id} className='planner-button'>
                                    <IonLabel>{seg.chapterName}</IonLabel>
                                </IonSegmentButton>
                            ))}
                               
                
                            </IonSegment>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-padding">
                        <IonCol>
                        {topics.length > 0 ? (
                                            
                            <IonCard className='border-radius-10 ion-margin-bottom planner-card-3'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonList className='ion-no-margin ion-no-padding'>
                                    {topics.map((topic, i) => 
                                    (topic.isActive) &&
                                    ( 
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            key={topic._id}
                                            className="topic-item" >

                                                <IonIcon
                                                    slot="start"
                                                    className='tran-icon'
                                                    icon={book}
                                                    color="tertiary"
                                                    />

                                                <IonLabel className='topic-label ion-text-wrap'>
                                                    <h3>{topic.name}</h3>
                                                </IonLabel>
                                             
                                                <IonButtons slot="end" className="ml-16">
                                                    <IonIcon
                                                        className='tran-icon'
                                                        icon={documentText} 
                                                        color="primary"
                                                        onClick={() => history.push(`/app/teacher/submissions/subject/${topic._id}`)}
                                                    />
                                                </IonButtons>

                                        </IonItem> 
                                        )
                                    )}
                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                                
                            ) : (
                            <IonCard className='red-card ion-margin'>
                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_topics")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                            )
                        }

                        </IonCol>
                    </IonRow>
                    
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default Chapters;
