import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';
import useApiService from '../../../../components/ApiService';

interface Driver {
    _id: string,
    userInfo: UserInfo
}
interface UserInfo {
    userID: string,
    name: string,
    phone: string,
}

interface Vehicle {
    _id: string,
    name: string,
    plate: string,
    isActive: boolean
}

const AddRoute: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [drivers, setDrivers] = useState < Driver[] > ([]);
    const [driver, setDriver] = useState < string > ("");
    const [driverID, setDriverID] = useState < string > ("");
    const [driverName, setDriverName] = useState < string > ("");
    const [driverPhone, setDriverPhone] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [type, setType] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] > ('06:00');
    const [vehicles, setVehicles] = useState < Vehicle[] > ([]);
    const [vehicle, setVehicle] = useState < string > ("");
    const [vehicleName, setVehicleName] = useState < string > ("");
    const [vehiclePlate, setVehiclePlate] = useState < string > ("");

    const handleDriver = (driverID : string) => {
        let selectedDriver: Driver | undefined;
        selectedDriver = drivers.find(x => x._id === driverID)!;
        setDriverID(driverID);
        setDriver(selectedDriver.userInfo.userID);
        setDriverName(selectedDriver.userInfo.name);
        setDriverPhone(selectedDriver.userInfo.phone);
    }

    const handleVehicle = (vehicleID : string) => {
        let selectedVehicle: Vehicle | undefined;
        selectedVehicle = vehicles.find(x => x._id === vehicleID);
        setVehicle(vehicleID);
        setVehicleName(selectedVehicle!.name);
        setVehiclePlate(selectedVehicle!.plate);
    }

    const handleSubmit = () => {

        if (!name || name === "") {
            setMessage(t("route_name_mandatory"));
            setIserror(true);
            return;
        }

        if (!type || type === "") {
            setMessage(t("route_type_mandatory"));
            setIserror(true);
            return;
        }

        if (!driver || driver === "") {
            setMessage(t("route_driver_mandatory"));
            setIserror(true);
            return;
        }

        if (!selectedDate || selectedDate === "") {
            setMessage(t("start_time_mandatory"));
            setIserror(true);
            return;
        }
  
        const addRoute = (startTime: string | string[]) => {
          return new Promise((resolve, reject) => {
            api.post('/routes/add', { 'instituteID': authInfo.user.instituteID, name, type, startTime, driver, driverName, driverPhone, vehicle, vehicleName, vehiclePlate }).then(res => {
              return resolve(res.data.routei.name);
            }).catch(err => reject(err));
          });
        }
  
        setShowLoading(true);
        addRoute(selectedDate)
        .then(data => {
          
          setPass(data+t("has_been_added"));
          setIspass(true);
        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
        
    }
      
    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/employees/getDrivers/${authInfo.user.instituteID}`);
                setDrivers(result.data);

                const res = await api.get(`/vehicles/getAllInstitute/${authInfo.user.instituteID}`);
                setVehicles(res.data);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/transport`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("add_new_route")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("add_new_route")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app/routes`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding">

                    <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("route_details")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("route_name")}*
                                        </IonLabel>
                                            <IonInput type="text"
                                                className="input-field"
                                                placeholder={t("route_name")}
                                                value={name}
                                                onIonInput={
                                                    (e) => setName(e.detail.value!)
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("route_type")}*
                                        </IonLabel>
                                            <IonSelect value={type}
                                                className="input-field" 
                                                cancelText={t("cancel")}
                                                okText={t("ok")}
                                                placeholder={t("route_type")}
                                                onIonChange={
                                                    (e) => setType(e.detail.value)
                                            }>
                                               
                                                <IonSelectOption value="pickup">{t("pickup")}</IonSelectOption>
                                                <IonSelectOption value="drop">{t("drop")}</IonSelectOption>      
                                                
                                            </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("route_driver")}*
                                        </IonLabel>
                                            <IonSelect value={driverID}
                                                className="input-field" 
                                                cancelText={t("cancel")}
                                                okText={t("ok")}
                                                placeholder={t("route_driver")}
                                                onIonChange={
                                                    (e) => handleDriver(e.detail.value)
                                            }>
                                               
                                               {drivers.map((driver) => 
                                                (<IonSelectOption key={driver._id} value={driver._id}>{driver.userInfo.name}</IonSelectOption>)
                                                )}
                                                
                                            </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("route_vehicle")}
                                        </IonLabel>
                                            <IonSelect value={vehicle}
                                                className="input-field" 
                                                cancelText={t("cancel")}
                                                okText={t("ok")}
                                                placeholder={t("route_vehicle")}
                                                onIonChange={
                                                    (e) => handleVehicle(e.detail.value)
                                            }>
                                               
                                               { vehicles.map((vehicle) => 
                                                (vehicle.isActive) &&
                                                (<IonSelectOption key={vehicle._id} value={`${vehicle._id}`}>{vehicle.name}</IonSelectOption>)
                                                )}
                                                
                                            </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="fixed" className="input-label" color="secondary">
                                        {t("route_start")}*
                                        </IonLabel>
                                            <IonDatetime
                                                value={selectedDate}
                                                presentation="time"
                                                onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                            />
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("add_route")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddRoute;
