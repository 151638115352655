import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { alertCircleOutline, checkboxOutline, construct } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';
import useApiService from '../../../../components/ApiService';

interface TimeTable {
    _id: string,
    name: string,
    classInfo: ClassInfo,
    isCurrent: boolean,
    isActive: boolean
}
interface ClassInfo {
    classID: string,
    className: string,
    session: string
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

const TimeTables: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [tables, setTables] = useState < TimeTable[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [session, setSession] = useState < string > ("");
    const [skip, setSkip] = useState < number > (0);
    const [current, setCurrent] = useState < string > ("");
    const [total, setTotal] = useState < number > (0);
   
    const loadItems = async () => {

        let limit = skip+30;

        try {
            const result = await api.get(`/timeTables/listInstitute/${authInfo.user.instituteID}/${session}?skip=${limit}`);

            if(result.data.tables && result.data.tables.length)
            {
                setTables([...tables, ...result.data.tables]);
                setSkip(limit);
            }
                

        } catch (error: any) {
            //
        }
        
    }

    const handleSession = () => {

        if (!session || session === "") {
            setMessage(t("select_session_first"));
            setIserror(true);
            return;
        }

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/timeTables/listInstitute/${authInfo.user.instituteID}/${session}`);
                setTables(result.data.tables);
                setTotal(result.data.total);
                setSkip(0);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }

     const handleSubmit = () => {
  
        const upTable = (tableID: string) => {
          return new Promise((resolve, reject) => {
            api.put('/timeTables/'+tableID, { 'setCurrent': 'yes' }).then(res => {
              return resolve(res.data.name);
            }).catch(err => reject(err));
          });
        }
    
        if (current !== "")
        {
            setShowLoading(true);
            upTable(current)
            .then(data => {
    
                setPass(data+t("timetable_active"));
                setIspass(true);
            
            })
            .catch((error) => {
                //
            })
            .finally(() => setShowLoading(false));
        }
        
    }

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {

                const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                setSessions(res.data);
                setTables([]);
                setTotal(0);
                setSkip(0);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
       

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/academics`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("manage_timetables")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_timetables")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                    
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding mb-60 overflow-x">

                    <IonRow>
                        <IonCol sizeXl="3" size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" color="secondary" className='input-label'>
                                            {t("class_session")}</IonLabel>
                                            <IonSelect value={session}
                                                    className='input-field'
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("class_session")}
                                                    onIonChange={
                                                        (e) => setSession(e.detail.value)
                                                }>

                                                    {sessions.length > 0 && sessions.map((session) => (
                                                                (session.isActive) && 
                                                                (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                            ))}

                                                    
                                            </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol sizeXl="3" size="6" className="ion-padding">
                            <IonButton onClick={() => handleSession() }
                                color="primary" className='mt-15'>{t("view_tables")}
                            </IonButton>
                        </IonCol>
                    </IonRow>

                    <div className="mt-30 ion-table">

                        <IonRow className="table-title ion-padding">
                            <IonCol size="6" className='table-heading'>
                                <IonIcon 
                                    icon={checkboxOutline}
                                    color="light"
                                    className='table-icon'
                                    />
                                    <IonText className='ion-margin-start'>{t("name")}</IonText>
                            </IonCol>
                            <IonCol size="5" className='table-heading'>
                            {t("class")}
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={construct}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>
                        <IonRadioGroup value={current} onIonChange={
                                            (e) => setCurrent(e.detail.value!)
                                    }>

                        {(tables.length > 0) ? (tables.map((table, i) => {
                            return (
                                <IonRow key={table._id} 
                                className={`${(i % 2 === 0) ? 'row-table' : 'row-table-alt'} ${table.isCurrent && 'font-color-7'} ion-padding-horizontal`}
                                onClick={
                                    () => setCurrent(table._id)
                                }
                                onDoubleClick={
                                    () => history.push(`/app/timetable/${table._id}`)
                                }>
                                    
                                        <IonCol size="6" className='table-field' >
                                            <IonRadio value={table._id} className='table-icon' color="secondary" />
                                            <IonText className='ion-margin-start'>{table.name}</IonText>
                                        </IonCol>
                                        <IonCol size="5" className='table-field'>
                                            {table.classInfo.className}
                                        </IonCol>
                                        <IonCol size="1" >
                                                <IonIcon 
                                                icon={construct}
                                                color={table.isCurrent ? "success" : "dark"}
                                                className='table-icon mt-10'
                                                onClick={
                                                    () => history.push(`/app/timetable/${table._id}`)
                                                }
                                                />
  
                                        </IonCol>
                                    
                                </IonRow>
                            )
                        })) : ( 
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                            <IonLabel className="list-title"><h3>{t("no_tables")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        )
                        } 
                    </IonRadioGroup>
                    </div>

                    {(tables.length > 0) &&
                    <IonRow>
                        <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCol>
                    </IonRow>
                    }

                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                            {t("showing")} {total === 0 ? t("no_records") : `1 - ${tables.length} ${t("of")} ${total}`} 
                        </IonCol>
                        </IonRow>
                            <IonRow className="ion-padding-vertical overflow-x">
                                <IonCol className="min-md">
                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (current === "")
                                                {
                                                    setMessage(t("select_timetable"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/app/timetable/${current}`)
                                                }

                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("view_update")}</IonButton>

                                    <IonButton 
                                        onClick={handleSubmit}
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("set_active")}</IonButton>

                                </IonCol>
                            </IonRow>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default TimeTables;