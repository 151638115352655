import {
  IonRouterOutlet,
} from "@ionic/react";

import React from 'react';
import { Redirect, Route, Switch } from "react-router";

import MasterHome from "../pages/Home/MasterHome";
import Regions from "../pages/Master/Region/Regions";
import AddRegion from "../pages/Master/Region/AddRegion";
import RegionDetail from "../pages/Master/Region/RegionDetail";
import Institutes from "../pages/Master/Institute/Institutes";
import AddInstitute from "../pages/Master/Institute/AddInstitute";
import InstituteDetail from "../pages/Master/Institute/InstituteDetail";
import InstituteLocation from "../pages/Master/Institute/InstituteLocation";
import AddContentAdmin from "../pages/Master/Admin/AddContentAdmin";
import ContentAdmins from "../pages/Master/Admin/ContentAdmins";
import AddInstituteAdmin from "../pages/Master/Admin/AddInstituteAdmin";
import InstituteAdmins from "../pages/Master/Admin/InstituteAdmins";
import AddSuperAdmin from "../pages/Master/Admin/AddSuperAdmin";
import SuperAdmins from "../pages/Master/Admin/SuperAdmins";
import AdminDetail from "../pages/Master/Admin/AdminDetail";
import SendNotification from "../pages/Master/SendNotification";

import UserNotifications from "../pages/User/UserNotifications";
import Profile from "../pages/User/Profile";
import UpdatePersonal from "../pages/User/UpdatePersonal";
import UpdatePassword from "../pages/User/UpdatePassword";
import Terms from "../pages/General/Policies/TermsAndConditions";
import Privacy from "../pages/General/Policies/PrivacyPolicy";
import ContactUs from "../pages/General/ContactUs";

const MasterTabs: React.FC = () => {
  return (
      <IonRouterOutlet>
        <Switch>
          <Route path="/app/home" exact={true} component={MasterHome} />
          <Route path="/app/regions" exact={true} component={Regions} />
          <Route path="/app/regions/add" exact={true} component={AddRegion} />
          <Route path="/app/region/:regionID" exact={true} component={RegionDetail} />
          <Route path="/app/institutes" exact={true} component={Institutes} />
          <Route path="/app/institutes/add" exact={true} component={AddInstitute} />
          <Route path="/app/institute/:instituteID" exact={true} component={InstituteDetail} />
          <Route path="/app/location/:instituteID" exact={true} component={InstituteLocation} />
          <Route path="/app/admins/super" exact={true} component={SuperAdmins} />
          <Route path="/app/admins/super/add" exact={true} component={AddSuperAdmin} />
          <Route path="/app/admins/institute" exact={true} component={InstituteAdmins} />
          <Route path="/app/admins/institute/add" exact={true} component={AddInstituteAdmin} />
          <Route path="/app/admins/content" exact={true} component={ContentAdmins} />
          <Route path="/app/admins/content/add" exact={true} component={AddContentAdmin} />
          <Route path="/app/admin/:adminID" exact={true} component={AdminDetail} />
          <Route path="/app/notifications" exact={true} component={SendNotification} />

          <Route path="/app/user/notification" exact={true} component={UserNotifications} />
          <Route path="/app/user/profile" exact={true} component={Profile} />
          <Route path="/app/user/profile/personal" exact={true} component={UpdatePersonal} />
          <Route path="/app/user/profile/password" exact={true} component={UpdatePassword} />
          <Route path="/app/policies/terms" exact={true} component={Terms} />
          <Route path="/app/policies/privacy" exact={true} component={Privacy} />
          <Route path="/app/support/contact" exact={true} component={ContactUs} />

          <Redirect exact={true} path="/app" to="/app/home" />
          <Redirect from="*" to="/app" />
          </Switch>
      </IonRouterOutlet>
  );
};

export default MasterTabs;
