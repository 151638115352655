import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import { addCircleOutline, alertCircleOutline, camera, eye, informationCircleOutline, pencil, trash } from 'ionicons/icons';
import { Capacitor } from '@capacitor/core';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { PhotoViewer } from '@capacitor-community/photoviewer';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { useAuth } from '../../components/AuthContext';
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';
import useApiService from '../../components/ApiService';

import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/autoplay";
import '@ionic/react/css/ionic-swiper.css';

interface Feed {
    _id: string,
    title: string,
    media: string,
    description: string,
    isActive: boolean
}

const Banners: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [feed, setFeed] = useState < Feed[] > ([]);
    const [title, setTitle] = useState < string > ("");
    const [link, setLink] = useState < string > ("");
    const [title1, setTitle1] = useState < string > ("");
    const [link1, setLink1] = useState < string > ("");
    const [uploadFile, setUploadFile] = useState < File | null > (null);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [selectedPic, setSelectedPic] = useState < string > ("");
    const [showPopover, setShowPopover] = useState < boolean > (false);
    const [showPopover1, setShowPopover1] = useState < boolean > (false);
    const [skip, setSkip] = useState < number > (0);

    function isValidURL(value: string) {
        // eslint-disable-next-line no-useless-escape
        var res = value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        return (res !== null);
    };

    function openURL(value: string) {

        var url = value;
        if (!/^https?:\/\//i.test(value)) {
            url = 'http://' + value;
        }

        window.open(url, '_system');
        return false;
    };

    const loadItems = async () => {

        let limit = skip+30;

        try {
            const result = await api.get(`/feeds/getWhiteHome/${authInfo.user.instituteID}?skip=${limit}`);

            if(result.data)
            {
                setFeed([...feed, ...result.data]);
                setSkip(limit);
            }
                

        } catch (error: any) {
            //
        }
    }

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    const openImage = (url: string, name: string) => {
        PhotoViewer.show({images: [{url, title: name}]});
    }

    const convertFileSrc = (path: string) => {
        return Capacitor.convertFileSrc(path);
    };
      
    const pickImage = async () => {
         try {

            const result = await FilePicker.pickImages({limit: 1});

            if (result.files.length === 1)
            {
                const file = result.files[0];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    const webPath = convertFileSrc(file.path);
                    const blob = await fetch(webPath).then((r) => r.blob());
                    const rawFile = new File([blob], file.name, {
                        type: file.mimeType,
                    });
                    setUploadFile(rawFile);
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    const rawFile = new File([file.blob], file.name, {
                        type: file.mimeType,
                    });
                    setUploadFile(rawFile);
                }
            }
            
        } 
        catch(error: any) {
 
            setMessage(t("no_files_selected"));  
            setIserror(true);
        }
    };

    const handlePhoto = () => {

        if (!uploadFile) {
            setMessage(t("gallery_format"));
            setIserror(true);
            return;
        }

        if (!title || title === "") {
            setMessage(t("pic_title_mandatory"));
            setIserror(true);
            return;
        }

        if (link && (link !== "")) {
            if (!isValidURL(link)) {
                setMessage(t("link_invalid"));
                setIserror(true);
                return;
            }
        }

        const upInstitute = ( formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/feeds/addHome', formData).then(res => {
                return resolve(res.data.feed.title);
              }).catch(err => reject(err));
            });
        }

          
        // Change uploadFile to blob in native implementation using capacitor plugin
        let formData = new FormData();
        formData.append('feed', uploadFile, 'feed');
        formData.append('instituteID', authInfo.user.instituteID);
        formData.append('title', title);
        formData.append('description', link);

        setShowLoading(true);
        upInstitute(formData)
        .then(data => {
           setShowPopover(false);
            setPass(title+t("has_been_uploaded"));
            setIspass(true);
        })
        .catch((error) => {
           //
        })
        .finally(() => setShowLoading(false));
        
    }

    const handleDelete = () => {
  
        const deleteApp = () => {
            return new Promise((resolve, reject) => {
    
                api.delete(`/feeds/${selectedPic}`).then(res => {
                    return resolve(true);
                }).catch(err => reject(err));
    
            });
        }

        setShowLoading(true);
        deleteApp()
        .then(() => {

            setPass(t("pic_deleted"));
            setIspass(true);
        })
        .catch((error) => {
           //
        })
        .finally(() => setShowLoading(false));
        
    }

    const handleSubmit = () => {

        if (!title1 || title1 === "") {
            setMessage(t("pic_title_mandatory"));
            setIserror(true);
            return;
        }

        if (link1 && (link1 !== "")) {
            if (!isValidURL(link1)) {
                setMessage(t("link_invalid"));
                setIserror(true);
                return;
            }
        }

        const updateApp = (title: string, description: string) => {
          return new Promise((resolve, reject) => {
              api.put(`/feeds/${selectedPic}`, {title, description}).then(res => {
                  return resolve(res.data.title);
                }).catch(err => reject(err));
          });
        }

        setShowLoading(true);
        updateApp(title1, link1)
        .then((data) => {

            setShowPopover1(false);
            setPass(data+t("has_been_updated"));
            setIspass(true);
        })
        .catch((error) => {
            //
        })
        .finally(() => setShowLoading(false));
      
    }
    
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const feeds = await api.get(`/feeds/getWhiteHome/${authInfo.user.instituteID}`);
                setFeed(feeds.data);
                setSkip(0);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchData();

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("home_stories")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("home_stories")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert
                    isOpen={showAlert}
                    cssClass="first-alert"
                    onDidDismiss={() => setShowAlert(false)}
                    header={t("confirm")}
                    message={t("confirm_deletion")}
                    buttons={[
                        {
                        text: t("no"),
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: t("yes"),
                        handler: () => {
                            handleDelete();
                        }
                        }
                    ]}
                />

               <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid>
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className='note-card-1'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="secondary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            <p>
                                            {t("gallery_home")}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    {(feed.length > 0) && (
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec'>
                                {t("preview")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    )}
                </IonGrid>
                <IonGrid>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <Swiper 
                                modules={[Autoplay, Pagination]}
                                spaceBetween={0}
                                initialSlide={0}
                                slidesPerView={1}
                                pagination={{el: '.swiper-pagination', clickable: false}}
                                autoplay={{
                                    delay: 3000
                                }}
                            >

                                {(feed.length > 0) && feed.map((post) => (
                                (post.media) &&
                                    (
                                    <SwiperSlide key={post._id} onClick={post.description && (post.description !== "") ? () => openURL(post.description) : undefined }>
                                            <img src={`${process.env.REACT_APP_STATIC_S3}/images/${post.media}`} alt={post.title} style={{'width': '100%', 'height': 'auto'}} />
                                    </SwiperSlide>  
                                    )
                                ))}
                                
                            </Swiper>
                        </IonCol>
                    </IonRow>
                    {(feed.length > 0) && (
                    <IonRow className="mt-30">
                        <IonCol>
                            <div className="swiper-pagination" />
                        </IonCol>
                    </IonRow>
                    )}
                </IonGrid>
                <IonGrid className="ion-no-padding mb-60">
                    <IonRow className='ion-margin-top'> 
                        
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className='action-card-one'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonItem lines="none"
                                        button={true}
                                        detail={true}
                                        onClick={() => setShowPopover(true)}
                                    >
                                        <IonIcon icon={addCircleOutline}
                                            slot="start"
                                            color="secondary" />
                                        <IonLabel className="action-title-one"><h3>{t("upload_picture")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                            <IonPopover size='cover' isOpen={showPopover} onDidDismiss={e => setShowPopover(false)}>
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" color="secondary">{t("title")}*</IonLabel>
                                                    <IonInput type="text"
                                                        value={title}
                                                        placeholder={t("title")}
                                                        onIonInput={
                                                            (e) => setTitle(e.detail.value !)
                                                    }></IonInput>
                                                </IonItem>
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" color="secondary">{t("link")}</IonLabel>
                                                    <IonInput type="text"
                                                        value={link}
                                                        placeholder={t("link")}
                                                        onIonInput={
                                                            (e) => setLink(e.detail.value !)
                                                    }></IonInput>
                                                </IonItem>
                                                <IonItem detail={true}
                                                    lines='none'
                                                    button={true}
                                                    onClick={pickImage}
                                                    >
                                                    <IonIcon
                                                        slot="start"
                                                        icon={camera}
                                                    />
                                                    <IonLabel>
                                                     {(uploadFile) ? t("change_photo") : t("upload_photo")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                {(uploadFile) && (
                                                <IonItem detail={true}
                                                        color="danger"
                                                        detailIcon={trash}
                                                        lines='none'
                                                        button={true}
                                                        onClick={() => setUploadFile(null)}
                                                    >
                                                    <IonBadge color="light" slot="start">1</IonBadge>
                                                    <IonLabel>
                                                        {t("remove_files")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                )}
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="ion-padding-horizontal">
                                        <IonButton onClick={handlePhoto}
                                            expand="block"
                                            color="secondary">{t("upload_picture")}</IonButton>

                                    </IonCol>
                                </IonRow>
                            </IonPopover>
                        </IonCol>

                    </IonRow>
                    <IonRow className='mt-30'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec'>
                                {t("all_pictures")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-vertical">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                          <IonList className="ion-no-padding">
                         
                             {(feed.length > 0) ? (feed.map((post, i) => {
                                    return (
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            key={post._id}
                                            className={((i%2) === 0) ? '' : 'alt-item'}
                                            >
                                            
                                            <IonLabel className='list-label'>
                                                <h3>{post.title} </h3>
                                            </IonLabel>
                                            <IonButtons slot="end">
                                                <IonButton onClick={() => openImage(`${process.env.REACT_APP_STATIC_S3}/images/${post.media}`, post.title)}>
                                                    <IonIcon 
                                                    icon={eye}
                                                    color="dark"
                                                    
                                                    />
                                                 </IonButton>
                                                 <IonButton onClick={() => {
                                                    setSelectedPic(post._id);
                                                    setTitle1(post.title);
                                                    setLink1(post.description);
                                                    setShowPopover1(true);
                                                    }}
                                                >
                                                    <IonIcon 
                                                        icon={pencil}
                                                        color="primary"
                                                    />
                                                 </IonButton>
                                                <IonButton onClick={() => {
                                                                        setSelectedPic(post._id);
                                                                        setShowAlert(true);
                                                                    }}>
                                                    <IonIcon 
                                                    icon={trash}
                                                    color="danger"
                                                    
                                                    />
                                                 </IonButton>
                                            </IonButtons> 
                                        </IonItem>
                                    )
                                })) : ( 
                                    <IonItem lines="none" className='red-card'>
                                        <IonIcon icon={alertCircleOutline}
                                        slot="start"
                                        color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_pictures")}</h3></IonLabel>

                                    </IonItem>
                                )
                                } 
                            </IonList>
                            <IonPopover size='cover' isOpen={showPopover1} onDidDismiss={e => setShowPopover1(false)}>
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" color="secondary">{t("title")}*</IonLabel>
                                                    <IonInput type="text"
                                                        value={title1}
                                                        placeholder={t("title")}
                                                        onIonInput={
                                                            (e) => setTitle1(e.detail.value !)
                                                    }></IonInput>
                                                </IonItem>
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" color="secondary">{t("link")}</IonLabel>
                                                    <IonInput type="text"
                                                        value={link1}
                                                        placeholder={t("link")}
                                                        onIonInput={
                                                            (e) => setLink1(e.detail.value !)
                                                    }></IonInput>
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="ion-padding-horizontal">
                                        <IonButton onClick={handleSubmit}
                                            expand="block"
                                            color="secondary">{t("update_picture")}</IonButton>

                                    </IonCol>
                                </IonRow>
                            </IonPopover>
                        </IonCol>
                    </IonRow>
                    {(feed.length > 0) &&
                        <IonRow>
                            <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                                >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCol>
                    </IonRow>
                    }
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default Banners;
