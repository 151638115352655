import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCheckbox,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonProgressBar,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { PhotoViewer } from '@capacitor-community/photoviewer';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import FileOpener from "../../../../components/FileOpener";
import useApiService from '../../../../components/ApiService';

interface ParamTypes {
    circularID: string
}

interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    isActive: boolean,
    isChecked: boolean
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

const CircularDetail: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {circularID} = useParams < ParamTypes > ();
    const [active, setActive] = useState < string > ("");
    const [showDownloading, setShowDownloading] = useState < boolean > (false);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [title, setTitle] = useState < string > ("");
    const [fileName, setFileName] = useState < string > ("");
    const [fileExt, setFileExt] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] > (new Date().toISOString());
    const [text, setText] = useState < string > ("");
    const [mode, setMode] = useState < string > ("");
    const [instituteID, setInstituteID] = useState < string > ("");
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [session, setSession] = useState < string > ("");
    const [type, setType] = useState < string > ("");
    const [buttonColor, setButtonColor] = useState < string > ("light");

    const formatDate = (value: string | string[]) => {
        if (typeof value === "string")
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
    };

    const openImage = (url: string, name: string) => {
        PhotoViewer.show({images: [{url, title: name}]});
    };

    const handleSession = (sessionName : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${instituteID}/${sessionName}`);
                setClasses(result.data);

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setSession(sessionName);
        setClasses([]);
    }

    const handleChecked = (value: boolean, id : number) => {

        let classic: Array < ClassInterface > = [];
        for (let i = 0; i < classes.length ; i++) {
            if (i === id) {
                classic[i] = {'_id': classes[i]._id, 'name': classes[i].name, 'year': classes[i].year, 'isActive': classes[i].isActive, 'isChecked' : !value};    
            }
            else 
            {
                classic[i] = classes[i];
            }       
        }
        setClasses(classic);
    }

    const handleAll = () => {

        if (buttonColor === "light")
        {
            let classic: Array < ClassInterface > = [];
            for (let i = 0; i < classes.length ; i++) {
                classic[i] = {'_id': classes[i]._id, 'name': classes[i].name, 'year': classes[i].year, 'isActive': classes[i].isActive, 'isChecked' : true};    
            }
            setClasses(classic);
            setButtonColor("warning");
        }
        else
        {
            let classic: Array < ClassInterface > = [];
            for (let i = 0; i < classes.length ; i++) {
                classic[i] = {'_id': classes[i]._id, 'name': classes[i].name, 'year': classes[i].year, 'isActive': classes[i].isActive, 'isChecked' : false};    
            }
            setClasses(classic);
            setButtonColor("light");
        }
    }

    const handleSection = (section: string) => {

        const toggleCircular = (isActive: string) => {
            return new Promise((resolve, reject) => {

                api.put(`/circulars/${circularID}`, { 'isActive': section }).then(res => {
                    return resolve(res.data.title);  
                }).catch(err => reject(err));

            });
        }
        if ((active !== "") && (section !== active))
        {
            setShowLoading(true);

            toggleCircular(section)
            .then(data => {

                setActive(section);
                setPass(data+t("has_been_updated"));
                setIspass(true);
            })
            .catch((error) => {
                //
            })
            .finally(() => setShowLoading(false));
        }
      
    }

    const handleSubmit = () => {

        if (!title || title === "") {
            setMessage(t("title_mandatory"));
            setIserror(true);
            return;
        }

        if (mode === "Announcement")
        {
            if (!text || text === "") {
                setMessage(t("message_mandatory"));
                setIserror(true);
                return;
            }
        }

        let classy: Array < string > = [];
        for (let i = 0; i < classes.length ; i++) {
            if (classes[i].isChecked)
                classy.push(classes[i]._id);
        }

        if(!classy.length)
        {
            setMessage((authInfo.institute.type === "school") ? t("select_classes") : t("select_batches"));
            setIserror(true);
            return;
        }
  
        const upCircular = (classes: string[]) => {
          return new Promise((resolve, reject) => {
            api.put('/circulars/'+circularID, { title, type, classes }).then(res => {
                    return resolve(res.data.title);
            }).catch(err => reject(err));
          });
        }

        const upAnnouncement = (classes: string[]) => {
            return new Promise((resolve, reject) => {
              api.put('/circulars/'+circularID, { title, type, text, classes }).then(res => {
                      return resolve(res.data.title);
              }).catch(err => reject(err));
            });
        }

        if (mode === "Circular")
        {
            setShowLoading(true);
            upCircular(classy)
            .then(data => {
    
                setPass(data+t("has_been_updated"));
                setIspass(true);
            
            })
            .catch((error) => {
                //
            })
            .finally(() => setShowLoading(false));
        }

        if (mode === "Announcement")
        {
            setShowLoading(true);
            upAnnouncement(classy)
            .then(data => {
    
                setPass(data+t("has_been_updated"));
                setIspass(true);
            
            })
            .catch((error) => {
                //
            })
            .finally(() => setShowLoading(false));
        }
      }

    const getMimeType = (ext: string) => {
        let mime = "";
        if (ext === 'jpeg')
            mime = 'image/jpeg';
        if (ext === 'png')
            mime = 'image/png';
        if (ext === 'pdf')
            mime = 'application/pdf';
        return mime;
    };

    const downloadCircular = async (fileName : string) => {

        const myArray = fileName.split(".");
        const mimeType = getMimeType(myArray[1]);
        const url = process.env.REACT_APP_STATIC_S3 + '/circulars/' + fileName;

        if (isPlatform('capacitor'))
        {
        try {
            Filesystem.checkPermissions().then(async (res) => {
                if (res.publicStorage !== 'granted') {
                    Filesystem.requestPermissions().then(async (ress) => {
                        if (ress.publicStorage === 'denied') {
                            setMessage(t("storage_denied"));
                            setIserror(true);
                        }
                        else {
                            setShowDownloading(true);
                            try {
                                const savedFile = await Filesystem.downloadFile({
                                    path: fileName,
                                    url,
                                    directory: Directory.External,
                                });
                                
                                if(savedFile.path)
                                {
                                    await FileOpener.open({filePath: savedFile.path, contentType: mimeType});
                                }
                                else
                                {
                                    setMessage(t("download_error"));
                                    setIserror(true);
                                }
                                
                            }
                            catch(error: any) {
                                setMessage(t("download_error"));  
                                setIserror(true);
                            } finally {
                                setShowDownloading(false);
                            }
                        }
                    });
                }
                else {
                    setShowDownloading(true);
                    try {
                        const savedFile = await Filesystem.downloadFile({
                            path: fileName,
                            url,
                            directory: Directory.External,
                        });
                        
                        if(savedFile.path)
                        {
                            await FileOpener.open({filePath: savedFile.path, contentType: mimeType});
                        }
                        else
                        {
                            setMessage(t("download_error"));
                            setIserror(true);
                        }
                        
                    }
                    catch(error: any) {
                        setMessage(t("download_error"));  
                        setIserror(true);
                    } finally {
                        setShowDownloading(false);
                    }
                }
            });

            } catch {
                setMessage(t("download_error"));
                setIserror(true);
            }
        }
       
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/circulars/' + circularID);
                
                setTitle(result.data.title);
                setType(result.data.type);

                if(result.data.filename)
                {
                    setFileName(result.data.filename);
                    const myArray = result.data.filename.split(".");
                    setFileExt(myArray[1]);
                    setMode("Circular");
                }
                if(result.data.text)
                {
                    setText(result.data.text);
                    setMode("Announcement");
                }
                
                setSelectedDate(result.data.createdAt);

                if (result.data.isActive)
                    setActive("enable");
                else
                    setActive("disable");

                if (authInfo.user.role === "Teacher")
                {
                    setInstituteID("teacher");
                   
                    const classy = await api.get(`/classes/getByTeacher/${authInfo.user._id}`);

                    let classic: Array < ClassInterface > = [];
                    for (let i = 0; i < classy.data.length ; i++) {
                        if (result.data.classes.includes(classy.data[i]._id) ) {
                            classic[i] = {'_id': classy.data[i]._id, 'name': classy.data[i].name, 'year': classy.data[i].year, 'isActive': classy.data[i].isActive, 'isChecked' : true};    
                        }
                        else 
                        {
                            classic[i] = {'_id': classy.data[i]._id, 'name': classy.data[i].name, 'year': classy.data[i].year, 'isActive': classy.data[i].isActive, 'isChecked' : false}; 
                        }       
                    }
                    setClasses(classic);
    
                }
                else 
                {
                    setInstituteID(result.data.instituteID);
                    
                    const res = await api.get(`/sessions/getAllInstitute/${result.data.instituteID}`);
                    setSessions(res.data);

                    let otherClass = await api.get(`/classes/${result.data.classes[0]}`);

                    setSession(otherClass.data.sessionID);
                    const classy = await api.get(`/classes/allInstitute/${result.data.instituteID}/${otherClass.data.sessionID}`);

                    let classic: Array < ClassInterface > = [];
                    for (let i = 0; i < classy.data.length ; i++) {
                        if (result.data.classes.includes(classy.data[i]._id) ) {
                            classic[i] = {'_id': classy.data[i]._id, 'name': classy.data[i].name, 'year': classy.data[i].year, 'isActive': classy.data[i].isActive, 'isChecked' : true};    
                        }
                        else 
                        {
                            classic[i] = {'_id': classy.data[i]._id, 'name': classy.data[i].name, 'year': classy.data[i].year, 'isActive': classy.data[i].isActive, 'isChecked' : false}; 
                        }       
                    }
                    setClasses(classic);
                  
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, circularID]);
    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref={`/app`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary"> {mode === "Circular" ? t("circular") : t("announcement")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                    {showDownloading && (
                        <IonProgressBar type="indeterminate"></IonProgressBar>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary"> {mode === "Circular" ? t("circular") : t("announcement")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false)
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding">
                    <IonRow className="ion-padding-vertical max-xl">
                        <IonCol sizeLg="6" size="12">
                           
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("communication_details")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-10">
                                <IonCol>
                                    <IonCard className='border-radius-10 active-group'>
                                        <IonCardContent>
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size="8">
                                                        
                                                        {t("current_status")}
                                                        
                                                    </IonCol>
                                                    <IonCol size="4" className='ion-text-right ion-padding-horizontal'>
                                                    {(active === 'enable') ? ( <IonBadge color="success" className='ion-padding-horizontal'>{t("active")}</IonBadge>) : 
                                                    ( <IonBadge color="danger" className='ion-padding-horizontal'>{t("inactive")}</IonBadge>)}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-padding-top ion-padding-horizontal">
                                                    <IonCol>
                                                        <IonSegment value={active} onIonChange={e => handleSection( e.detail.value!.toString() )} mode="ios" className="isactive-segment">
                                                            <IonSegmentButton value="enable">
                                                                <IonLabel>{t("make_active")}</IonLabel>
                                                            </IonSegmentButton>
                                                            <IonSegmentButton value="disable">
                                                                <IonLabel>{t("make_inactive")}</IonLabel>
                                                            </IonSegmentButton>
                                                        </IonSegment>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("issued_on")}
                                                </IonLabel>
                                                <IonInput className="input-field" value={formatDate(selectedDate)} readonly={true} />
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("comm_title")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("comm_title")}
                                                        value={title}
                                                        onIonInput={
                                                            (e) => setTitle(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("comm_type")}*
                                                    </IonLabel>
                                                    <IonSelect value={type}
                                                        className="input-field"
                                                        placeholder={t("comm_type")}
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        onIonChange={
                                                            (e) => setType(e.detail.value)
                                                        }>
                                                    
                                                        <IonSelectOption value="general">{t("general_comm")}</IonSelectOption>
                                                        <IonSelectOption value="academic">{t("academic_comm")}</IonSelectOption> 
                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            {(mode === "Circular") && (fileName !== "") &&(
                                <>
                                 <IonRow className="ion-margin-top">
                                    <IonCol className="ion-padding-horizontal">

                                        {isPlatform("capacitor") && (
                                            <IonButton expand="block" color="dark" onClick={() => downloadCircular(fileName)}>{t("download_circular")}</IonButton> 
                                        )}
                                        {!isPlatform("capacitor") && (
                                            <a href={`${process.env.REACT_APP_STATIC_S3}/circulars/${fileName}`} target="_blank" rel="noreferrer" download className='font-none'> 
                                                <IonButton expand="block" color="dark">{t("download_circular")}</IonButton> 
                                            </a>
                                        )}
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-margin-top">
                                    <IonCol>
                                        <IonCard className="ion-text-center">
                                            <IonCardHeader>
                                                <IonCardSubtitle>{t("circular_preview")}</IonCardSubtitle>
                                            </IonCardHeader>
                                            <IonCardContent>
                                                {(fileExt) && ((fileExt === 'jpeg') || (fileExt === 'png')) &&
                                                    (
                                                        <img onClick={() => openImage(`${process.env.REACT_APP_STATIC_S3}/circulars/${fileName}`, title)}
                                                        src={`${process.env.REACT_APP_STATIC_S3}/circulars/${fileName}`} 
                                                        alt={t("circular_preview")}
                                                        width="320" />
                                                )
                                                }
                                                {(fileExt) && (fileExt === 'pdf') &&
                                                    (<div className="pdf-preview">
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <img onClick={() => downloadCircular(fileName)} src="assets/images/pdf-icon.png" alt="PDF" />
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                                
                                            </IonCardContent>        
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                               
                            </>
                            )}
                            {(mode === "Announcement") && (
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}>
                                                <IonLabel position="floating" color="secondary">{t("circular_msg")}</IonLabel>
                                                <IonTextarea rows={20} value={text} onIonInput={
                                                        (e) => setText(e.detail.value!)
                                                }></IonTextarea>
                                            
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )}

                        </IonCol>
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {(authInfo.institute.type === "school") ? t("comm_classes") : t("comm_batches")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            
                            {(instituteID !== 'teacher') && (
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("class_session")}</IonLabel>
                                                    <IonSelect value={session}
                                                            className="input-field"
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={t("class_session")}
                                                            onIonChange={
                                                                (e) => handleSession(e.detail.value)
                                                        }>

                                                            {sessions.map((session) => (
                                                                (session.isActive) && 
                                                                (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                            ))}

                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )}

                            <IonRow>
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonList className="ion-no-padding">
                                                <IonItemDivider color="secondary">
                                                   
                                                    <IonButtons slot="start">
                                                        <IonButton onClick={handleAll} color={buttonColor}>{t("select_all")}</IonButton>
                                                    </IonButtons>
                                                </IonItemDivider>
                                                {classes.map((classi, i) => (
                                                    (classi.isActive) &&
                                                    (<IonItem key={classi._id}
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value={classi._id} checked={classi.isChecked} 
                                                            onClick={e => handleChecked(classi.isChecked, i)}/>
                                                        <IonLabel>{classi.name} ({classi.year})</IonLabel>
                                                    </IonItem>)
                                                ))}

                                            </IonList>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            

                        </IonCol>
                    </IonRow>
                </IonGrid>
                
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("update_comm")} {mode === "Circular" ? t("circular") : t("announcement")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default CircularDetail;
