import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonRouterOutlet,
  IonLabel,
  IonIcon
} from "@ionic/react";

import React from 'react';
import { Redirect, Route, Switch } from "react-router";

import SalesRepHome from "../pages/Home/SalesRepHome";
import ManageEnquiries from "../pages/Employee/Dashboard/ManageEnquiries";

import News from "../pages/Employee/News/News";
import NewsDetail from "../pages/Employee/News/NewsDetail";

import NewInquiry from "../pages/Admin/Admission/Enquiry/NewInquiry";
import Inquiries from "../pages/Admin/Admission/Enquiry/Inquiries";
import InquiriesBoard from "../pages/Admin/Admission/Enquiry/InquiriesBoard";
import Inquiry from "../pages/Admin/Admission/Enquiry/Inquiry";
import EnquiryDetail from "../pages/Admin/Admission/Enquiry/EnquiryDetail";
import InquiryChat from "../pages/Admin/Admission/Enquiry/InquiryChat";
import MyStats from "../pages/Employee/SalesRep/MyStats";

import MyTasks from "../pages/Employee/Task/MyTasks";
import DailyReports from "../pages/Employee/Task/DailyReports";
import MyChats from "../pages/Employee/Chat/MyChats";
import AddChat from "../pages/Employee/Chat/AddChat";
import ViewChat from "../pages/Employee/Chat/ViewChat";

import MyAttendance from "../pages/Employee/Attendance/MyAttendance";
import MyLeaves from "../pages/Employee/Attendance/MyLeaves";
import AddLeave from "../pages/Employee/Attendance/AddLeave";
import UserNotifications from "../pages/User/UserNotifications";
import Profile from "../pages/User/Profile";
import UpdatePersonal from "../pages/User/UpdatePersonal";
import UpdatePassword from "../pages/User/UpdatePassword";
import Terms from "../pages/General/Policies/TermsAndConditions";
import Privacy from "../pages/General/Policies/PrivacyPolicy";
import ContactUs from "../pages/General/ContactUs";
import DeleteAccount from "../pages/General/DeleteAccount";

import { calendarNumber, chatbubbleEllipses, clipboard, home, newspaper } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useAuth } from './AuthContext';

const SalesRepTabs: React.FC = () => {
  const {t} = useTranslation();
  const {authInfo} = useAuth()!;
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Switch>
          <Route path="/app/home" exact={true} component={SalesRepHome} />
          <Route path="/app/enquiries" exact={true} component={ManageEnquiries} />

          <Route path="/app/news" exact={true} component={News} />
          <Route path="/app/news/:newsID" exact={true} component={NewsDetail} />

          <Route path="/app/admission/inquiries/new/:mode?" exact={true} component={NewInquiry} />
          <Route path="/app/admission/inquiries/list" exact={true} component={Inquiries} />
          <Route path="/app/admission/inquiries/board" exact={true} component={InquiriesBoard} />
          <Route path="/app/admission/inquiry/:inquiryID" exact={true} component={Inquiry} />
          <Route path="/app/admission/inquiry/:inquiryID/details" exact={true} component={EnquiryDetail} />
          <Route path="/app/admission/inquiry/:inquiryID/chat" exact={true} component={InquiryChat} />

          <Route path="/app/user/stats" exact={true} component={MyStats} />
          <Route path="/app/user/tasks" exact={true} component={MyTasks} />
          <Route path="/app/user/task/:chatID" exact={true} component={ViewChat} />
          <Route path="/app/user/reports" exact={true} component={DailyReports} />
          <Route path="/app/user/chats" exact={true} component={MyChats} />
          <Route path="/app/user/chat" exact={true} component={AddChat} />
          <Route path="/app/user/chat/:chatID" exact={true} component={ViewChat} />
 
          <Route path="/app/user/attendance" exact={true} component={MyAttendance} />
          <Route path="/app/user/leaves" exact={true} component={MyLeaves} />
          <Route path="/app/user/leaves/add" exact={true} component={AddLeave} />
          <Route path="/app/user/notification" exact={true} component={UserNotifications} />
          <Route path="/app/user/profile" exact={true} component={Profile} />
          <Route path="/app/user/profile/personal" exact={true} component={UpdatePersonal} />
          <Route path="/app/user/profile/password" exact={true} component={UpdatePassword} />
          <Route path="/app/policies/terms" exact={true} component={Terms} />
          <Route path="/app/policies/privacy" exact={true} component={Privacy} />
          <Route path="/app/support/contact" exact={true} component={ContactUs} />
          <Route path="/app/support/delete" exact={true} component={DeleteAccount} />

          <Redirect exact={true} path="/app" to={`/app/home`} />
          <Redirect from="*" to="/app"/>

        </Switch>
      </IonRouterOutlet>
      <IonTabBar slot="bottom" className="tab-bar">
        <IonTabButton tab="home" href="/app/home" className="tab-button" target="_blank">
          <IonIcon icon={home} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">{t("home")}</IonLabel>
        </IonTabButton>

        {(authInfo.institute.task) ? 
        (<IonTabButton tab="reports" href="/app/user/reports" className="tab-button" target="_blank">
          <IonIcon icon={clipboard} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">{t("daily_reports")}</IonLabel>
        </IonTabButton>) 
        : 
        ( <IonTabButton tab="news" href="/app/news" className="tab-button" target="_blank">
          <IonIcon icon={newspaper} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">{t("circulars")}</IonLabel>
        </IonTabButton>)}

        <IonTabButton tab="chat" href="/app/user/chats" className="tab-button" target="_blank">
          <IonIcon icon={chatbubbleEllipses} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">{t("my_chats")}</IonLabel>
        </IonTabButton>
        
        <IonTabButton tab="attendance" href="/app/user/attendance" className="tab-button" target="_blank">
          <IonIcon icon={calendarNumber} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">{t("my_attendance")}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default SalesRepTabs;