import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonPopover,
    IonProgressBar,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import { alertCircleOutline, attach, chevronForwardOutline, create, imageOutline, informationCircle, videocam } from 'ionicons/icons';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { PhotoViewer, Image } from '@capacitor-community/photoviewer';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {Calendar, Day} from '@hassanmojab/react-modern-calendar-datepicker';
import { useAuth } from "../../../components/AuthContext";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchStudent from '../../../components/SwitchStudent';
import useApiService from '../../../components/ApiService';
import FileOpener from "../../../components/FileOpener";

import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';

interface Entry {
    _id: string,
    employee: Employee,
    subjectInfo: SubjectInfo,
    info: string,
    files?: Attach[],
    isAssignment: boolean
}
interface Employee {
    userID: string,
    name: string
}
interface Attach {
    caption: string,
    filename: string
}
interface SubjectInfo {
    subjectID: string,
    subjectName: string
}
interface Attend {
    year: number,
    month: number,
    day: number,
    className: string
}

interface Student {
    _id: string,
    classID: string,
    name: string
}

const ClassDiary: React.FC = () => {
    const { api, renderApiAlerts } = useApiService(); 
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [showDownloading, setShowDownloading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    
    const [refreshed, setRefreshed] = useState < number > (0);
    const [student, setStudent] = useState < Student > ();
    const [selectedDate, setSelectedDate] = useState < string > ('');
    const [minDay, setMinDay] = useState < Day > ();
    const [maxDay, setMaxDay] = useState < Day > ();
    const [selectedDay, setSelectedDay] = useState < Day > ();
    const [attend, setAttend] = useState < Attend[] > ([]);
    const [entries, setEntries] = useState < Entry[] > ([]);

    const [viewTitle, setViewTitle] = useState < string > ("");
    const [viewInfo, setViewInfo] = useState < string > ("");
    const [viewIsAssignment, setViewIsAssignment] = useState < boolean > (false);
    const [files, setFiles] = useState < Attach[] > ([]);
    const [isView, setIsView] = useState(false);
    const [entryID, setEntryID] = useState < string > ("");

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDate = (value: string) => {
        const monthNames = [t("jan"), t("feb"), t("mar"), t("apr"), t("may"), t("jun"),
            t("jul"), t("aug"), t("sep"), t("oct"), t("nov"), t("dec")];

        const valueDate = new Date(value);
        return monthNames[valueDate.getMonth()] +' - '+ valueDate.getFullYear();
    };

    const formatDated = (value: Day) => {
        return value.day+"/"+value.month+"/"+value.year;
    };

    const getDaysInMonth = (year: number, month: number) => {
        return new Date(year, month, 0).getDate();
    };

    const handleSubmit = (theDate: string | string[]) => {

        if (typeof(theDate) === "string" && student)
        {
            setSelectedDate(theDate);

            const onDate = new Date(theDate);

            const aajDate = new Date(Date.now());

            const minDate = {
                day: 1,
                month: onDate.getMonth()+1,
                year: onDate.getFullYear()
                
            }

            let maxDate : Day;

            if ((aajDate.getFullYear() === onDate.getFullYear()) && (aajDate.getMonth() === onDate.getMonth()))
            {
                maxDate = {
                    day: onDate.getDate()+1,
                    month: onDate.getMonth()+1,
                    year: onDate.getFullYear()
                }
            }
            else
            {
                maxDate = {
                    day: getDaysInMonth(onDate.getFullYear(), onDate.getMonth()+1),
                    month: onDate.getMonth()+1,
                    year: onDate.getFullYear()
                }
            }

            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    const events = await api.get(`/dailyDiary/getByMonth/${student.classID}/${theDate}`);

                    let result: Array < Attend > = [];

                    for (let i = 0; i < events.data.length ; i++) {
                        let dated: Date = new Date(events.data[i].calendarDate);
                        let dateA: number = dated.getDate();

                        let calObj: Attend = {year: onDate.getFullYear(), month: onDate.getMonth()+1, day: dateA, className: "eventDay"}; 
                        result.push(calObj);
                
                    }
                    setAttend(result);
                    setMinDay(minDate);
                    setMaxDay(maxDate);
                    setSelectedDay(minDate);

                    const res = await api.post('/dailyDiary/getByDay', {'classID': student.classID, 'day': minDate});
                    if (res.data)
                    {
                        setEntries(res.data);
                    }
                    else
                    {
                        setEntries([]);
                    }
                
                } catch (error: any) {
                    //
                } finally {
                    setShowLoading(false);
                }
                
            };

            fetchUp();
        }
    }

    const handleDate = async (dated : Day) => {

        if (student) 
        {
            setSelectedDay(dated);

            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    const res = await api.post('/dailyDiary/getByDay', {'classID': student.classID, 'day': dated});
                    if (res.data)
                    {
                        setEntries(res.data);
                    }
                    else
                    {
                        setEntries([]);
                    }
    
                } catch (error: any) {
                    //
                } finally {
                    setShowLoading(false);
                }
                
            };
    
            fetchUp();     
        }
    }

    const isFileImage= (fileName: string) => {
        // Extract the extension from the file name
        const extension = fileName.split('.').pop();
    
        // Check if the extension matches 'jpeg' or 'png'
        return extension === 'jpeg' || extension === 'png';
    };

    const isFileVideo= (fileName: string) => {
        const extension = fileName.split('.').pop();
        return extension === 'mp4' || extension === 'mov';
    };
    
    const openImage = (url: string, location: string) => {

        let images: Image[] = [];
        let startFrom = 0;
        let j = -1;

        if (location === "web")
        {
            for (let i = 0; i < files.length; i++)
            {
                if(isFileImage(files[i].filename))
                {
                    j += 1;
                    images.push({url: `${process.env.REACT_APP_STATIC_S3}/diary/${files[i].filename}`, title: files[i].caption});
                    if (files[i].filename === url)
                    {
                        startFrom = j;
                    }
                }
            }
        }

        if (images.length > 1)
        {
            PhotoViewer.show({images, mode: isPlatform('mobile') ? 'slider' : 'gallery', startFrom});
        }
        if (images.length === 1)
        {
            PhotoViewer.show({images, mode: 'one'});
        }

    };

    const downloadFile = async (fileName : string) => {

        const myArray = fileName.split(".");
        const mimeType = getMimeType(myArray[1]);
        const url = process.env.REACT_APP_STATIC_S3 + '/diary/' + fileName;

        if (isPlatform('capacitor') && !showDownloading)
        {
            try {
                Filesystem.checkPermissions().then(async (res) => {
                    if (res.publicStorage !== 'granted') {
                        Filesystem.requestPermissions().then(async (ress) => {
                            if (ress.publicStorage === 'denied') {
                                setMessage(t("storage_denied"));
                                setIserror(true);
                            }
                            else {
                                setShowDownloading(true);
                                try {
                                    const savedFile = await Filesystem.downloadFile({
                                        path: fileName,
                                        url,
                                        directory: Directory.External,
                                    });
                                    
                                    if(savedFile.path) {
                                        await FileOpener.open({filePath: savedFile.path, contentType: mimeType});
                                    } else {
                                        setMessage(t("download_error"));
                                        setIserror(true);
                                    }
                                    
                                } catch(error: any) {
                                    setMessage(t("download_error"));  
                                    setIserror(true);
                                } finally {
                                    setShowDownloading(false);
                                }
                            }
                        });
                    }
                    else {
                        setShowDownloading(true);
                        try {
                            const savedFile = await Filesystem.downloadFile({
                                path: fileName,
                                url,
                                directory: Directory.External,
                            });
                            
                            if(savedFile.path) {
                                await FileOpener.open({filePath: savedFile.path, contentType: mimeType});
                            } else {
                                setMessage(t("download_error"));
                                setIserror(true);
                            }
                            
                        } catch(error: any) {
                            setMessage(t("download_error"));  
                            setIserror(true);
                        } finally {
                            setShowDownloading(false);
                        }
                    }
                });

            } catch {
                setMessage(t("download_error"));
                setIserror(true);
            } 
        }
        if (isPlatform('capacitor') && showDownloading)
        {
            setMessage(t("download_in_progress"));
            setIserror(true);
        }

        if (!isPlatform('capacitor'))
        {
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.rel = 'noreferrer';
            link.download = 'download'; // Triggers download

            // Append the anchor to the body
            document.body.appendChild(link);

            // Programmatically click the link
            link.click();

            // Remove the anchor from the document
            document.body.removeChild(link);
        }

    };

    const getMimeType = (ext: string) => {
        let mime = "";
        if (ext === 'jpeg')
            mime = 'image/jpeg';
        if (ext === 'png')
            mime = 'image/png';
        if (ext === 'pdf')
            mime = 'application/pdf';
        if (ext === 'mp4')
            mime = 'video/mp4';
        if (ext === 'mov')
            mime = 'video/quicktime';
        if (ext === 'docx')
            mime = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        if (ext === 'xlsx')
            mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (ext === 'pptx')
            mime = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        if (ext === 'doc')
            mime = 'application/msword';
        if (ext === 'xls')
            mime = 'application/vnd.ms-excel';
        if (ext === 'ppt')
            mime = 'application/vnd.ms-powerpoint';
        return mime;
    };
    
    const openDiary = (diaryID: string) => {
        const entryObj = entries.find(val => val._id === diaryID)!;
        setEntryID(diaryID);
        setViewTitle(entryObj.subjectInfo.subjectName);
        setViewInfo(entryObj.info);
        setViewIsAssignment(entryObj.isAssignment);
        setFiles(entryObj.files || []);
        setIsView(true);
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const student = await api.get(`/students/getByParent/${authInfo.user._id}`);
                setStudent(student.data);

                const onDate = new Date(Date.now());
                setSelectedDate(onDate.toISOString());

                const minDate = {
                    day: 1,
                    month: onDate.getMonth()+1,
                    year: onDate.getFullYear()
                }
                const maxDate = {
                    day: onDate.getDate(),
                    month: onDate.getMonth()+1,
                    year: onDate.getFullYear()
                }

                const events = await api.get(`/dailyDiary/getByMonth/${student.data.classID}/${onDate.toISOString()}`);

                let result: Array < Attend > = [];

                for (let i = 0; i < events.data.length ; i++) {
                    let dated: Date = new Date(events.data[i].calendarDate);
                    let dateA: number = dated.getDate();

                    let calObj: Attend = {year: onDate.getFullYear(), month: onDate.getMonth()+1, day: dateA, className: "eventDay"}; 
                    result.push(calObj);
            
                }
                setAttend(result);
                setMinDay(minDate);
                setMaxDay(maxDate);
                setSelectedDay(maxDate);

                const res = await api.post('/dailyDiary/getByDay', {'classID': student.data.classID, 'day': maxDate});
                if (res.data)
                {
                    setEntries(res.data);
                }
                else
                {
                    setEntries([]);
                }

            } catch (error: any) {
                //
            } finally {
                setShowLoading(false);
            }
            
        };
      
        fetchData();
     
    }, [authInfo, refreshed]);


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_diary")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                    {showDownloading && (
                        <IonProgressBar type="indeterminate"></IonProgressBar>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_diary")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                {renderApiAlerts()}

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <SwitchStudent refreshed={refreshed} setRefreshed={setRefreshed} />

                <IonGrid className="ion-no-padding mb-60">

                {(selectedDay && (<>

                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>

                                <IonCardContent className="ion-no-padding">

                                        <IonItem detail={false}
                                            lines='full'
                                            button={true}
                                            id="open-date-input">
                                            <IonLabel position="floating" color="secondary">{t("month")}</IonLabel>
                                            <IonInput value={selectedDate === '' ? t("pick_month") : formatDate(selectedDate)} readonly={true} />
                                            <IonPopover trigger="open-date-input" showBackdrop={false} size='cover'>
                                                <IonDatetime
                                                    cancelText={t("cancel")}
                                                    doneText={t("ok")}
                                                    value={selectedDate}
                                                    presentation="month-year"
                                                    max={new Date(Date.now()).toISOString()}
                                                    onIonChange={ev => handleSubmit(ev.detail.value!)}
                                                    showDefaultButtons={true}
                                                    size="cover"
                                                />
                                            </IonPopover>
                                        </IonItem>

                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow className="ion-padding">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                        {(minDay && maxDay) && (
                            <div className="vertical-container">
                                <div className="vertical-center"> 
                                    <Calendar 
                                        value={selectedDay}
                                        onChange={handleDate}
                                        minimumDate={minDay}
                                        maximumDate={maxDay}
                                        shouldHighlightWeekends={true}
                                        customDaysClassName={attend}
                                        calendarClassName="custom-calendar"
                                    />
                                </div>
                            </div>
                       
                         )}
                        </IonCol>
                    </IonRow>
                    
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12" className='ion-text-center'>
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("entries_for")} {formatDated(selectedDay)}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    
                    <IonModal isOpen={isView} showBackdrop={true} onDidDismiss={() => setIsView(false)}
                        initialBreakpoint={0.95} breakpoints={[0, 0.95]} handle={true}>

                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                            <IonToolbar>
                                <IonTitle>{t("view_entry")}</IonTitle>
                            </IonToolbar>
                           
                            <IonRow>
                                <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("entry_title")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("entry_title")}
                                                        value={viewTitle}
                                                        readonly={true}
                                                    ></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            
                             <IonRow>
                                <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                            <IonItem detail={false}
                                                lines='none'
                                                button={false}
                                            >
                                                <IonLabel position="floating"  className="input-label" color="secondary">{t("entry_info")}</IonLabel>
                                                <IonTextarea rows={6} autoGrow={true} value={viewInfo} readonly={true}></IonTextarea>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            
                            <IonRow>
                                <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                    {(files.length > 0) && (files.map((afile, i) => {
                                        return (
    
                                            <IonCard key={i}>
                                                <IonCardContent className="ion-no-padding ion-no-margin">
                                                    <IonItem lines="none"
                                                    button={false}
                                                    >
                                                        <IonIcon icon={isFileImage(afile.filename) ? imageOutline : isFileVideo(afile.filename) ? videocam : attach}
                                                            slot="start"
                                                            color="tertiary" 
                                                            onClick={() => isFileImage(afile.filename) ? openImage(afile.filename, "web") : downloadFile(afile.filename)} />
                                                        <IonLabel className="action-title-two" onClick={() => isFileImage(afile.filename) ? openImage(afile.filename, "web") : downloadFile(afile.filename)}><h3>{afile.caption}</h3></IonLabel>
                                                        
                                                        <IonIcon icon={chevronForwardOutline}
                                                            slot="end"
                                                            color="dark"
                                                            onClick={() => isFileImage(afile.filename) ? openImage(afile.filename, "web") : downloadFile(afile.filename)} />
    
                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
    
                                        )}))}
    
                                        {(files.length === 0) && (
                                            <IonCard className='red-card'>
                                                <IonCardContent>
                                                    <IonItem lines="none">
                                                        <IonIcon icon={alertCircleOutline}
                                                            slot="start" 
                                                            color="danger" 
                                                        />
                                                        <IonLabel className="list-title">
                                                            <h3>{t("no_files_uploaded")}</h3>
                                                        </IonLabel>
    
                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
    
                                        )}
                                </IonCol>
                            </IonRow>
                            
                        </IonContent>
                        
                    </IonModal>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                        {(entries.length > 0) ? (entries.map((entry) => {
                            return (
                            <IonCard className='border-radius-10 mt-15' key={entry._id} onClick={() => openDiary(entry._id)}>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow className="tran-pad tran-row">
                                        <IonCol size="11">
                                            <IonText className='text-bold'>{entry.subjectInfo.subjectName}</IonText>
                                            <IonText className='tran-label'><br/>{t("updated_by")} {entry.employee.name}</IonText>
                                        </IonCol>
                                         <IonCol size="1">
                                            
                                            <IonIcon className='tran-icon-2 mt-10'
                                                icon={informationCircle}
                                                color='dark'
                                                onClick={() => null}
                                            />
                                            
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='ion-padding border-top'>
                                        <IonCol className='diary-col'>
                                            <p>{entry.info}</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="tran-pad class-row">
                                        <IonCol className='ion-text-center'>
                                            <IonText>{entry.files?.length} {t("attachments")}</IonText>
                                        </IonCol>
                                        
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                            
                            )})) : (
                            <IonCard className='red-card'>
                                <IonCardContent className='ion-no-margin ion-no-padding ion-padding-vertical'>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_entries")}</h3></IonLabel>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                            )}
                        </IonCol>
                    </IonRow>
                    </>))}
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default ClassDiary;
